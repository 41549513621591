import { jsx as _jsx } from "react/jsx-runtime";
import { subtitlesDefaults } from 'api/settings/user-settings';
import { getNodeProperty } from 'libs/quill-utils';
import clsx from 'clsx';
import { txt } from 'libs/i18n';
import { Tooltip } from 'antd';
import styles from './style.module.less';
const COLOR_BUTTON_WIDTH = 15;
const SpeakerColorButton = ({ onClick, position, widgetTrigger, backgroundColor, disabled, }) => {
    const getSpeakerColor = () => {
        const speakerNode = widgetTrigger.parentElement;
        if (speakerNode !== null && backgroundColor !== undefined) {
            const captionColor = getNodeProperty(speakerNode, 'caption-color-');
            return captionColor !== null && captionColor !== void 0 ? captionColor : backgroundColor;
        }
        return subtitlesDefaults.speakerColor;
    };
    return (_jsx(Tooltip, { title: disabled === true ? txt('readOnlyProject') : false, children: _jsx("button", { type: "button", disabled: disabled, onClick: () => {
                if (onClick !== undefined) {
                    onClick(widgetTrigger);
                }
            }, className: clsx(styles.colorCircle, getSpeakerColor()), style: {
                position: 'absolute',
                zIndex: 100,
                top: position.top + 8,
                left: position.isRightToLeft
                    ? position.left + position.width + COLOR_BUTTON_WIDTH
                    : position.left - COLOR_BUTTON_WIDTH,
                backgroundColor: getSpeakerColor(),
            } }) }));
};
export default SpeakerColorButton;
