import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Switch, Tooltip, Button, } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { txt } from 'libs/i18n';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DraggableModal from 'components/DraggableModal';
import { getDialogPosition } from 'libs/dialog-position';
import { SpeakerColorSchema } from 'api/settings/user-settings';
import styles from './styles.module.less';
import SpeakerColorSelect from '../SpeakerColorSelect';
const getSpeakerColoringFormat = (speakers, defaultColor) => {
    return speakers.reduce((acc, speaker) => {
        var _a;
        const captionColor = (_a = speaker.captionColor) !== null && _a !== void 0 ? _a : defaultColor;
        return Object.assign(Object.assign({}, acc), { [speaker.id]: speaker.isDefaultColor ? 'default' : captionColor });
    }, {});
};
const SpeakerColorDialog = ({ onClose, editorController, defaultSpeakerColor, onColorChange, showConflicts, onShowConflicts, }) => {
    const [form] = Form.useForm();
    const scrollableRef = useRef(null);
    const { getSpeakerColorConflict, getSpeakerFrequencyInDocument } = editorController.speakers;
    useEffect(() => {
        form.setFieldValue('defaultColor', defaultSpeakerColor);
    }, [defaultSpeakerColor]);
    // NOTE: With each change new documentSpeaker is created so current speakers need to be filtered.
    const filterDeletionsAndDuplicates = (speakers) => {
        const uniqueSpeakers = [];
        for (let i = speakers.length - 1; i >= 0; i -= 1) {
            const speakerName = speakers[i].composeLabel();
            if (!editorController.speakers.getDisplayedSpeakerNames().includes(speakerName)) {
                continue;
            }
            if (uniqueSpeakers.find((speaker) => speaker.composeLabel() === speakerName) !== undefined) {
                continue;
            }
            uniqueSpeakers.push(speakers[i]);
        }
        return uniqueSpeakers;
    };
    const sortSpeakersByFrequency = (speakers) => {
        const frequencies = speakers.map((speaker) => editorController.speakers.getSpeakerFrequencyInDocument(speaker));
        return speakers.slice().sort((a, b) => {
            const frequencyDiff = frequencies[speakers.indexOf(b)] - frequencies[speakers.indexOf(a)];
            if (frequencyDiff === 0) {
                return a.composeLabel().localeCompare(b.composeLabel());
            }
            return frequencyDiff;
        });
    };
    const [documentSpeakers, setDocumentSpeakers] = useState(() => sortSpeakersByFrequency(filterDeletionsAndDuplicates(editorController.speakers.documentSpeakers)));
    useEffect(() => {
        var _a;
        (_a = scrollableRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
        const handleTextChanged = () => {
            const changedSpeakers = sortSpeakersByFrequency(filterDeletionsAndDuplicates(editorController.speakers.documentSpeakers));
            setDocumentSpeakers(changedSpeakers);
            form.setFieldValue('speakers', getSpeakerColoringFormat(changedSpeakers, defaultSpeakerColor));
        };
        editorController.addEventListener('text-changed', handleTextChanged);
        return () => {
            editorController.removeEventListener('text-changed', handleTextChanged);
        };
    }, []);
    const formInitialValues = {
        defaultColor: defaultSpeakerColor,
        showConflicts,
        speakers: getSpeakerColoringFormat(documentSpeakers, defaultSpeakerColor),
    };
    const getSpeaker = (speakerId) => {
        return documentSpeakers.find((speaker) => speaker.id === speakerId);
    };
    const handleDefaultColorChange = (newColor) => {
        if (newColor !== 'default') {
            editorController.captions.updateCaptionParameters({ defaultColor: newColor });
        }
    };
    const changeSpeakerColor = (speakerId, color, isDefaultColor) => {
        const changedSpeaker = getSpeaker(speakerId);
        if (changedSpeaker === undefined) {
            global.logger.error('failed to change speaker color - speaker not found');
            return;
        }
        onColorChange(changedSpeaker, color, isDefaultColor);
    };
    const handleFormValuesChange = (values) => {
        if (values.speakers !== undefined) {
            const { speakers } = values;
            const speakerId = Object.keys(speakers)[0];
            const newColor = speakers[speakerId] === 'default' ? defaultSpeakerColor : speakers[speakerId];
            changeSpeakerColor(speakerId, newColor, speakers[speakerId] === 'default');
        }
        onShowConflicts(values.showConflicts);
    };
    const autoColorSpeakers = () => {
        // NOTE: In auto color mode, most frequent speaker has default color.
        onColorChange(documentSpeakers[0], defaultSpeakerColor, true);
        const colors = SpeakerColorSchema.values.filter((color) => color !== defaultSpeakerColor);
        const assignedColors = editorController.speakers.assignSpeakersColors(colors);
        assignedColors.forEach((coloredSpeaker) => {
            changeSpeakerColor(coloredSpeaker.id, coloredSpeaker.color, false);
        });
    };
    return (_jsx(DraggableModal, { bounds: "body", onClose: onClose, title: txt('speakerColor'), defaultPosition: getDialogPosition({ width: 500, height: 450 }), children: _jsxs(Form, { labelCol: { span: 13 }, form: form, initialValues: formInitialValues, labelAlign: "left", labelWrap: true, className: styles.speakerForm, colon: false, onValuesChange: handleFormValuesChange, children: [_jsx(Form.Item, { name: "defaultColor", label: `${txt('defaultColor')}:`, children: _jsx(SpeakerColorSelect, { onChange: handleDefaultColorChange }) }), _jsx(Form.Item, { className: styles.controlInput, name: "autoColor", label: `${txt('autoColor')}:`, children: _jsx(Button, { size: "small", onClick: autoColorSpeakers, children: txt('assignColors') }) }), _jsx(Form.Item, { name: "showConflicts", className: styles.controlInput, valuePropName: "checked", label: `${txt('showConflicts')}:`, children: _jsx(Switch, { size: "small" }) }), _jsx("div", { className: styles.hint, children: txt('colorSpeakerHint') }), _jsx("div", { className: styles.speakerList, ref: scrollableRef, children: documentSpeakers.map((speaker) => (_jsxs("div", { className: styles.speakerFormItem, children: [_jsx(Form.Item, { name: ['speakers', speaker.id], shouldUpdate: true, children: _jsx(SpeakerColorSelect, { defaultColor: defaultSpeakerColor, bordered: false, size: "small", hideArrow: true, selectClassName: styles.colorSelect, dropdownMatchSelectWidth: false }) }), _jsx("span", { className: styles.speaker, children: speaker.composeLabel() }), _jsx(Tooltip, { title: txt('speakerFreq'), children: _jsx("span", { className: styles.speaker, children: `(${getSpeakerFrequencyInDocument(speaker)})` }) }), _jsx(Form.Item, { dependencies: ['showConflicts'], children: () => {
                                    const conflictName = getSpeakerColorConflict(speaker.id);
                                    return (showConflicts && conflictName !== null ? (_jsx(Tooltip, { title: txt('colorConflictError'), children: _jsxs("div", { className: styles.conflict, children: [_jsx(ExclamationCircleOutlined, {}), _jsx("span", { children: conflictName })] }) })) : null);
                                } })] }, speaker.id))) })] }) }));
};
export default SpeakerColorDialog;
