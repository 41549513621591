import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { deriveTagStyle } from 'libs/tag-style';
import { screenBreakpoints, useAppContext } from 'App/app-context';
import { Tooltip } from 'antd';
import { txt } from 'libs/i18n';
import { useRef } from 'react';
const getTagContent = (trigger) => {
    if (trigger.dataset.tags !== undefined) {
        return trigger.dataset.tags;
    }
    return null;
};
const SectionTag = ({ onClick, position, widgetTrigger, disabled, }) => {
    const buttonRef = useRef(null);
    const { screenSize } = useAppContext();
    const isMobile = screenSize.width < screenBreakpoints.md;
    const tagContent = getTagContent(widgetTrigger);
    if (tagContent === null) {
        return null;
    }
    const isAddTag = tagContent.startsWith('+');
    const shouldClipContent = isMobile && isAddTag;
    const tagStyleAttributes = {
        position: 'absolute',
        zIndex: 100,
        top: position.top + 15,
        left: position.isRightToLeft
            ? widgetTrigger.offsetLeft - 60
            : widgetTrigger.offsetWidth + 40,
    };
    return (_jsx(Tooltip, { title: isAddTag
            ? (disabled === true
                ? txt('readOnlyProject')
                : '')
            : tagContent, children: _jsx("button", { ref: buttonRef, type: "button", disabled: disabled, onClick: () => {
                if (onClick !== undefined) {
                    onClick(widgetTrigger, 'editSections');
                }
            }, className: clsx('sectionTag beeyTag', { clippedTag: shouldClipContent }, { addNew: isAddTag }), style: !isAddTag ? Object.assign(Object.assign({}, deriveTagStyle(tagContent.slice(1))), tagStyleAttributes) : tagStyleAttributes, children: shouldClipContent ? '+' : tagContent }) }));
};
export default SectionTag;
