import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'antd';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import { Link } from 'react-router-dom';
import styles from './style.module.less';
const MediaOriginsSummary = ({ mediaOriginsList, availableCredit, remainingStorage, }) => {
    const { session } = useSession();
    const { login } = session;
    if (mediaOriginsList.isEmpty()) {
        return null;
    }
    let alertMessage = null;
    let isAlertTypeWarning = true;
    const storageAlert = mediaOriginsList.hasEnoughStorage(remainingStorage) || login.hasClaim('unlimitedStorage:enabled')
        ? null
        : (_jsxs(_Fragment, { children: [login.hasClaim('signIn:apa') ? (_jsxs(_Fragment, { children: ["Sie haben nicht gen\u00FCgend Speicherplatz. Wenden Sie sich bitte an APA-Salesdesk:", _jsx(IconButton, { onClick: () => {
                                window.location.href = 'mailto:salesdesk@apa.at';
                            }, children: "salesdesk@apa.at" })] })) : (_jsxs("p", { children: [txt('notEnoughStorageLong'), ' '] })), _jsx("strong", { className: styles.contactMessage, children: login.hasClaim('orders:create') || login.hasClaim('signIn:apa')
                        ? (_jsxs(_Fragment, { children: [txt('infoOnStorage'), ' ', _jsx(Link, { to: "/account/credit", children: txt('account').toLowerCase() }), "."] }))
                        : login.hasClaim('team:owner') ? txt('contactDistributor') : txt('contactTeamOwner') })] }));
    let creditAlert = mediaOriginsList.hasEnoughCredit(availableCredit)
        ? null
        : (_jsxs(_Fragment, { children: [login.hasClaim('signIn:apa') ? (_jsxs(_Fragment, { children: ["Sie haben nicht genug Guthaben. Wenden Sie sich bitte an APA-Salesdesk:", _jsx(IconButton, { onClick: () => {
                                window.location.href = 'mailto:salesdesk@apa.at';
                            }, children: "salesdesk@apa.at" })] })) : (_jsxs("p", { children: [txt('notEnoughCredit'), ' '] })), _jsx("strong", { className: styles.contactMessage, children: login.hasClaim('orders:create') || login.hasClaim('signIn:apa')
                        ? null
                        : login.hasClaim('team:owner') ? txt('contactDistributor') : txt('contactTeamOwner') })] }));
    if (mediaOriginsList.containsLongMedia()) {
        creditAlert = null;
        alertMessage = txt('extraLongRecording');
        isAlertTypeWarning = false;
    }
    else if (mediaOriginsList.containsMediumMedia()) {
        alertMessage = txt('longRecording');
    }
    if (mediaOriginsList.containsError('invalidFormat')) {
        alertMessage = txt('mediaFormatUnsupported');
        isAlertTypeWarning = false;
    }
    if (mediaOriginsList.containsError('cannot-process')) {
        alertMessage = txt('mediaOriginError');
        isAlertTypeWarning = false;
    }
    if (creditAlert === null && alertMessage === null && storageAlert === null) {
        return null;
    }
    return (_jsxs("div", { className: styles.mediaOriginsSummary, children: [creditAlert === null ? null
                : (_jsx(Alert, { message: creditAlert, type: "error", showIcon: true })), storageAlert === null ? null
                : (_jsx(Alert, { message: storageAlert, type: "error", showIcon: true })), alertMessage === null ? null
                : (_jsx(Alert, { message: alertMessage, type: isAlertTypeWarning ? 'warning' : 'error', showIcon: true }))] }));
};
export default MediaOriginsSummary;
