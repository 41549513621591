import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Form, Button, Select } from 'antd';
import { txt } from 'libs/i18n';
import ChangePasswordForm from './ChangePasswordForm';
import styles from '../../style.module.less';
const { Option } = Select;
const BasicUserInformation = ({ user, onRoleChange, teamOwnerEmail, }) => {
    const [isPswModalVisible, setIsPswModalVisible] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(Form, { wrapperCol: { sm: { offset: 7 } }, labelCol: { sm: { span: 5 } }, labelAlign: "left", className: styles.form, children: [_jsx(Form.Item, { label: txt('email'), children: _jsx("span", { className: "ant-form-text", children: user.email }) }), _jsx(Form.Item, { label: txt('pw'), children: _jsx(Button, { id: "action-change-password", onClick: () => setIsPswModalVisible(true), type: "primary", children: txt('changePassword') }) }), _jsx(Form.Item, { label: txt('role'), children: _jsxs(Select, { value: user.role, onChange: onRoleChange, children: [_jsx(Option, { value: "Enterprise", children: "Enterprise" }), _jsx(Option, { value: "Standard", children: "Standard" }), _jsx(Option, { value: "Admin", children: "Admin" }), _jsx(Option, { value: "Developer", children: "Developer" })] }) }), _jsx(Form.Item, { label: txt('team'), children: user.isTeamOwner ? (_jsx("span", { className: "ant-form-text", children: txt('teamOwner') })) : (_jsx("span", { className: "ant-form-text", children: teamOwnerEmail })) })] }), _jsx(ChangePasswordForm, { visible: isPswModalVisible, onCancel: () => setIsPswModalVisible(false), userId: user.id })] }));
};
export default BasicUserInformation;
