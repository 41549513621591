import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { App, Button, Input } from 'antd';
import FormModal from 'components/FormModal';
import * as ProjectAPI from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const { TextArea } = Input;
const ProjectNotesModal = ({ visible, onCancel, onNotesChange, enqueueProjectUpdate, notes, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [projNotes, setProjNotes] = useState(notes);
    const updateProjectNotes = ({ target: { value } }) => {
        setProjNotes(value);
    };
    useEffect(() => {
        setProjNotes(notes);
    }, [notes]);
    const saveProjNotes = async () => {
        await enqueueProjectUpdate(async (initialProject) => {
            try {
                const updatedProject = await ProjectAPI.updateProjectNotes(session.connection, initialProject, projNotes);
                void message.success(projNotes.length === 0 ? txt('notesDeleted') : txt('notesSaved'), 2);
                onNotesChange(projNotes);
                onCancel();
                return updatedProject;
            }
            catch (error) {
                global.logger.error('Failed to save project notes', {}, error);
                void message.error(txt('failedToSaveNotes'), 2);
            }
            return initialProject;
        });
    };
    return (_jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], className: styles.projectNotesModal, title: txt('notes'), open: visible, onCancel: onCancel, onOk: saveProjNotes, footer: [
            _jsx(Button, { onClick: onCancel, children: txt('close') }, "back"),
            _jsx(Button, { type: "primary", onClick: saveProjNotes, children: txt('save') }, "submit"),
        ], children: _jsx(TextArea, { id: "textarea-project-notes", defaultValue: notes, value: projNotes, autoSize: { minRows: 6, maxRows: 15 }, onChange: updateProjectNotes, autoFocus: true }) }));
};
export default ProjectNotesModal;
