var _a;
import Quill from 'quill';
import { debounce } from 'throttle-debounce';
import { Speaker } from 'api/speaker';
import { parseSpeaker } from 'libs/parse-speakers';
import dayjs from 'dayjs';
import { doesOperationIncludeFormat, getChangedRangeFromDelta, normalizeFormat, } from 'libs/quill-utils';
import { txt } from 'libs/i18n';
import Delta from 'quill-delta';
import EventEmitter from 'events';
import { nanoid } from 'nanoid';
import { detectBrowser } from 'libs/browser';
import { registerGlobalShortcut, unregisterGlobalShortcut } from 'libs/shortcuts';
import { isSelectionRightToLeft, revertSelection, scrollElementIntoView, } from './selection';
import Speakers from './speakers';
import SpeakerBlot from './blots/speaker-blot';
import SectionBlot from './blots/section-blot';
import FindAndReplace from './find-and-replace';
import UnalignedBlot from './blots/unaligned-blot';
import UneditableBlot from './blots/uneditable-blot';
import TimeAnchorBlot from './blots/time-anchor-blot';
import WarningBlot from './blots/warning-blot';
import KeywordBlot from './blots/keyword-blot';
import SearchBlot from './blots/search-blot';
import SummaryBlot from './blots/summary-blot';
import TrsxManager from './trsx-manager';
import { ALIGNER_EVENTS, DocumentAlignment } from './document-alignment';
import Captions, { CAPTION_EVENTS } from './captions';
import Keywords from './keywords';
import Summary from './summary';
import TimestampLabels from './timestamp-labels';
import TimeAnchors from './time-anchors';
import QuillBindings from './quill-bindings';
import { NONSPACE_REGEX, CAPTION_END_SYMBOL, WORD_BOUNDARY_CHAR_REGEX, TIME_ANCHOR_SYMBOL, NBSP_DOT_SYMBOL, } from './text-utils';
import TextMetadata from './text-metadata';
import CustomColor from './custom-color';
import { PlaybackEvents } from '../MediaPlayer/playback';
import { KeyboardHandler } from './keyboard-handler';
import ExpandableAbbreviations from './expandable-abbreviations';
import Sections from './sections';
const operatingSystem = (_a = detectBrowser()) === null || _a === void 0 ? void 0 : _a.os;
const isOsMac = operatingSystem === 'Mac OS' || operatingSystem === 'iOS';
Quill.register(SpeakerBlot);
Quill.register(SectionBlot);
Quill.register(UnalignedBlot);
Quill.register(UneditableBlot);
Quill.register(TimeAnchorBlot);
Quill.register(WarningBlot);
Quill.register(KeywordBlot);
Quill.register(SearchBlot);
Quill.register(SummaryBlot);
Quill.register('modules/findAndReplace', FindAndReplace);
const QUILL_FORMATS = [
    'speaker',
    'unaligned',
    'timeAnchor',
    'header',
    'section',
    'color',
    'uneditable',
    'warning',
    'keyword',
    'search',
    'summary',
    'underline', // reserved for caption highlight with sound wave
];
export const NONTRANSCRIPT_FORMATS = [
    'speaker',
    'section',
    'header',
    'summary',
];
export default class EditorController {
    constructor(session, caretTimeRef, playback, setCaptionMode, onCriticalError, onMessage, areSectionTagsAllowed, readOnlyProject, sectionNamesList) {
        this.currentTrsxId = null;
        this.rootElement = null;
        this.insertingSpeakerBeforeParagraph = false;
        this.editorId = nanoid();
        this.mouseDownTarget = null;
        this.cachedEditorText = null;
        this.cachedEditorLength = null;
        this.getLanguage = () => this.language;
        this.isNonTranscriptFormat = (format) => {
            return Object.entries(format).some(([key, value]) => (value !== undefined) && NONTRANSCRIPT_FORMATS.includes(key));
        };
        this.isEditable = (format) => {
            return Object.entries(format)
                .every(([key]) => {
                if (key === 'speaker') {
                    return false;
                }
                if (key === 'uneditable') {
                    return false;
                }
                return true;
            });
        };
        this.execTextChange = (directives, fun) => {
            const savedDirectives = this.textChangeDirectives;
            this.textChangeDirectives = Object.assign(Object.assign({}, this.textChangeDirectives), directives);
            try {
                fun();
                this.textChangeDirectives = savedDirectives;
            }
            catch (error) {
                this.textChangeDirectives = savedDirectives;
                throw error;
            }
        };
        // eslint-disable-next-line @typescript-eslint/member-ordering
        this.debouncedUpdateOnDelta = debounce(30, false, (delta) => {
            const { documentAligner } = this;
            this.execTextChange({ runAligner: false }, () => {
                documentAligner.alignOnDelta(delta);
            });
            this.triggerSave();
        });
        this.triggerSave = () => {
            this.onDocumentChanged(true);
        };
        /* This function is called after document and player finished loading.
          This does not necessarily mean that player is playable and document is editable -
          If there is nothing to load (e. g. project is in queue), loading is considered finished.
        */
        this.handlePlayerAndDocumentLoadingFinished = (isDocumentComplete) => {
            if (isDocumentComplete) {
                this.documentAligner.finalizePhrases(this.playback.duration);
            }
            if (this.trsxManager.lastPlaybackTime !== null
                && this.fullTextHighlightState === 'nothing-highlighted') {
                this.playback.seekTo(this.trsxManager.lastPlaybackTime);
            }
            // If there is a document loaded, seek to last edited position.
            // If the document does not exist yet, nothing happens.
            window.setTimeout(() => {
                this.syncTextHighlightWithPlayback(true);
                if (this.fullTextHighlightState !== 'nothing-highlighted') {
                    this.fullTextHighlightState = 'decolor-allowed';
                }
                this.timestampLabels.update(0, this.getLength());
            }, 200);
        };
        this.handleQuillTextChanged = (delta, oldDelta, source) => {
            var _a;
            if (this.textChangeDirectives.updateMetadata) {
                this.textMetadata.applyDelta(delta);
            }
            if (delta.ops.some((op) => doesOperationIncludeFormat(op, NONTRANSCRIPT_FORMATS))) {
                this.textMetadata.spliceMetadata('lineFormatCache', 0, this.getLength() + 1, []);
            }
            if (this.wasLastChangeNewLineDelete()) {
                // NOTE: when deleting newlines, some other new line formats may change.
                // This makes the line format cache invalid. Thus we clear the cache completely.
                // In principle, we could clear the cache only for the places that were affected,
                // but it is simpler to clear everything.
                this.textMetadata.spliceMetadata('lineFormatCache', 0, this.getLength() + 1, []);
            }
            const editedRange = getChangedRangeFromDelta(delta);
            if (editedRange !== null) {
                this.cachedEditorText = null;
                this.cachedEditorLength = null;
                this.onDocumentChanged(this.textChangeDirectives.requestSave);
            }
            if (!this.textChangeDirectives.fireEvent) {
                return;
            }
            this.previousHighlightIndex = 'reset';
            (_a = this.quill) === null || _a === void 0 ? void 0 : _a.getModule('findAndReplace').handleTextChange();
            this.summary.handleTextChanged();
            this.speakers.handleTextChanged();
            if (editedRange !== null) {
                const [editedFrom, editedTo] = editedRange;
                this.emitter.emit('text-changed', { editedFrom, editedTo });
                const { captions, expandableAbbreviations } = this;
                captions.requestUpdate(editedFrom, editedTo, false);
                this.timestampLabels.update(editedFrom, editedTo);
                expandableAbbreviations.handleTextChanged(delta, source);
                this.timeAnchors.handleTextChanged(editedFrom, editedTo);
                if (this.textChangeDirectives.runAligner) {
                    this.debouncedUpdateOnDelta(delta);
                }
            }
            else {
                // NOTE: The event should fire even when there was no text edited,
                // only formatting.
                this.emitter.emit('text-changed', { editedFrom: 0, editedTo: 0 });
            }
            const time = this.getActiveTimestamp();
            if (time !== null) {
                this.caretTimeRef.current = time;
            }
            this.forceValidSelection(this.getSelection() || null);
        };
        this.forceValidSelection = (range) => {
            var _a;
            if (range === null)
                return;
            if (range.length === 0) {
                this.forceValidCaretPosition(range.index);
                return;
            }
            let start = range.index;
            let end = range.index + range.length;
            const originalStart = start;
            const originalEnd = end;
            while (!this.isEditable(this.getLineFormat(start))) {
                if (this.isEditable(this.getLineFormat(start + 1))) {
                    // selection starts at newline after speaker
                    start += 1;
                    break;
                }
                start -= 1;
                if (start < 0) {
                    throw new Error(`cannot force valid selection for range ${range.index} ${range.length}`);
                }
            }
            while (!this.isEditable(this.getLineFormat(end))) {
                if (this.isEditable(this.getLineFormat(end - 1))) {
                    // selection ends at newline before speaker
                    end -= 1;
                    break;
                }
                end += 1; // enlarge selection to include the whole speaker
                if (end >= this.getLength()) {
                    throw new Error(`cannot force valid selection for range ${range.index} ${range.length}`);
                }
            }
            if (end !== originalEnd || start !== originalStart) {
                const wasSelectionRightToLeft = isSelectionRightToLeft();
                (_a = this.quill) === null || _a === void 0 ? void 0 : _a.setSelection(start, end - start, 'api');
                if (isSelectionRightToLeft() !== wasSelectionRightToLeft) {
                    revertSelection();
                }
            }
            // This function always creates a valid selection - starting and ending outside of speakers.
            // Infinite callback loop is therefore not possible. QED
        };
        this.selectWrapline = (time) => {
            const index = this.textMetadata.getTimestampsAtTime(time).to;
            const startIndex = this.extendToWrapLineStart(index);
            const endIndex = this.extendToWraplineEnd(index);
            this.setSelection(startIndex, endIndex - startIndex);
        };
        this.handleSelectionChanged = (range) => {
            var _a;
            if (range === null) {
                return;
            }
            this.textMetadata.spliceMetadata('lastSelection', 0, this.getLength(), [
                [range.index, 'start'],
                [range.index + range.length, 'end'],
            ]);
            const time = this.getActiveTimestamp();
            if (time !== null) {
                this.caretTimeRef.current = time;
            }
            (_a = this.quill) === null || _a === void 0 ? void 0 : _a.getModule('findAndReplace').onSelectionChange();
            if (this.fullTextHighlightState === 'decolor-allowed') {
                void this.decolorHighlightSearchResult();
            }
            this.expandableAbbreviations.handleSelectionChanged();
            this.forceValidSelection(range);
        };
        this.getWordStart = (index) => {
            for (let i = index - 1; i > 0; i -= 1) {
                // NOTE: Skip to the word start. Caption end is not considered as part of the word,
                // but time anchor is.
                if (WORD_BOUNDARY_CHAR_REGEX.test(this.getText(i, 1))) {
                    return i + 1;
                }
            }
            return 0;
        };
        this.highlightSpeaker = (searchedText) => {
            const blockStarts = this.getBlockStarts(0, this.getLength());
            let firstIndex = 0;
            for (let i = 0; i < blockStarts.length - 1; i += 1) {
                const blockFrom = blockStarts[i];
                const blockTo = blockStarts[i + 1];
                const lineFormat = this.getLineFormat(blockFrom);
                if (lineFormat.speaker !== undefined) {
                    const speakerNameLength = blockTo - blockFrom;
                    const speakerName = this.getText(blockFrom, speakerNameLength);
                    if (speakerName.includes(searchedText)) {
                        if (firstIndex === 0) {
                            firstIndex = blockFrom;
                            this.fullTextHighlightState = 'decolor-blocked';
                        }
                        // eslint-disable-next-line no-await-in-loop
                        this.execTextChange({ runAligner: false, requestSave: false }, () => {
                            this.formatText(blockFrom, blockTo - blockFrom, { search: 'result' }, 'api');
                        });
                    }
                }
            }
            const firstSpeakerTime = this.textMetadata.getTimestampsAtIndex(firstIndex).begin;
            this.playback.seekTo(firstSpeakerTime);
        };
        this.highlightSearchResult = (timestampInMs, search) => {
            const timestamp = Number(timestampInMs) / 1000;
            const { from: wordStart } = this.textMetadata.getTimestampsAtTime(timestamp);
            // Checking for the first word equal to search from query param
            // in a relatively long text (250 chars) next to the time stamp
            const textToBeChecked = this.getText(wordStart, 250);
            const beginIdxOfResult = textToBeChecked.indexOf(search);
            if (beginIdxOfResult === -1) { // no equal word was found
                this.playback.seekTo(timestamp);
                return;
            }
            this.fullTextHighlightState = 'decolor-blocked';
            this.execTextChange({ runAligner: false, requestSave: false }, () => {
                this.formatText(wordStart + beginIdxOfResult, search.length, { search: 'highlighted' }, 'api');
            });
            this.playback.seekTo(timestamp);
        };
        this.scrollToPlayback = () => {
            const highlightIndex = this.textMetadata.getTimestampsAtTime(this.playback.time).to;
            this.scrollToIndex(Math.max(0, highlightIndex - 1));
        };
        this.initializeAligner = (trsx) => {
            this.documentAligner.fromTrsx(trsx);
            this.execTextChange({ runAligner: false, requestSave: false }, () => {
                this.documentAligner.highlightMissingTimestamps(0, this.getLength());
            });
        };
        this.getText = (indexFrom = 0, length = this.getLength()) => {
            const { quill } = this;
            if (quill === undefined) {
                return '';
            }
            if (this.cachedEditorText === null) {
                this.cachedEditorText = quill.getText();
            }
            return this.cachedEditorText.substring(indexFrom, indexFrom + length);
        };
        this.insertTextWithFormat = (index, text, format, source = 'user') => {
            var _a;
            if (index >= this.getLength()) {
                throw Error('cannot insert text after document end');
            }
            try {
                (_a = this.quill) === null || _a === void 0 ? void 0 : _a.insertText(index, text, format, source);
            }
            catch (error) {
                global.logger.error('cannot insert text', { text, index }, error);
                // error when inserting or deleting text has catastrophic consequences:
                // metadata lose sync with text
                this.onCriticalError();
            }
        };
        this.formatText = (index, length, format, source) => {
            var _a;
            try {
                (_a = this.quill) === null || _a === void 0 ? void 0 : _a.formatText(index, length, format, source);
            }
            catch (error) {
                global.logger.error('cannot format text', { index, length }, error);
                this.onCriticalError();
            }
        };
        this.getSelection = () => {
            var _a, _b;
            return (_b = (_a = this.quill) === null || _a === void 0 ? void 0 : _a.getSelection()) !== null && _b !== void 0 ? _b : null;
        };
        this.setSelection = (start, length, source = 'user') => {
            var _a;
            (_a = this.quill) === null || _a === void 0 ? void 0 : _a.setSelection(start, length, source);
        };
        this.getIndex = (blot) => {
            if (this.quill === undefined)
                throw Error('quill is undefined');
            return this.quill.getIndex(blot);
        };
        this.getFormat = (index, length = 1) => {
            if (this.quill === undefined)
                throw Error('quill is undefined');
            return this.quill.getFormat(index, length);
        };
        this.getSelectedText = () => {
            const selectionRange = this.getSelection();
            if (selectionRange === null || selectionRange.length === 0) {
                return '';
            }
            const isSelectedTextSpeaker = this.getLineFormat(selectionRange.index).speaker !== undefined;
            if (isSelectedTextSpeaker) {
                return '';
            }
            return this.getText(selectionRange.index, selectionRange.length).trim();
        };
        this.deleteText = (index, length, source = 'user') => {
            var _a;
            try {
                (_a = this.quill) === null || _a === void 0 ? void 0 : _a.deleteText(index, length, source);
            }
            catch (error) {
                global.logger.error('cannot delete text', { index, length }, error);
                this.onCriticalError();
            }
        };
        this.scrollToIndex = (index) => {
            if (this.quill === undefined)
                return;
            const quillContainer = this.quill.root.parentElement;
            const [blot] = this.quill.getLeaf(index);
            scrollElementIntoView(blot.domNode.parentElement, quillContainer);
        };
        // Function focus is better than quill.setSelection, because it does not cause bug
        // in chrome that editor scrolls so that selection is on page bottom when settings
        // focus this way.
        this.focus = (index_) => {
            var _a, _b;
            if (this.quill === undefined)
                throw Error('quill is undefined');
            let index = (_b = index_ !== null && index_ !== void 0 ? index_ : (_a = this.getLastSelection()) === null || _a === void 0 ? void 0 : _a.index) !== null && _b !== void 0 ? _b : 0;
            while (index < this.getLength() - 2
                && this.isNonTranscriptFormat(this.getLineFormat(index))) {
                index += 1;
            }
            const [blot, offset] = this.quill.getLeaf(index);
            if (blot === null)
                return;
            const selection = window.getSelection();
            if (selection === null)
                return;
            selection.removeAllRanges();
            const range = new Range();
            range.setEnd(blot.domNode, offset);
            range.collapse();
            selection.addRange(range);
            // NOTE: It is important to handle this change immediately, because the event from quill is
            // fired with a delay. We need to be sure that after executing focus, last selection metadata
            // is correct.
            this.handleSelectionChanged({ index, length: 0 });
        };
        this.getPlaybackVerticalPosition = () => {
            var _a, _b;
            if (this.quill === undefined)
                throw Error('quill is undefined');
            const highlightIndex = this.textMetadata.getTimestampsAtTime(this.playback.time).to;
            const [playbackBlot] = this.quill.getLeaf(highlightIndex);
            const playbackElementRect = (_a = playbackBlot === null || playbackBlot === void 0 ? void 0 : playbackBlot.domNode.parentElement) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            return (_b = playbackElementRect === null || playbackElementRect === void 0 ? void 0 : playbackElementRect.bottom) !== null && _b !== void 0 ? _b : null;
        };
        this.syncScroll = (requiredPlaybackVerticalPosition) => {
            if (this.quill === undefined)
                throw Error('quill is undefined');
            const quillContainer = this.quill.root.parentElement;
            const ourVerticalPosition = this.getPlaybackVerticalPosition();
            if (ourVerticalPosition === null || requiredPlaybackVerticalPosition === null) {
                return;
            }
            if (Math.abs(ourVerticalPosition - requiredPlaybackVerticalPosition) > 1) {
                quillContainer.scrollBy(0, ourVerticalPosition - requiredPlaybackVerticalPosition);
            }
        };
        this.cleanupOperation = (op) => {
            let newOp = {};
            if (op.insert !== undefined && typeof op.insert === 'string') {
                if (this.settings.nonBreakingSpace === true && op.insert.includes('\u00a0') === true) {
                    newOp.insert = op.insert.replaceAll('\u00a0', NBSP_DOT_SYMBOL);
                }
                else if (op.insert.trim() === TIME_ANCHOR_SYMBOL) {
                    // NOTE: This can happen when only the timeanchor is copied, i.e. without any text.
                    newOp.insert = '';
                }
                else {
                    newOp.insert = op.insert;
                }
                if (op.attributes) {
                    if (op.attributes.timeAnchor !== undefined) {
                        newOp.insert = '';
                    }
                    newOp.attributes = {};
                    if (op.attributes.header === 1) {
                        newOp.attributes.header = op.attributes.header;
                    }
                    if (op.attributes.section !== undefined) {
                        newOp.attributes.section = op.attributes.section;
                    }
                    if (op.attributes.summary !== undefined) {
                        newOp.attributes.summary = op.attributes.summary;
                    }
                    if (op.attributes.speaker !== undefined) {
                        newOp.attributes = Object.assign(Object.assign({}, op.attributes), { color: '#666' });
                        this.createPastedSpeaker(op.insert, op.attributes.speaker);
                    }
                }
            }
            else if (op.insert !== undefined && typeof op.insert !== 'string') {
                return null; // ignore weird inserts such as images
            }
            else {
                newOp = op;
            }
            return newOp;
        };
        this.getNextWordStart = (index) => {
            if (index === 0)
                return 0;
            // if word start is focused, insert there
            if (WORD_BOUNDARY_CHAR_REGEX.test(this.getText(index - 1, 1)))
                return index;
            for (let i = index; i <= this.getLength(); i += 1) {
                // insert at paragraph end
                if (this.getText(i, 1) === '\n')
                    return i;
                // insert after the space after the focused word
                if (WORD_BOUNDARY_CHAR_REGEX.test(this.getText(i, 1))) {
                    return i + 1;
                }
            }
            return this.getLength();
        };
        /*
          Moves caret toward playback.
          The caret should be at a word end, behind the audio with the delay
          defined by settings.caretDelay.
        */
        this.syncCaretWithPlayback = () => {
            // the time, where the caret should actually be moved
            const selectionRange = this.getSelection();
            let caretTime = this.playback.time;
            if (selectionRange) {
                const selectionIndex = selectionRange.index;
                const activeBegin = this.textMetadata.getBeginAtIndex(selectionIndex);
                const activeEnd = this.textMetadata.getEndAtIndex(selectionIndex);
                /* if the caret is behind the played time, but with lower delay
                       than settings.caretDelay, don't move it.
                    */
                if (this.playback.time - this.settings.caretDelay <= activeEnd
                    && this.playback.time >= activeBegin) {
                    return;
                }
                // if we are supposed to move forward, move with a delay.
                if (activeBegin < this.playback.time - this.settings.caretDelay) {
                    caretTime = this.playback.time - this.settings.caretDelay;
                }
            }
            this.moveCaretToTime(caretTime);
        };
        this.decolorHighlightSearchResult = () => {
            this.execTextChange({ runAligner: false, requestSave: false }, () => {
                if (this.fullTextHighlightState !== 'nothing-highlighted') {
                    this.formatText(0, this.getLength(), { search: false }, 'api');
                    this.fullTextHighlightState = 'nothing-highlighted';
                }
                void this.timestampLabels.update(0, this.getLength(), false);
            });
        };
        this.wasLastChangeNewLineDelete = () => {
            if (this.quill === undefined)
                return false;
            const undoStack = this.quill.getModule('history').stack.undo;
            if (undoStack.length === 0)
                return false;
            const lastChange = undoStack[undoStack.length - 1].undo;
            return lastChange.ops.some((op) => typeof op.insert === 'string' && op.insert.includes('\n'));
        };
        this.assertConsistency = () => {
            if (this.getLength() !== this.textMetadata.getLength()) {
                global.logger.error('Asserting consistency failed. Text metadata are in inconsistent state.', {
                    quillLength: this.getLength(),
                    metadataLength: this.textMetadata.getLength(),
                });
                // NOTE: something horrible happened and metadata are in inconsistent state.
                // Throw an error to avoid saving something broken into trsx.
                throw Error('inconsistent metadata');
            }
        };
        this.handleMouseDown = (event) => {
            // the user set the caret to a new location.
            // Pause the media player, because otherwise it would move the cursor back.
            if (this.settings.syncCaret) {
                this.playback.pause();
            }
            this.mouseDownTarget = event.target;
        };
        this.handleMouseUp = (event) => {
            var _a, _b, _c;
            if (this.readOnlyProject) {
                return;
            }
            // dragging to the element does not count as a click
            if (this.mouseDownTarget !== event.target)
                return;
            const target = event.target;
            if (target === null)
                return;
            const isSpeaker = (_b = (_a = target.parentElement) === null || _a === void 0 ? void 0 : _a.classList.contains('speaker')) !== null && _b !== void 0 ? _b : false;
            const isSummary = target.classList.contains('summary');
            const isSectionName = target.closest('H2') !== null;
            if (event.button === 0 && isSpeaker) {
                const h4Node = target.parentElement;
                if (isOsMac ? event.metaKey : event.ctrlKey) {
                    // on Mac metaKey is cmd key
                    this.handleReplaceSpeaker(h4Node, true, false);
                }
                else {
                    const speakerBlot = Quill.find(h4Node);
                    (_c = this.quill) === null || _c === void 0 ? void 0 : _c.setSelection(speakerBlot.offset() + speakerBlot.length(), 0, 'user');
                    this.handleReplaceSpeaker(h4Node, false, false);
                }
            }
            if (event.button === 0 && isSummary) {
                this.summary.handleGenerateSummary(target);
            }
            if (event.button === 0 && isSectionName && this.sectionNamesList !== null) {
                this.onSectionHeadingClicked(target);
            }
        };
        this.handleTab = () => {
            if (this.session.login.user.settings.isAudioDelayed && !this.playback.playing) {
                this.playback.delayAudio(this.session.login.user.settings.audioDelay);
            }
            else {
                this.playback.togglePlay();
            }
        };
        this.handleShiftTab = () => {
            const timestamp = this.getActiveTimestamp();
            if (timestamp !== null) {
                this.playback.playFromTime(timestamp);
            }
        };
        this.handleUpdatePlaybackTime = () => {
            this.syncTextHighlightWithPlayback();
        };
        this.extendToWraplineEnd = (index) => {
            var _a, _b;
            if (index >= this.getLength()) {
                return this.getLength();
            }
            const range = this.createCollapsedRangeAt(index);
            if (range === null || this.quill === undefined) {
                return index;
            }
            const initialHeight = range.getBoundingClientRect().height;
            let height = initialHeight;
            let endNode = range.endContainer;
            let endIndex = index;
            while (height === initialHeight) {
                endIndex += 1;
                const leaf = this.quill.getLeaf(endIndex);
                if (leaf[0] === null) {
                    return index;
                }
                if (range.endOffset === ((_b = (_a = endNode.textContent) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0)) {
                    endNode = leaf[0].domNode;
                    range.setEnd(endNode, 1);
                }
                else {
                    range.setEnd(endNode, range.endOffset + 1);
                }
                height = range.getBoundingClientRect().height;
            }
            return endIndex - 1;
        };
        this.extendToWrapLineStart = (index) => {
            if (index === 0 || this.quill === undefined) {
                return 0;
            }
            const range = this.createCollapsedRangeAt(index);
            if (range === null) {
                return index;
            }
            const initialHeight = range.getBoundingClientRect().height;
            let height = initialHeight;
            let startNode = range.startContainer;
            let startIndex = index;
            while (height === initialHeight) {
                startIndex -= 1;
                const leaf = this.quill.getLeaf(startIndex);
                if (leaf[0] === null) {
                    return index;
                }
                if (range.startOffset === 0) {
                    startNode = leaf[0].domNode;
                    range.setStart(startNode, startNode.textContent === null ? 0 : startNode.textContent.length - 1);
                }
                else {
                    range.setStart(startNode, range.startOffset - 1);
                }
                height = range.getBoundingClientRect().height;
            }
            return startIndex + 1;
        };
        this.createCollapsedRangeAt = (index) => {
            var _a;
            const range = document.createRange();
            const leaf = (_a = this.quill) === null || _a === void 0 ? void 0 : _a.getLeaf(index);
            if (leaf === undefined || leaf[0] === null) {
                return null;
            }
            const node = leaf[0].domNode;
            const offset = leaf[1];
            range.setStart(node, offset);
            range.setEnd(node, offset);
            return range;
        };
        this.session = session;
        this.playback = playback;
        this.settings = session.login.user.settings;
        this.language = null;
        this.areSectionTagsAllowed = areSectionTagsAllowed;
        this.sectionNamesList = sectionNamesList;
        this.onDocumentChanged = () => { };
        this.onSelectSpeaker = () => { };
        this.onReplaceSpeaker = () => { };
        this.onTriggerFindAndReplace = () => { };
        this.onGenerateSummary = () => { };
        this.onSectionHeadingClicked = () => { };
        this.caretTimeRef = caretTimeRef;
        this.setCaptionMode = setCaptionMode;
        this.onCriticalError = onCriticalError;
        this.onMessage = onMessage;
        this.readOnlyProject = readOnlyProject;
        this.textMetadata = new TextMetadata();
        this.lastHandledMessageId = -1;
        this.lastHandledPhraseTime = -1;
        this.textChangeDirectives = {
            updateMetadata: true,
            runAligner: true,
            fireEvent: true,
            requestSave: true,
        };
        this.documentAligner = new DocumentAlignment(this);
        this.captions = new Captions(this);
        this.expandableAbbreviations = new ExpandableAbbreviations(this);
        this.keywords = new Keywords(this);
        this.summary = new Summary(this);
        this.sections = new Sections(this, sectionNamesList);
        this.timestampLabels = new TimestampLabels(this, this.settings.enableTimestampLabels);
        this.timeAnchors = new TimeAnchors(this);
        this.keyboardHandler = new KeyboardHandler(this);
        this.quillBindings = new QuillBindings(this);
        this.documentAligner.addEventListener(ALIGNER_EVENTS.ALIGNED, this.captions.handleDocumentAligned);
        this.documentAligner.addEventListener(ALIGNER_EVENTS.ALIGNED, this.timestampLabels.update);
        this.captions.addEventListener(CAPTION_EVENTS.CHANGED, ({ from, to }) => this.timestampLabels.updateTimeRange(from, to));
        this.previousHighlightIndex = 'reset';
        this.playback.addEventListener(PlaybackEvents.TimeUpdate, this.handleUpdatePlaybackTime);
        this.fullTextHighlightState = 'nothing-highlighted';
        this.emitter = new EventEmitter();
    }
    addEventListener(event, listener) {
        this.emitter.on(event, listener);
    }
    removeEventListener(event, listener) {
        this.emitter.off(event, listener);
    }
    setLanguage(language) {
        this.language = language;
    }
    disableEditing() {
        var _a;
        (_a = this.quill) === null || _a === void 0 ? void 0 : _a.disable();
    }
    getRecentEditWarning() {
        try {
            const lastEdit = dayjs(this.trsxManager.trsxCreatedTime).unix();
            const sinceLastEdit = dayjs().unix() - lastEdit;
            if (sinceLastEdit > 120)
                return null;
            if (this.trsxManager.lastEditorUsername === null) {
                return null;
            }
            if (this.session.login.user.email === this.trsxManager.lastEditorUsername) {
                return null;
            }
            const warning = txt('editedSecondsAgo')
                .replace('<timestamp>', String(sinceLastEdit))
                .replace('<user>', this.trsxManager.lastEditorUsername);
            return warning;
        }
        catch (error) {
            global.logger.error(error);
            return null;
        }
    }
    updateMediaStartDatetime(datetime) {
        this.timestampLabels.updateMediaStartDatetime(this.settings.showMediaDate ? datetime : null);
    }
    mount(editorElement, editorPriority) {
        const bindings = this.quillBindings.getBindings();
        this.quill = new Quill(editorElement, {
            modules: {
                keyboard: {
                    bindings,
                },
                history: {
                    delay: 1000,
                    maxStack: 500,
                    userOnly: true,
                },
                findAndReplace: this,
            },
            formats: QUILL_FORMATS,
            scrollingContainer: editorElement, // fixes issue 474
        });
        this.rootElement = editorElement;
        this.speakers = new Speakers(this, this.session);
        this.quill.on('text-change', this.handleQuillTextChanged);
        this.quill.on('selection-change', this.handleSelectionChanged);
        this.trsxManager = new TrsxManager(this, this.session.login.user.email);
        this.quill.root.spellcheck = this.settings.spellCheck;
        this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            const ops = delta.ops.map(this.cleanupOperation).filter((op) => op !== null);
            const newDelta = new Delta();
            newDelta.ops = ops;
            return newDelta;
        });
        CustomColor.blotName = 'customColor';
        CustomColor.tagName = 'FONT';
        Quill.register(CustomColor, true);
        this.quill.root.addEventListener('mousedown', this.handleMouseDown);
        this.quill.root.addEventListener('mouseup', this.handleMouseUp);
        this.quill.root.addEventListener('keydown', this.keyboardHandler.handleEditorKeyDown);
        if (editorPriority === 'main') {
            registerGlobalShortcut('Tab', this.handleTab);
            registerGlobalShortcut('Shift+Tab', this.handleShiftTab);
            if (!this.readOnlyProject) {
                registerGlobalShortcut('Ctrl+H', () => this.onTriggerFindAndReplace('open-find-replace'));
            }
        }
        if (this.summary.getEnabled()) {
            this.quill.root.classList.add('show-summaries');
        }
        if (this.sectionNamesList !== null) {
            this.quill.root.classList.add('sections-select-enabled');
        }
    }
    getContents() {
        if (this.quill === undefined) {
            // NOTE: The editor should always be mounted when this function is called.
            // If it is not, it is a programming error.
            throw new Error('editor is not mounted');
        }
        return this.quill.getContents();
    }
    updateContents(delta, source) {
        var _a;
        (_a = this.quill) === null || _a === void 0 ? void 0 : _a.updateContents(delta, source);
    }
    getHistory() {
        var _a;
        return (_a = this.quill) === null || _a === void 0 ? void 0 : _a.getModule('history');
    }
    getFindAndReplace() {
        var _a;
        return (_a = this.quill) === null || _a === void 0 ? void 0 : _a.getModule('findAndReplace');
    }
    copyDocumentToClipboard() {
        var _a;
        (_a = this.quill) === null || _a === void 0 ? void 0 : _a.setSelection(0, this.getLength());
        document.execCommand('copy');
    }
    forceValidCaretPosition(index) {
        var _a;
        let newIndex = index;
        while (!this.isEditable(this.getLineFormat(newIndex, false))) {
            newIndex += 1;
            if (newIndex >= this.getLength()) {
                throw new Error(`cannot force valid selection for index ${newIndex}`);
            }
        }
        if (newIndex > index) {
            (_a = this.quill) === null || _a === void 0 ? void 0 : _a.setSelection(newIndex, 0, 'api');
        }
    }
    /* gets the speaker node corresponding to the given position in text.
       If the index is in a speaker node, that node is returned.
       If the index is in a paragraph, the speaker node before that paragraph
       is returned (if present). */
    getSpeakerNode(index) {
        const blot = this.getBlock(index);
        let speakerNode = null;
        const { tagName } = blot.domNode;
        if (tagName === 'P') {
            if (blot.prev) {
                speakerNode = blot.prev.domNode;
            }
        }
        if (blot.domNode.tagName === 'H4') {
            speakerNode = blot.domNode;
        }
        if (speakerNode && speakerNode.classList.contains('speaker')) {
            return speakerNode;
        }
        return null;
    }
    /* returns the current selection if possible, or the last selection
       before blur. If the editor was blurred, forceGetQuillSelection
       focuses the editor on the previous selection. */
    forceGetQuillSelection() {
        const selection = this.getSelection();
        if (!selection) {
            this.focus();
            return this.getSelection();
        }
        return selection;
    }
    getBlockStarts(from, to) {
        const text = this.getText(from, to - from);
        const blockStarts = [from];
        let lineEndIndex = text.indexOf('\n');
        while (lineEndIndex !== -1) { // go over all document blocks in the range
            const blockTo = from + lineEndIndex + 1;
            blockStarts.push(blockTo);
            lineEndIndex = text.indexOf('\n', lineEndIndex + 1);
        }
        if (blockStarts[blockStarts.length - 1] !== to) {
            blockStarts.push(to);
        }
        return blockStarts;
    }
    selectSpeakerFromToolbar() {
        if (this.settings.syncCaret) {
            this.playback.pause();
        }
        this.handleSelectSpeaker(false);
    }
    handleSelectSpeaker(insertingSpeakerBeforeParagraph) {
        const range = this.forceGetQuillSelection();
        if (this.getLineFormat(range.index).summary !== undefined) {
            void this.onMessage('info', txt('speakerSummaryError'));
            return;
        }
        this.insertingSpeakerBeforeParagraph = insertingSpeakerBeforeParagraph;
        this.onSelectSpeaker();
    }
    handleRemoveSpeaker(speakerNode) {
        const speakerBlot = Quill.find(speakerNode);
        this.speakers.removeSpeaker(speakerBlot, 'user');
    }
    handleReplaceSpeaker(speakerNode, replacingEverywhere, insertingSpeakerBeforeParagraph) {
        this.insertingSpeakerBeforeParagraph = insertingSpeakerBeforeParagraph;
        this.onReplaceSpeaker(speakerNode, replacingEverywhere);
    }
    moveCaretToTime(time) {
        let caretIndex = this.textMetadata.getTimestampsAtTime(time).to;
        while (caretIndex > 0 && !NONSPACE_REGEX.test(this.getText(caretIndex - 1, 1))) {
            caretIndex -= 1;
        }
        this.moveCaretToIndex(caretIndex);
    }
    syncTextHighlightWithPlayback(forceSyncCaret = false, forceReset = false) {
        const shouldSyncCaret = (this.playback.playing && this.settings.syncCaret) || forceSyncCaret;
        if (shouldSyncCaret) {
            this.syncCaretWithPlayback();
        }
        const selectionRange = this.getSelection();
        const wasSelectionRightToLeft = isSelectionRightToLeft();
        const highlightIndex = this.textMetadata.getTimestampsAtTime(this.playback.time).to;
        this.execTextChange({
            updateMetadata: false,
            runAligner: false,
            requestSave: false,
            fireEvent: false,
        }, () => {
            var _a, _b, _c, _d;
            if (this.previousHighlightIndex === 'reset' || forceReset) {
                // universal update
                (_a = this.quill) === null || _a === void 0 ? void 0 : _a.formatText(0, highlightIndex, { color: '#000' }, 'api');
                (_b = this.quill) === null || _b === void 0 ? void 0 : _b.formatText(highlightIndex, this.getLength() - highlightIndex, { color: '#666' }, 'api');
            }
            else if (this.previousHighlightIndex === highlightIndex) {
                // do nothing
            }
            else if (this.previousHighlightIndex < highlightIndex) {
                // fast update
                (_c = this.quill) === null || _c === void 0 ? void 0 : _c.formatText(this.previousHighlightIndex, highlightIndex - this.previousHighlightIndex, { color: '#000' }, 'api');
            }
            else { // this.previousHighlightIndex > highlightIndex
                (_d = this.quill) === null || _d === void 0 ? void 0 : _d.formatText(highlightIndex, this.previousHighlightIndex - highlightIndex, { color: '#666' }, 'api');
            }
        });
        if (this.previousHighlightIndex !== highlightIndex) {
            this.scrollToIndex(Math.max(0, highlightIndex - 1));
        }
        this.previousHighlightIndex = highlightIndex;
        if (selectionRange && selectionRange.length > 0) {
            // quill.formatText() may have broken original selection, need to set it again
            this.setSelection(selectionRange.index, selectionRange.length);
            // quill.setSelection() always selects from left to right
            // if original selection was right to left, we need to revert it
            if (wasSelectionRightToLeft && !isSelectionRightToLeft()) {
                revertSelection();
            }
        }
    }
    exportDocument(shouldAssertConsistency = false) {
        if (shouldAssertConsistency) {
            this.assertConsistency();
        }
        return this.trsxManager.export();
    }
    alignDocument() {
        this.documentAligner.alignAll();
        this.triggerSave();
    }
    importTrsx(trsx, shouldRequestSave, project, removeCaptions = false) {
        if (this.quill === undefined) {
            throw Error('cannot import trsx because editor is not mounted');
        }
        this.currentTrsxId = project.currentTrsxId;
        this.execTextChange({
            updateMetadata: false, runAligner: false, requestSave: shouldRequestSave, fireEvent: false,
        }, () => {
            this.trsxManager.import(trsx, false, undefined, removeCaptions);
            let captionParameters = null;
            if (this.trsxManager.captionMetadata) {
                captionParameters = Object.assign({}, this.trsxManager.captionMetadata);
            }
            this.captions.loadParameters(captionParameters);
            this.previousHighlightIndex = 'reset'; // forces highlight redraw when replacing trsx
            this.syncTextHighlightWithPlayback(false); // fixes issue https://trello.com/c/F9fFZYkl/1291
            this.timestampLabels.update(0, this.getLength());
            void this.keywords.highlightAllKeywords(project.keywordsHighlight);
        });
        this.execTextChange({ runAligner: false, requestSave: false }, () => {
            // initializing captions may do changes in document,
            // so we need to run in with updateMetadata=true
            this.captions.initialize();
        });
        this.setCaptionMode(this.captions.enabled);
        // used for speaker colors - if caption mode is on, speaker colors are displayed
        if (this.captions.enabled) {
            this.quill.root.classList.add('caption-mode-on');
        }
        else {
            this.quill.root.classList.remove('caption-mode-on');
        }
    }
    importDump(text) {
        const json = JSON.parse(text);
        this.execTextChange({
            updateMetadata: false,
            runAligner: false,
            requestSave: false,
            fireEvent: false,
        }, () => {
            var _a;
            (_a = this.quill) === null || _a === void 0 ? void 0 : _a.setContents(json.quillContents);
            // TODO: @chocoman: this.speakers = Speakers.fromDump(json.speakers);
            this.textMetadata = TextMetadata.fromDump(json.metadata);
            this.captions.loadParameters(json.captionMetadata);
        });
    }
    fillPartialTrsx(trsx) {
        // if there is already loaded a non-partial trsx, do not fill.
        if (this.lastHandledPhraseTime === Infinity)
            return;
        this.execTextChange({ updateMetadata: false, runAligner: false, requestSave: true }, () => {
            this.trsxManager.import(trsx, true, this.lastHandledPhraseTime, false, true);
        });
    }
    clearFormating() {
        const range = this.forceGetQuillSelection();
        const formats = ['header', 'section'];
        formats.forEach((format) => {
            if (this.getLineFormat(range.index)[format] !== undefined) {
                this.formatLine(range.index, range.length, format, false, 'user', true);
            }
        });
        this.focus(); // place focus back into editor
    }
    formatLine(index, length, formatName, formatValue, source = 'user', requestSave = false) {
        var _a;
        if (this.quill === undefined)
            return;
        try {
            const nextNewLineIndex = this.getNextNewLineIndex(index + length);
            if (nextNewLineIndex === null) {
                global.logger.warn('cannot format line - no line end found', { index });
                return;
            }
            this.textMetadata.spliceMetadata(// clear metadata so that it is not outdated
            'lineFormatCache', nextNewLineIndex, nextNewLineIndex + 1, []);
            const lineStart = this.getLineStart(index);
            const textLength = nextNewLineIndex - lineStart;
            const previousFormat = this.quill.getFormat(lineStart, 0);
            if (previousFormat.color === undefined) {
                previousFormat.color = '#666';
            }
            if (((_a = this.getSelection()) === null || _a === void 0 ? void 0 : _a.index) === lineStart && textLength === 0) {
                // HACK: This is a special edge case, when the line is empty. In this case quill does
                // not create span inside h2 or h1, which we need for widgets to work properly.
                // Quill has it's magic workaround for this case which involves creating empty span
                // ql-cursor, but this works in quill.format only (format at selection).
                // Also this does not fix the case when user types in the heading and then deletes it
                // and starts typing again.
                this.quill.format('color', previousFormat.color);
            }
            this.quill.formatLine(lineStart, textLength, formatName, formatValue, source);
            // NOTE: quill.formatLine does not allow changing color, so we need to set it with
            // separate operation formatText. formatText must be after formatLine, to avoid infinite
            // cycle in line format validation (for example fixFirstProblem in summaries.)
            this.quill.formatText(lineStart, textLength, 'color', previousFormat.color, source);
            if (requestSave) { // changing format does not trigger save implicitly
                this.triggerSave();
            }
        }
        catch (error) {
            global.logger.error('cannot format line', { index, length }, error);
            this.onCriticalError();
        }
    }
    insertText(index, text, source = 'user') {
        this.insertTextWithFormat(index, text, {}, source);
    }
    blur() {
        var _a;
        (_a = this.quill) === null || _a === void 0 ? void 0 : _a.blur();
    }
    updateSpellcheck(spellCheck) {
        if (this.quill && this.quill.root.spellcheck !== spellCheck) {
            const { root } = this.quill;
            root.spellcheck = spellCheck;
            root.focus();
            root.blur();
        }
    }
    updateSettings(settings) {
        this.settings = settings;
        this.updateSpellcheck(settings.spellCheck);
        void this.timestampLabels.setEnabled(settings.enableTimestampLabels);
    }
    handleSpeakerColorChange(speaker, color, isDefaultColor) {
        const newSpeaker = Speaker.fromSpeaker(speaker);
        newSpeaker.captionColor = color;
        newSpeaker.isDefaultColor = isDefaultColor;
        this.speakers.addDocumentSpeaker(newSpeaker);
        this.speakers.replaceAllSpeakers(speaker.id, newSpeaker);
        this.emitter.emit('widget-changed');
    }
    handleSpeakerSelected(speaker, replacingEverywhere, replacedSpeaker) {
        if (replacingEverywhere && replacedSpeaker !== null) {
            this.speakers.replaceAllSpeakers(replacedSpeaker.id, speaker);
        }
        else if (this.insertingSpeakerBeforeParagraph) {
            this.speakers.addSpeakerBeforeParagraph(speaker);
        }
        else {
            this.speakers.addSpeakerOnCaret(speaker);
        }
        this.speakers.addDocumentSpeaker(speaker);
    }
    handleCloseSpeakerSelector() {
        // cannot use quill.setSelection() to avoid issue 394 in Chrome (scrolling selection to bottom)
        this.focus();
    }
    handleSectionTagsSelected(tags, node) {
        if (this.quill === undefined)
            return;
        const blot = Quill.find(node);
        const index = this.getIndex(blot);
        const sectionId = this.getLineFormat(index).section.id;
        const formatValue = {
            tags,
            id: sectionId === '' ? null : sectionId,
        };
        this.formatLine(index, 1, 'section', formatValue, 'user', true);
        this.emitter.emit('widget-changed');
    }
    destroy() {
        this.playback.removeEventListener(PlaybackEvents.TimeUpdate, this.handleUpdatePlaybackTime);
        this.captions.destroy();
        unregisterGlobalShortcut('Tab');
        unregisterGlobalShortcut('Shift+Tab');
        unregisterGlobalShortcut('Ctrl+H');
    }
    getBlock(index) {
        if (this.quill === undefined)
            throw Error('quill is undefined');
        let blot = this.quill.getLeaf(index)[0];
        while (blot.parent !== blot.scroll) {
            blot = blot.parent;
        }
        return blot;
    }
    insertHeading1() {
        const range = this.forceGetQuillSelection();
        const currentFormat = this.getLineFormat(range.index);
        if (currentFormat.summary !== undefined) {
            void this.onMessage('info', txt('headingPlacementError'));
            return;
        }
        if (currentFormat.header === 1) {
            this.formatLine(range.index, range.length, 'header', false, 'user', true);
        }
        else {
            this.formatLine(range.index, range.length, 'header', 1, 'user', true);
        }
    }
    getLineFormat(index, useCache = true) {
        if (this.quill === undefined)
            throw Error('quill is undefined');
        if (index >= this.getLength() || index < 0) {
            return {};
        }
        if (!useCache) {
            return normalizeFormat(this.quill.getFormat(index));
        }
        const newLineIndex = this.getNextNewLineIndex(index);
        if (newLineIndex === null) {
            return {};
        }
        const cachedFormat = this.textMetadata.getMetadataAtIndex(newLineIndex, 'lineFormatCache');
        if (cachedFormat !== null) {
            return cachedFormat;
        }
        const format = normalizeFormat(this.quill.getFormat(newLineIndex));
        this.textMetadata.addMetadata('lineFormatCache', newLineIndex, format);
        return format;
    }
    getLength() {
        const { quill } = this;
        if (quill === undefined) {
            return 0;
        }
        if (this.cachedEditorLength === null) {
            this.cachedEditorLength = quill.getLength();
        }
        return this.cachedEditorLength;
    }
    getEditorId() {
        return this.editorId;
    }
    insertCaptionEnd() {
        if (!this.captions.enabled) {
            return;
        }
        const selection = this.getSelection();
        if (selection === null)
            return;
        const index = this.getNextWordStart(selection.index);
        const value = this.textMetadata.getEndAtIndex(index - 1);
        if (this.getText(index, 1) === CAPTION_END_SYMBOL
            || this.getText(index - 1, 1) === CAPTION_END_SYMBOL) {
            void this.onMessage('info', txt('captionErrorSamePlace'));
            return;
        }
        const format = this.getLineFormat(index);
        if (this.isNonTranscriptFormat(format)) {
            void this.onMessage('info', txt('captionPlacementError'));
            return;
        }
        this.insertTextWithFormat(index, CAPTION_END_SYMBOL, {
            captionEnd: value,
            timeAnchor: false,
            speaker: false,
        }, 'user');
        this.textMetadata.addMetadata('captionEnd', index, value);
        this.captions.updateCaptions(index - 3, index + 3, true); // both new captions
    }
    getSelectionTimeRange() {
        const selection = this.getLastSelection();
        if (!selection || selection.length === 0)
            return null;
        const begin = this.textMetadata.getBeginAtIndex(selection.index);
        const end = this.textMetadata.getEndAtIndex(selection.index + selection.length);
        if (end === Infinity) {
            return null;
        }
        return { begin, end };
    }
    getLastSelection() {
        const metadata = this.textMetadata.getMetadataInRange(0, this.getLength(), 'lastSelection');
        if (metadata.length < 2 || metadata[0][1] !== 'start' || metadata[1][1] !== 'end') {
            logger.warn('last selection metadata is missing or invalid');
            return null;
        }
        const start = metadata[0][0];
        const end = metadata[1][0];
        return { index: start, length: end - start };
    }
    getLineStart(from) {
        const textToIndex = this.getText(0, from);
        for (let i = from; i >= 0; i -= 1) {
            if (textToIndex[i] === '\n') {
                return i + 1;
            }
        }
        return 0;
    }
    getNextNewLineIndex(from) {
        if (from >= this.getLength()) {
            return null;
        }
        const textFromIndex = this.getText(from, this.getLength() - from);
        let newLineRelativeIndex = textFromIndex.indexOf('\n');
        if (newLineRelativeIndex === -1) {
            // this happens in some mysterious cases when document does not end with newline
            newLineRelativeIndex = textFromIndex.length;
        }
        return from + newLineRelativeIndex;
    }
    createPastedSpeaker(text, speakerFormatValue) {
        var _a, _b;
        const speakerFormatValueParts = speakerFormatValue.split(' ');
        const speakerId = speakerFormatValueParts[0];
        const speakerColor = speakerFormatValueParts.length > 1
            ? speakerFormatValueParts[1]
            : null;
        if (this.speakers.getSpeakerById(speakerId)) {
            // no need to create speaker
            return;
        }
        // create speaker for cases when it is copied from another project
        const { firstName, lastName, role, } = parseSpeaker(text.trim());
        const speaker = new Speaker(firstName, lastName, role, speakerId, null, null, false, (_b = (_a = this.language) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : null, new Map(), speakerColor);
        this.speakers.addDocumentSpeaker(speaker);
    }
    moveCaretToIndex(index) {
        var _a;
        (_a = this.quill) === null || _a === void 0 ? void 0 : _a.setSelection(index, 0);
    }
    getActiveTimestamp() {
        const selection = this.getSelection();
        if (!selection)
            return null;
        return this.textMetadata.getBeginAtIndex(selection.index);
    }
}
