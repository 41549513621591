import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { App, Button, Form, Input, Spin, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { updateSpeaker, addSpeaker } from 'api/speaker-api';
import { Speaker } from 'api/speaker';
import { useSession } from 'components/Authenticated';
import { getCurrentUiLocale } from 'libs/locales/ui';
import { txt } from 'libs/i18n';
import FormModal from 'components/FormModal';
import SpeakerRole from '../SpeakerRole';
import styles from './style.module.less';
const speakerXmlFromRow = ({ firstName, lastName, role, key, unmaintainedMetadata, }) => {
    const speakerXml = new Speaker(firstName, lastName, role, null, key, null, false, getCurrentUiLocale().code, unmaintainedMetadata);
    return speakerXml.exportXml();
};
const SpeakersModal = ({ visible, action, onClose, speaker = null, onUpdateTable, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const headerText = action === 'addSpeaker' ? txt('addSpeaker') : txt('editSpeaker');
    const buttonText = action === 'addSpeaker' ? txt('add') : txt('save');
    const initialValues = {
        firstName: speaker !== null ? speaker.firstName : '',
        lastName: speaker !== null ? speaker.lastName : '',
        role: speaker !== null ? speaker.role : '',
        key: speaker !== null ? speaker.key : '',
        unmaintainedMetadata: speaker !== null ? speaker.unmaintainedMetadata : new Map(),
    };
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValues);
        }
    }, [visible]);
    const addNewSpeaker = async (newSpeaker) => {
        try {
            await addSpeaker(session.connection, speakerXmlFromRow(newSpeaker));
            void message.success(txt('speakerSaved'), 2);
        }
        catch (error) {
            global.logger.error('Failed to save speaker to the database', {}, error);
            void message.error(txt('failedToSaveSpeaker'));
        }
    };
    const updateExistingSpeaker = async (updatedSpeaker) => {
        try {
            await updateSpeaker(session.connection, speakerXmlFromRow(updatedSpeaker));
            void message.success(txt('speakerUpdated'), 2);
        }
        catch (error) {
            global.logger.error('Failed to update speaker in the database', {}, error);
            void message.error(txt('failedToUpdate'));
        }
    };
    const handleSubmit = async (formValues) => {
        const { firstName, lastName, role, key, unmaintainedMetadata, } = formValues;
        const trimmedFormValues = {
            firstName: (firstName !== null && firstName !== void 0 ? firstName : '').trim(),
            lastName: lastName.trim(),
            role: (role !== null && role !== void 0 ? role : '').trim(),
            key,
            unmaintainedMetadata,
        };
        setIsLoading(true);
        if (action === 'addSpeaker') {
            await addNewSpeaker(trimmedFormValues);
        }
        if (action === 'editSpeaker') {
            await updateExistingSpeaker(Object.assign(Object.assign({}, speaker), trimmedFormValues));
        }
        form.resetFields();
        setIsLoading(false);
        onUpdateTable();
        onClose();
    };
    return (_jsx(FormModal, { footer: [
            _jsx(Button, { onClick: onClose, children: txt('cancel') }, "cancel"),
            _jsxs(Button, { type: "primary", htmlType: "submit", onClick: () => form.submit(), disabled: isLoading, children: [isLoading
                        ? _jsx(Spin, { className: styles.spinnerIcon, indicator: _jsx(LoadingOutlined, {}) })
                        : null, buttonText] }, "submit"),
        ], open: visible, onCancel: onClose, title: headerText, children: _jsxs(Form, { name: "speaker-modal", form: form, initialValues: initialValues, labelCol: { span: 6 }, labelAlign: "left", onFinish: handleSubmit, preserve: false, children: [_jsx(Form.Item, { name: "firstName", label: txt('firstName'), children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { name: "lastName", label: txt('lastName'), children: _jsx(Input, {}) }), _jsx(SpeakerRole, {}), _jsx(Form.Item, { name: "key", className: styles.hiddenField, dependencies: ['firstName', 'lastName', 'role'], rules: [
                        ({ getFieldValue }) => ({
                            validator() {
                                const isAllFieldsEmpty = getFieldValue('lastName').trim() === '' && getFieldValue('firstName').trim() === '' && getFieldValue('role').trim() === '';
                                if (isAllFieldsEmpty) {
                                    return Promise.reject(txt('addAtLeastOne'));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ], children: _jsx(Input, { type: "hidden" }) }), _jsx(Form.Item, { name: "unmaintainedMetadata", className: styles.hiddenField, children: _jsx(Input, { type: "hidden" }) })] }) }));
};
export default SpeakersModal;
