import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { DatePicker, ConfigProvider, Button, Form, } from 'antd';
import dayjs from 'dayjs';
import { getCurrentAntdLocale } from 'libs/dayjs-locale';
import { txt } from 'libs/i18n';
import './style.less';
const { RangePicker } = DatePicker;
const validateRange = (range) => {
    if (range.length === 0) {
        return [null, dayjs()];
    }
    const from = range[0] !== null ? dayjs(range[0].$d) : null;
    const to = range[1] !== null ? dayjs(range[1].$d) : null;
    return [from, to];
};
const ProjectsFilterDialog = ({ onClearFilter, onFilterDatesChanged, filterDates, onDropdownVisibility, }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (filterDates.length === 0) {
            form.setFieldsValue({ rangePicker: [null, dayjs()] });
        }
    }, [filterDates]);
    const handleFilter = () => {
        const selectedDates = form.getFieldValue('rangePicker');
        onFilterDatesChanged(selectedDates);
        onDropdownVisibility(false);
    };
    const handleClearFilter = () => {
        onClearFilter();
        form.setFieldsValue({ rangePicker: [null, dayjs()] });
        onDropdownVisibility(false);
    };
    const ranges = {
        [txt('thisYear')]: [dayjs().startOf('year'), dayjs().endOf('year')],
        [txt('thisMonth')]: [dayjs().startOf('month'), dayjs().endOf('month')],
        [txt('thisWeek')]: [dayjs().startOf('week'), dayjs().endOf('week')],
        [txt('today')]: [dayjs().startOf('day'), dayjs().endOf('day')],
    };
    return (_jsx(ConfigProvider, { locale: getCurrentAntdLocale(), children: _jsxs(Form, { form: form, initialValues: {
                rangePicker: validateRange(filterDates),
            }, onFinish: handleFilter, children: [_jsx(Form.Item, { name: "rangePicker", rules: [
                        {
                            validator: async (_, date) => {
                                if (date === null || date[0] === null || date[1] === null) {
                                    return Promise.reject(txt('enterDate'));
                                }
                                return Promise.resolve();
                            },
                        },
                    ], children: _jsx(RangePicker, { allowClear: true, allowEmpty: [true, true], placeholder: [txt('startDate'), txt('endDate')], format: ['DD/MM/YYYY', 'DD/MM/YY'], showTime: {
                            format: 'HH:mm',
                            defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:59', 'HH:mm')],
                        }, ranges: ranges }) }), _jsxs("div", { className: "filter-dialog__buttons", children: [_jsx(Button, { onClick: handleClearFilter, size: "small", type: "link", children: txt('clearFilter') }), _jsx(Button, { type: "primary", htmlType: "submit", size: "small", children: txt('filter') })] })] }) }));
};
export default ProjectsFilterDialog;
