import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Spin, Alert, Card } from 'antd';
import { useSession } from 'components/Authenticated';
import { listApps } from '@newtontechnologies/beey-api-js-client/apps-api';
import { txt } from 'libs/i18n';
import DashboardPage from '../../components/DashboardPage';
import AppCard from './AppCard';
import './style.less';
const AppsPage = () => {
    const { session: { connection } } = useSession();
    const [appsList, setAppsList] = useState('fetching');
    useEffect(() => {
        const fetchApps = async () => {
            const json = await listApps(connection);
            setAppsList(json.list);
        };
        void fetchApps();
    }, []);
    const cardContent = (() => {
        if (appsList === 'fetching') {
            return (_jsx("div", { className: "apps-report", children: _jsxs("div", { children: [_jsx(Spin, {}), _jsx("span", { className: "apps-report__loading", children: txt('loadingApps') })] }) }));
        }
        if (appsList.length === 0) {
            return (_jsx("div", { className: "apps-report", children: _jsx(Alert, { message: txt('noApps'), type: "info", showIcon: true }) }));
        }
        return (_jsx("div", { className: "apps-list", children: appsList.map((app) => (_jsx(AppCard, { name: app.serviceNames[0].text, endpoint: app.proxyEndpoint }, app.proxyEndpoint))) }));
    })();
    return (_jsx(DashboardPage, { title: txt('apps'), children: _jsx(Card, { bordered: false, children: cardContent }) }));
};
export default AppsPage;
