import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import DraggableModal from 'components/DraggableModal';
import { getDialogPosition } from 'libs/dialog-position';
import { App, Badge, Button, Collapse, Tabs, Tooltip, } from 'antd';
import IconButton from 'components/IconButton';
import { CheckOutlined, StopOutlined, UndoOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { durationInSeconds } from 'libs/duration';
import { updateChecklistItem } from 'api/project-api';
import { useSession } from 'components/Authenticated';
import styles from './style.module.less';
const createCollapseItems = (problems, onResolve, onIgnore, onReturn, onHighlightProblem) => problems.map((problem) => ({
    className: styles.problemItem,
    key: problem.id,
    label: (_jsxs(_Fragment, { children: [_jsxs("span", { children: [problem.status === 'Resolved'
                        ? (_jsx(Tooltip, { title: txt('resolved'), children: _jsx(CheckOutlined, {}) }))
                        : problem.status === 'Ignored'
                            ? (_jsx(Tooltip, { title: txt('declined'), children: _jsx(StopOutlined, {}) }))
                            : null, "\u00A0", problem.level === 'Warn'
                        ? _jsx(WarningOutlined, { className: styles.iconWarning })
                        : problem.level === 'Info'
                            ? _jsx(InfoCircleOutlined, {})
                            : _jsx(CloseCircleOutlined, { className: styles.iconError }), "\u00A0", `${problem.code === undefined ? '' : `${problem.code}: `}`, problem.title] }), _jsx(Button, { className: styles.showButton, size: "small", onClick: () => onHighlightProblem(durationInSeconds(problem.location.time)), children: txt('show') })] })),
    children: _jsx("p", { children: problem.hint }),
    extra: (_jsx("span", { className: styles.buttons, children: problem.status === 'Open'
            ? (_jsxs(_Fragment, { children: [_jsx(IconButton, { tooltip: txt('resolve'), tooltipPlacement: "top", onClick: (e) => {
                            e.stopPropagation();
                            onResolve(problem.id);
                        }, children: _jsx(CheckOutlined, {}) }), _jsx(IconButton, { tooltip: problem.ignorable ? txt('decline') : txt('cannotIgnore'), disabled: !problem.ignorable, tooltipPlacement: "top", onClick: (e) => {
                            e.stopPropagation();
                            onIgnore(problem.id);
                        }, children: _jsx(StopOutlined, {}) })] })) : (_jsx(IconButton, { tooltip: txt('backToUnresolved'), tooltipPlacement: "top", onClick: (e) => {
                e.stopPropagation();
                onReturn(problem.id);
            }, children: _jsx(UndoOutlined, {}) })) })),
}));
const ProblemsDialog = ({ onClose, onHighlightProblem, projectId, checklist, }) => {
    const [problems, setProblems] = useState(checklist);
    const { session } = useSession();
    const { message } = App.useApp();
    const openProblems = problems.filter((problem) => problem.status === 'Open');
    const processedProblems = problems.filter((problem) => problem.status === 'Resolved' || problem.status === 'Ignored');
    useEffect(() => {
        if (openProblems.length > 0) {
            const { time } = openProblems[0].location;
            onHighlightProblem(durationInSeconds(time));
        }
    }, []);
    const handleResolve = (id) => {
        const newProblems = problems.map((problem) => (problem.id === id
            ? Object.assign(Object.assign({}, problem), { status: 'Resolved' }) : problem));
        setProblems(newProblems);
    };
    const handleIgnore = async (id) => {
        const currentProblem = problems.find((problem) => problem.id === id);
        if (currentProblem === undefined) {
            return;
        }
        const result = await updateChecklistItem(session.connection, projectId, Object.assign(Object.assign({}, currentProblem), { status: 'Ignored' }));
        if (result.isFail()) {
            void message.error(result.err() === 'unexpected' ? txt('problemIgnoreFail') : txt('problemIgnoreDeclined'));
            return;
        }
        const newProblems = problems.map((problem) => (problem.id === id
            ? Object.assign(Object.assign({}, problem), { status: 'Ignored' }) : problem));
        setProblems(newProblems);
    };
    const handleReturn = (id) => {
        const newProblems = problems.map((problem) => (problem.id === id
            ? Object.assign(Object.assign({}, problem), { status: 'Open' }) : problem));
        setProblems(newProblems);
    };
    const openItems = createCollapseItems(openProblems, handleResolve, handleIgnore, handleReturn, onHighlightProblem);
    const processedItems = createCollapseItems(processedProblems, handleResolve, handleIgnore, handleReturn, onHighlightProblem);
    const tabs = [
        {
            key: '1',
            label: (_jsxs("span", { className: styles.tab, children: [txt('notResolved'), "\u00A0", _jsx(Badge, { count: openProblems.length })] })),
            children: openProblems.length === 0
                ? _jsx("p", { children: txt('everythingResolved') })
                : _jsx(Collapse, { items: openItems, bordered: false, collapsible: "icon" }),
        },
        {
            key: '2',
            label: (_jsxs("span", { className: styles.tab, children: [txt('toCheck'), "\u00A0", _jsx(Badge, { count: processedProblems.length })] })),
            children: processedProblems.length === 0
                ? _jsx("p", { children: txt('nothingToCheck') })
                : _jsx(Collapse, { items: processedItems, bordered: false, collapsible: "icon" }),
        },
    ];
    return (_jsx(DraggableModal, { bounds: "body", onClose: onClose, title: "Problems", defaultPosition: getDialogPosition({ width: 500, height: 150 }, { widthFraction: 0.4, heightFraction: 0.05 }), children: _jsx(Tabs, { items: tabs }) }));
};
export default ProblemsDialog;
