import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import { CloseOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Card, Button, Spin, Divider, Row, } from 'antd';
import { txt } from 'libs/i18n';
import { handleTeamMembersExport } from 'libs/download-csv';
import { fetchTeamTranscriptions } from 'api/admin/teams-api';
import { useSession } from 'components/Authenticated';
import ColumnHeader from 'components/ColumnHeader';
import { formatDuration } from 'screens/DashboardScreen/pages/AccountPage/TranscriptionInfo/TranscribedOverview';
import DateRangeFilterDropdown from 'components/DateRangeFilterDropdown';
import StorageStatisticsInfo from 'components/StorageStatisticsInfo';
import { getDateCellContent } from 'screens/DashboardScreen/pages/AccountPage/TranscriptionInfo';
import styles from '../style.module.less';
import usageStyles from './style.module.less';
const TEAM_ADMIN_TABLE_ID = 'team-admin-table'; // Must be unique across all tables in Beey!!
const defaultSieve = {
    filterDates: [],
    sortBy: 'email',
    sortOrder: 'ascend',
};
const TeamUsageOverview = ({ team, storageStatistics, onFetchStorageStatistics, onGetTeamMembersNumber, }) => {
    var _a;
    const { session } = useSession();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [teamTranscriptions, setTeamTranscriptions] = useState('loading');
    const tableView = useTableView(TEAM_ADMIN_TABLE_ID, {
        pageSize: 10,
        sieve: defaultSieve,
    });
    const { sortBy, sortOrder, filterDates } = (_a = tableView.getViewParams().sieve) !== null && _a !== void 0 ? _a : defaultSieve;
    const oldSieve = tableView.getViewParams().sieve;
    const fetchTeamTranscriptionsState = async () => {
        const { currentPage, pageSize } = tableView.getViewParams();
        try {
            const teamData = await fetchTeamTranscriptions(session.connection, team.id, filterDates, sortBy, sortOrder === 'ascend', pageSize * (currentPage - 1), pageSize);
            setTeamTranscriptions({
                members: teamData.data.list.map((member) => ({
                    key: member.id,
                    email: member.email !== null ? member.email : '',
                    transcribedMinutes: member.chargedCredit,
                    created: getDateCellContent(member, filterDates),
                })),
                total: teamData.totalCharged,
            });
            onGetTeamMembersNumber(teamData.data.list.length);
        }
        catch (error) {
            global.logger.error('Fetching team transcriptions failed', {}, error);
        }
    };
    useEffect(() => {
        void fetchTeamTranscriptionsState();
    }, []);
    const handleSort = (columnTitle, sortingOrder) => {
        tableView.setSieve({
            sortBy: columnTitle,
            sortOrder: sortingOrder,
            filterDates: [],
        });
    };
    const handleFilterDatesChanged = (dates) => {
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filterDates: dates }));
    };
    const handleClearSieve = () => {
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filterDates: [], sortBy: 'email', sortOrder: 'ascend' }));
    };
    const columns = [
        {
            title: (_jsx(ColumnHeader, { title: "email", columnKey: "email", sortOrder: sortOrder, isSorting: sortBy === 'email', onSort: handleSort, className: usageStyles.tableHeader })),
            dataIndex: 'email',
            fixed: 'left',
        },
        {
            title: (_jsx(ColumnHeader, { title: "chargedMinutes", columnKey: "chargedMinutes", sortOrder: sortOrder, isSorting: sortBy === 'transcribedMinutes', onSort: handleSort, className: usageStyles.tableHeader })),
            dataIndex: 'transcribedMinutes',
            render: (minutes) => `${formatDuration(minutes)}`,
        },
        {
            title: txt('forPeriod'),
            className: usageStyles.tableHeader,
            dataIndex: 'created',
            onFilterDropdownOpenChange: (visibility) => setDropdownVisible(visibility),
            filterDropdownOpen: dropdownVisible,
            filterDropdown: _jsx(DateRangeFilterDropdown, { filterDates: filterDates, onDropdownVisibility: setDropdownVisible, onClearSieve: handleClearSieve, onFilterDatesChanged: handleFilterDatesChanged }),
        },
    ];
    const isDefaultSetting = (sortBy === 'email'
        && filterDates.length === 0
        && (sortOrder === 'ascend' || sortOrder === undefined));
    const totalTranscribedFooter = () => {
        if (teamTranscriptions === 'loading') {
            return _jsx(Spin, {});
        }
        const totalTranscribed = formatDuration(teamTranscriptions.total);
        const totalTranscibedLabel = filterDates.length !== 0
            ? 'totalPerPeriod'
            : 'total';
        return (_jsxs("div", { children: [_jsx("span", { children: txt(`${totalTranscibedLabel}`) }), _jsx("span", { children: totalTranscribed })] }));
    };
    return (_jsxs(Card, { bordered: false, className: styles.card, style: { width: '100%' }, title: (_jsxs("h2", { children: [_jsx(ClockCircleOutlined, {}), "\u00A0", txt('teamTranscribedOverview')] })), children: [_jsx("div", { className: styles.sectionName, children: txt('transcriptions') }), _jsx(Divider, { className: styles.divider }), (isDefaultSetting === false) && (_jsxs(Button, { className: usageStyles.clearButton, onClick: handleClearSieve, size: "small", children: [_jsx(CloseOutlined, {}), _jsx("span", { children: txt('clearAll') })] })), _jsx(ResponsiveTable, { columns: columns, bordered: true, className: usageStyles.teamUsageTable, onViewChange: fetchTeamTranscriptionsState, dataSource: teamTranscriptions === 'loading' ? [] : teamTranscriptions.members, rowKey: (member) => member.key, recordCount: teamTranscriptions === 'loading' ? 0 : teamTranscriptions.members.length, tableView: tableView, footer: () => totalTranscribedFooter() }), _jsx("div", { className: usageStyles.exportButton, children: _jsx(Button, { onClick: () => handleTeamMembersExport(session.connection, Number(team.id), filterDates, String(sortBy), sortOrder === 'ascend', team.ownerEmail), type: "primary", children: txt('downloadInCSV') }) }), _jsx("div", { className: styles.sectionName, children: txt('storage') }), _jsx(Divider, { className: styles.divider }), _jsx(Row, { className: "form-row", children: _jsx(Button, { type: "primary", onClick: () => onFetchStorageStatistics(true), children: txt('displayStorageStatistics') }) }), _jsx("div", { className: styles.storageInfo, children: _jsx(StorageStatisticsInfo, { storageStatistics: storageStatistics, onStorageStatisticsChange: onFetchStorageStatistics, userDataOnly: storageStatistics !== 'loading' ? storageStatistics.onlyUserData : true }) })] }));
};
export default TeamUsageOverview;
