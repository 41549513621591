import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch } from 'react-router-dom';
import { txt } from 'libs/i18n';
import MissingSubpage from 'components/MissingSubpage';
import DashboardPage from '../../components/DashboardPage';
import AnnouncementSubpage from './AnnouncementSubpage';
import UsersSubpage from './UsersSubpage';
import EditUserSubpage from './EditUserSubpage';
import ManagementGroupSubpage from './ManagamentGroupSubpage';
import './style.less';
const AdminPage = () => (_jsx(DashboardPage, { title: txt('administration'), children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: "/admin/users", component: UsersSubpage }), _jsx(Route, { exact: true, path: "/admin/users/edituser/:userid", component: EditUserSubpage }), _jsx(Route, { exact: true, path: "/admin/announcements", component: AnnouncementSubpage }), _jsx(Route, { exact: true, path: "/admin/groups", component: ManagementGroupSubpage }), _jsx(Route, { render: () => (_jsx(MissingSubpage, { backLink: "/admin/users", backName: "administration" })) })] }) }));
export default AdminPage;
