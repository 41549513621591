import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { txt } from 'libs/i18n';
import './style.less';
export const Alerts = {
    alert: (message, type, showIcon, description) => (_jsx(Alert, { style: { marginBottom: '1rem' }, message: message, type: type, showIcon: showIcon, description: description })),
    success: (msg, desc) => Alerts.alert(msg, 'success', true, desc),
    info: (msg, desc) => Alerts.alert(msg, 'info', true, desc),
    warning: (msg, desc) => Alerts.alert(msg, 'warning', true, desc),
    error: (msg, desc) => Alerts.alert(msg, 'error', true, desc),
    crash: (desc) => Alerts.alert(txt('unexpectedError'), 'error', true, desc),
};
export const BigAlert = ({ header = null, body, iconSize = '3rem' }) => (_jsxs("div", { className: "big-alert", children: [_jsx("div", { className: "big-alert__icon", style: { fontSize: iconSize }, children: _jsx(ExclamationCircleOutlined, {}) }), _jsxs("div", { className: "big-alert__text", children: [_jsx("div", { className: "big-alert__header", children: header }), _jsx("div", { className: "big-alert__body", children: body })] })] }));
