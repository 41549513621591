import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Spin, Collapse, Flex, Space, Button, Card, Select, Form, InputNumber, App, } from 'antd';
import { txt } from 'libs/i18n';
import * as clientEnv from 'libs/client-env';
import { ClockCircleOutlined, CheckCircleFilled, StopFilled } from '@ant-design/icons';
import { fetchCurrentTeamMembers, fetchStripeProducts, fetchTeamPlan } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import { createStripeOrder } from 'api/orders';
import { fetchStripeCustomerPortal } from 'api/user-api';
import { currencyBasedOnUI, beeyCurrencies } from 'libs/dayjs-locale';
import { getBillingInfo } from 'api/billing-api';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import CreditValidity from './CreditValidity';
import PlansOverview from './PlansOverview';
import TeamPlan from './TeamPlan';
import styles from './style.module.less';
import { separateThousands, separateThousandsWithDecimals } from './TeamPlan/LastPayment';
import BillingInfoModal from '../BillingInfoModal';
const CreditInfo = ({ totalCredit, payAsYouGoCredit, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [products, setProducts] = useState('loading');
    const [priceInCurrency, setPriceInCurrency] = useState({
        price: 0,
        currency: clientEnv.getCurrency(currencyBasedOnUI()).toLocaleLowerCase(),
    });
    const [plan, setPlan] = useState('loading');
    const [billingInfo, setBillingInfo] = useState(null);
    const [billingModalVisible, setBillingModalVisible] = useState(false);
    const [minutesToBuy, setMinutesToBuy] = useState(60);
    const [form] = Form.useForm();
    const getStripeProducts = async (currency) => {
        const apiProducts = await fetchStripeProducts(session.connection);
        setProducts(apiProducts.list);
        const singleProduct = apiProducts.list.filter((product) => product.lookupKey === 'beey_start')[0];
        setPriceInCurrency({
            price: singleProduct.pricesForCurrencies[currency.toLocaleLowerCase()].priceWithTax,
            currency: currency.toUpperCase(),
        });
    };
    const getTeamPlan = async () => {
        const teamPlan = await fetchTeamPlan(session.connection);
        const teamMembers = await fetchCurrentTeamMembers(session.connection, 0, 0);
        const currentStorage = teamMembers.list.reduce((acc, member) => acc + member.storageSharedBytes, 0) / BYTES_IN_GIGABYTE;
        const totalStorage = Number(teamPlan.productMetadata.storageLimit);
        if (teamPlan.lookupKey === 'beey_start') {
            setPlan({
                planDetails: {
                    totalStorage,
                    currentStorage,
                },
                interval: 'year',
            });
        }
        else if (teamPlan.lookupKey === 'beey_plus_monthly'
            || teamPlan.lookupKey === 'beey_plus_yearly') {
            const extendedTeam = Object.assign(Object.assign({}, teamPlan), { totalNumberOfUsers: 5, currentNumberOfUsers: teamMembers.list.length, currentStorage,
                totalStorage });
            setPlan({
                planDetails: extendedTeam,
                interval: extendedTeam.interval,
            });
        }
        else if (teamPlan.lookupKey === 'beey_business_monthly'
            || teamPlan.lookupKey === 'beey_business_yearly') {
            const extendedTeam = Object.assign(Object.assign({}, teamPlan), { totalNumberOfUsers: 10, currentNumberOfUsers: teamMembers.list.length, currentStorage,
                totalStorage });
            setPlan({
                planDetails: extendedTeam,
                interval: extendedTeam.interval,
            });
        }
        else {
            setPlan({
                planDetails: Object.assign(Object.assign({}, teamPlan), { currentStorage, totalStorage: 0, totalNumberOfUsers: 0, currentNumberOfUsers: 0 }),
                interval: teamPlan.interval,
            });
        }
    };
    const isSubscribed = plan !== 'loading' && plan.planDetails.id !== undefined;
    const fetchBillingInfo = async () => {
        const userBillingInfo = await getBillingInfo(session.connection);
        setBillingInfo(userBillingInfo);
    };
    useEffect(() => {
        void getTeamPlan();
        void fetchBillingInfo();
    }, []);
    useEffect(() => {
        if (plan !== 'loading') {
            void getStripeProducts(plan.planDetails.currency !== undefined
                ? plan.planDetails.currency
                : priceInCurrency.currency);
        }
    }, [plan]);
    const getStripeCustomerPortal = async (landingPage, priceId) => {
        const planId = plan !== 'loading' && plan.planDetails.id !== undefined ? plan.planDetails.id : '';
        const itemId = (plan !== 'loading'
            && plan.planDetails.itemId !== undefined
            && priceId !== null) ? plan.planDetails.itemId : null;
        const portalData = await fetchStripeCustomerPortal(session.connection, window.location.href, landingPage, planId, itemId, priceId);
        window.open(portalData.Url, '_self');
    };
    const createOrder = async (lookupKey, quantity, currency) => {
        if (billingInfo === null) {
            setBillingModalVisible(true);
            return;
        }
        if (quantity !== null) {
            const result = await createStripeOrder(session.connection, lookupKey, quantity.toString(), currency);
            if (result.isFail()) {
                if (result.err() === 'invalid-user-data') {
                    setBillingModalVisible(true);
                }
                else {
                    void message.error(result.err());
                }
            }
            else {
                window.open(result.get().Url, '_self');
            }
        }
        else {
            void message.error(txt('enterMinutes'));
        }
    };
    const items = [
        {
            key: '1',
            label: txt('creditValidity'),
            children: _jsx(CreditValidity, {}),
        },
    ];
    const handleChangeCurrency = async (currency) => {
        await getStripeProducts(currency);
        clientEnv.setCurrency(currency);
    };
    return (_jsxs("div", { className: styles.page, children: [_jsxs(Flex, { vertical: true, gap: "large", className: styles.creditInfo, children: [_jsxs("div", { className: styles.teamCredit, children: [_jsxs(Flex, { className: styles.remainingCredit, children: [_jsxs(Space, { align: "start", className: styles.space, children: [_jsx("span", { className: styles.label, children: `${txt('remainingTeamCredit')}:` }), _jsx("strong", { className: styles.credit, children: totalCredit !== 'loading' ? `${separateThousands(totalCredit)} min` : _jsx(Spin, {}) })] }), _jsx("div", { className: styles.creditValidity, children: _jsx(Collapse, { items: items }) })] }), _jsxs(Flex, { justify: "space-between", children: [_jsxs("h2", { className: styles.title, children: [_jsx(ClockCircleOutlined, {}), ' ', txt('teamPlan')] }), _jsx(Button, { type: "primary", onClick: () => getStripeCustomerPortal(null, null), children: isSubscribed ? txt('managePlan') : txt('customerPortal') })] }), plan !== 'loading' ? (_jsx(Card, { title: !isSubscribed ? 'Start' : `${plan.planDetails.productName} ${plan.interval === 'year'
                                    ? txt('yearly').toLowerCase()
                                    : txt('monthly').toLowerCase()}`, extra: isSubscribed
                                    ? (_jsx(Space, { children: plan.planDetails.canceledAt === null ? (_jsxs(_Fragment, { children: [_jsx(CheckCircleFilled, { className: styles.iconActive }), _jsx("span", { children: txt('active') })] })) : (_jsxs(_Fragment, { children: [_jsx(StopFilled, { className: styles.iconCanceled }), _jsx("span", { children: txt('canceled') })] })) })) : null, className: styles.card, children: _jsx(TeamPlan, { currency: priceInCurrency.currency, onManagePlan: (landingPage) => getStripeCustomerPortal(landingPage, null), isSubscribed: isSubscribed, plan: plan.planDetails }) })) : _jsx(Spin, {})] }), _jsxs("div", { children: [!isSubscribed ? (_jsx(Flex, { justify: "flex-end", children: _jsxs(Space, { children: [_jsxs("span", { children: [txt('currency'), ":"] }), _jsx(Select, { className: styles.select, onChange: (currency) => handleChangeCurrency(currency), defaultValue: clientEnv.getCurrency(currencyBasedOnUI()), options: beeyCurrencies.map((currency) => ({ label: currency, value: currency })) })] }) })) : null, _jsxs("h2", { className: styles.title, children: [_jsx(ClockCircleOutlined, {}), ' ', txt('payAsYouGoCredit')] }), _jsx("p", { children: txt('creditValid') }), _jsxs(Space, { children: [_jsx("span", { children: `${txt('remainingPayAsYouGo')}:` }), _jsx("strong", { children: payAsYouGoCredit !== 'loading' ? `${separateThousands(payAsYouGoCredit)} min` : _jsx(Spin, {}) })] }), _jsx("h2", { className: `${styles.title} ${styles.ranOutCredit}`, children: txt('ranOutCredit') }), _jsxs(Space, { children: [_jsxs("span", { children: [txt('topUp'), ":"] }), _jsxs("strong", { children: [separateThousandsWithDecimals(priceInCurrency.price), ' ', priceInCurrency.currency, "/min"] })] }), _jsxs("div", { className: styles.gridContainer, children: [_jsxs("span", { children: [txt('numberOfMinutes'), ":"] }), _jsx(Form, { form: form, initialValues: {
                                            minutesToBuy: minutesToBuy !== null ? minutesToBuy : 60,
                                        }, children: _jsx(Form.Item, { name: "minutesToBuy", rules: [
                                                {
                                                    validator: async (_, value) => {
                                                        if (value < 60) {
                                                            return Promise.reject(txt('minimumCredit'));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ], children: _jsx(InputNumber, { className: styles.inputNumber, max: 99999, onChange: (value) => setMinutesToBuy(value) }) }) }), _jsxs("span", { children: [txt('priceInclVat'), ":"] }), _jsxs(Space, { children: [_jsx("strong", { children: minutesToBuy !== null
                                                    ? separateThousandsWithDecimals(minutesToBuy * priceInCurrency.price)
                                                    : 0 }), _jsx("strong", { children: priceInCurrency.currency })] })] }), _jsx("div", { className: styles.button, children: products !== 'loading' ? (_jsx(Button, { type: "primary", disabled: minutesToBuy !== null && minutesToBuy < 60, onClick: () => createOrder('beey_start', minutesToBuy, priceInCurrency.currency), children: txt('topUpCredit') })) : null })] })] }), _jsx(PlansOverview, { products: products, currency: priceInCurrency.currency, isSubscribed: isSubscribed, isCanceled: isSubscribed && plan.planDetails.canceledAt !== null, onGetStripeCustomerPortal: (landingPage, priceId) => getStripeCustomerPortal(landingPage, priceId), onSetPlanInterval: (interval) => {
                    if (plan !== 'loading') {
                        setPlan(Object.assign(Object.assign({}, plan), { interval }));
                    }
                }, onBillingModalVisible: () => setBillingModalVisible(true), billingInfo: billingInfo, plan: plan }), _jsx(BillingInfoModal, { isVisible: billingModalVisible, onClose: () => setBillingModalVisible(false), billingInfo: billingInfo, updateBillingInfo: () => fetchBillingInfo() })] }));
};
export default CreditInfo;
