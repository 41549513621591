import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Form, Select, Button } from 'antd';
import FormModal from 'components/FormModal';
import TagOption from 'components/TagOption';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import './style.less';
const SectionTagsModal = ({ tags, onCancel, onSelectTags, targetNode, }) => {
    const { session } = useSession();
    const { teamSettings: { sectionTags = [] } } = session;
    const [form] = Form.useForm();
    useEffect(() => {
        const selectedTags = sectionTags
            .map((tag) => tag.title)
            .filter((title) => tags.includes(title));
        form.setFieldsValue({ sectionTag: selectedTags });
    }, [tags]);
    const options = sectionTags.map((tag) => ({ value: tag.title }));
    const saveTags = () => {
        if (targetNode === null) {
            throw Error('cannot save section tags, target node is null.');
        }
        const newlySelectedTag = form.getFieldValue('sectionTag');
        onSelectTags(newlySelectedTag === undefined ? [] : [newlySelectedTag], targetNode);
    };
    return (_jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], title: txt('addTags'), open: true, onCancel: onCancel, footer: [
            _jsx(Button, { onClick: () => form.submit(), htmlType: "submit", type: "primary", children: txt('save') }, "submit"),
        ], children: _jsx(Form, { className: "section-tags-modal", form: form, onFinish: saveTags, children: _jsx(Form.Item, { name: "sectionTag", children: _jsx(Select, { className: "section-tags__select", popupClassName: "section-tags__dropdown", autoFocus: true, allowClear: true, mode: "multiple", 
                    // NOTE: It is not used at the moment, but can be used in the future if needed.
                    // The select would have to have mode="multiple" to allow the tags to be selected.
                    tagRender: TagOption, options: options }) }) }) }));
};
export default SectionTagsModal;
