import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
import { localeTxt, sortedLocales } from 'libs/i18n';
import { BeeyLocale } from 'libs/locales/locale';
import { normalizeString } from 'libs/normalize-string';
import './style.less';
export const languageFilter = (selectInput, option) => {
    const optionLowerCase = option === null || option === void 0 ? void 0 : option.children.props.children.toLocaleLowerCase();
    const normalizedOption = normalizeString(optionLowerCase);
    return normalizedOption.startsWith(selectInput.toLocaleLowerCase());
};
const LanguageSelect = ({ id, className, locales, value, onChange, }) => {
    var _a;
    const handleChange = (code) => {
        // NOTE: We can safely create TLocale from a code here as all locale codes
        // in this component come from valid TLocale instances
        onChange === null || onChange === void 0 ? void 0 : onChange(BeeyLocale.fromCode(code));
    };
    const options = sortedLocales(locales);
    return (_jsx(Select, { id: id, className: className, showSearch: true, value: (_a = value === null || value === void 0 ? void 0 : value.code) !== null && _a !== void 0 ? _a : undefined, onChange: handleChange, optionFilterProp: "children", filterOption: languageFilter, children: options.map((option) => (_jsx(Select.Option, { value: option.code, children: _jsx("span", { children: localeTxt(option) }) }, option.code))) }));
};
export default LanguageSelect;
