import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from 'react';
import * as ProjectAPI from 'api/project-api';
import * as TeamAPI from 'api/team-api';
import { deriveTagColor, deriveTagStyle } from 'libs/tag-style';
import { useSession } from 'components/Authenticated';
import { Spin, App, Select, Flex, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import YetAnotherTagComponent from 'components/YetAnotherTagComponent';
import { normalizeString } from 'libs/normalize-string';
import ProjectTag from './ProjectTag';
import styles from './style.module.less';
const TagsPanel = ({ project, enqueueProjectUpdate, onTagsChange, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [loadingTags, setLoadingTags] = useState([]);
    const [teamTags, setTeamTags] = useState('loading');
    const [selectValue, setSelectValue] = useState('');
    const fetchTags = async () => {
        const tags = await TeamAPI.fetchCurrentTeamTags(session.connection);
        setTeamTags(tags);
    };
    useEffect(() => {
        void fetchTags();
    }, [project]);
    const changeTag = async (tag, add) => {
        setLoadingTags([...loadingTags, tag]);
        await enqueueProjectUpdate(async (currentProject) => {
            try {
                const serverProject = add ? await ProjectAPI.addTagToProject(session.connection, currentProject, tag) : await ProjectAPI.deleteTagFromProject(session.connection, currentProject, tag);
                return serverProject;
            }
            catch (error) {
                void message.error(txt('failedToSaveTags'));
                global.logger.error('failed to save tags for unhandled reason', {}, error);
                throw error;
            }
        });
        setLoadingTags((previousLoadingTags) => previousLoadingTags.filter((loadingTag) => loadingTag !== tag));
    };
    const pinnedTags = teamTags !== 'loading'
        ? teamTags.list.filter((t) => t.isPinned || project.tags.includes(t.key))
        : [];
    const updateProjectTags = async () => {
        await fetchTags();
        if (onTagsChange) {
            onTagsChange();
        }
    };
    const handleSelect = async (value) => {
        setSelectValue(value);
        if (teamTags === 'loading')
            return;
        const updatedTags = [...new Set([...project.tags, value])];
        await enqueueProjectUpdate(async () => {
            try {
                const serverProject = await ProjectAPI.updateTags(session.connection, project, updatedTags);
                return serverProject;
            }
            catch (error) {
                void message.error(txt('failedToSaveTags'));
                global.logger.error('failed to save tags for unhandled reason', {}, error);
                throw error;
            }
        });
    };
    const options = teamTags !== 'loading' ? teamTags.list
        .filter((t) => !project.tags.includes(t.key))
        .map((t) => ({ label: t.key, value: t.key })) : [];
    const filterOption = (inputValue, selectOption) => {
        if (selectOption === undefined)
            return false;
        const normalizedOption = normalizeString(selectOption.value.toLocaleLowerCase());
        if (inputValue.includes(' ')) {
            return normalizedOption.includes(normalizeString(inputValue));
        }
        return normalizedOption.split(' ').some((option) => option.startsWith(normalizeString(inputValue)));
    };
    return (teamTags !== 'loading' ? (_jsxs(Flex, { vertical: true, children: [_jsxs(Flex, { wrap: "wrap", children: [pinnedTags.map((tag) => (_jsx(ProjectTag, { tag: tag.key, checked: project.tags.includes(tag.key), onTagChange: changeTag, disabled: project.isReadOnly, style: project.tags.includes(tag.key) ? deriveTagStyle(tag.key, tag.color) : {}, loadingTags: loadingTags }, tag.key))), project.tags.length > 0 && project.tags.map((tag) => {
                        const teamTag = teamTags.list.find((tt) => tt.key === tag);
                        if (teamTag === undefined) {
                            const foreignTag = {
                                title: tag,
                                color: deriveTagColor(tag),
                                abbreviation: tag.slice(0, 2),
                                isForeign: true,
                                showFullVersion: true,
                            };
                            return (_jsx(YetAnotherTagComponent, { tag: foreignTag, allTags: teamTags.list, onTagDelete: () => changeTag(tag, false), onTagSave: updateProjectTags }, tag));
                        }
                        return null;
                    })] }), _jsx(Select, { className: styles.tagsSelect, value: selectValue, onBlur: () => setSelectValue(''), filterOption: filterOption, onChange: handleSelect, showSearch: true, options: options })] })) : _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) }));
};
export default TagsPanel;
