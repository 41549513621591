import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDashboard } from 'screens/DashboardScreen/dashboard-context';
import Brand from '../Brand';
import DashMenuButton from '../DashMenuButton';
import './style.less';
const DashMenuHead = () => {
    const { menuCollapsed, toggleMenuCollapse } = useDashboard();
    return (_jsxs("div", { className: "dashmenu__head", children: [_jsx("div", { className: "dashmenu__open-btn", children: _jsx(DashMenuButton, { onClick: toggleMenuCollapse }) }), menuCollapsed ? null : (_jsx("div", { className: "dashmenu__brand", children: _jsx(Brand, {}) }))] }));
};
export default DashMenuHead;
