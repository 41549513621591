import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { App, Form, Input, Radio, } from 'antd';
import { addSpeaker, deleteSpeaker } from 'api/speaker-api';
import { Speaker } from 'api/speaker';
import { useSession } from 'components/Authenticated';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { txt } from 'libs/i18n';
import ModalFooter from 'components/ModalFooter';
import SpeakerRole from '../../../../SpeakersPage/SpeakerRole';
import styles from './style.module.less';
import SpeakerColorSelect from '../../SpeakerColorSelect';
const SpeakerSelectForm = ({ onSubmit, diarizationCode = null, language, isCaptionMode, defaultSpeakerColor, editedSpeaker, isDatabaseSpeaker, initialValues, onClose, direction, }) => {
    const { session, updateUserSettings } = useSession();
    const { settings: { speakerUpdateOptions } } = session.login.user;
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const { tutorialPrepareNext } = useBeeyTutorial();
    const handleSubmit = async (formValues) => {
        var _a;
        const { firstName, lastName, role, speakerColor, saveNewSpeaker, updateDbSpeaker, } = formValues;
        let speaker = new Speaker(firstName.trim(), lastName.trim(), role.trim(), null, null, diarizationCode, false, (_a = language === null || language === void 0 ? void 0 : language.code) !== null && _a !== void 0 ? _a : null, new Map(), speakerColor === 'default' ? defaultSpeakerColor : speakerColor, speakerColor === 'default');
        updateUserSettings({
            speakerUpdateOptions: {
                saveNewSpeaker: saveNewSpeaker !== null && saveNewSpeaker !== void 0 ? saveNewSpeaker : speakerUpdateOptions.saveNewSpeaker,
                updateDbSpeaker: updateDbSpeaker !== null && updateDbSpeaker !== void 0 ? updateDbSpeaker : speakerUpdateOptions.updateDbSpeaker,
            },
        });
        if (saveNewSpeaker !== 'useInProjectOnly' && updateDbSpeaker !== 'useInProjectOnly') {
            try {
                const speakerXml = await addSpeaker(session.connection, speaker.exportXml());
                if (updateDbSpeaker === 'updateInDatabase' && editedSpeaker && editedSpeaker.dbid !== null) {
                    void deleteSpeaker(session.connection, editedSpeaker.dbid);
                }
                speaker = Speaker.fromXMLString(speakerXml);
                void message.success(txt('speakerSaved'), 2);
            }
            catch (error) {
                global.logger.error('Failed to save speaker to the database', {}, error);
                void message.error(txt('failedToSaveSpeaker'), 2);
            }
        }
        onSubmit(speaker);
        tutorialPrepareNext();
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.nativeEvent.stopPropagation();
            event.stopPropagation();
        }
    };
    return (_jsx(Form, { form: form, className: styles.speakerForm, onFinish: handleSubmit, initialValues: Object.assign(Object.assign({}, initialValues), { speakerColor: 'default', saveNewSpeaker: speakerUpdateOptions.saveNewSpeaker, updateDbSpeaker: speakerUpdateOptions.updateDbSpeaker }), onKeyDown: handleKeyDown, labelCol: { span: 8 }, labelAlign: "left", labelWrap: true, children: _jsxs("div", { className: "ant-modal-body", children: [_jsx(Form.Item, { name: "firstName", label: txt('firstName'), children: _jsx(Input, { type: "text", autoFocus: true, className: direction }) }), _jsx(Form.Item, { name: "lastName", label: txt('lastName'), children: _jsx(Input, { type: "text", className: direction }) }), _jsx(SpeakerRole, { direction: direction }), isCaptionMode && (_jsx(Form.Item, { name: "speakerColor", label: txt('speakerColor'), children: _jsx(SpeakerColorSelect, { selectClassName: "speaker-color-select", popupClassName: "speaker-color-select__dropdown", defaultColor: defaultSpeakerColor, direction: direction }) })), _jsx(Form.Item, { name: isDatabaseSpeaker ? 'updateDbSpeaker' : 'saveNewSpeaker', children: _jsx(Radio.Group, { children: isDatabaseSpeaker ? (_jsxs(_Fragment, { children: [_jsx(Radio, { value: "updateInDatabase", children: txt('updateSpeakerInDatabase') }), _jsx(Radio, { value: "saveAsNew", children: txt('saveAsNewSpeaker') }), _jsx(Radio, { value: "useInProjectOnly", children: txt('editOnlyInProject') })] })) : (_jsxs(_Fragment, { children: [_jsx(Radio, { value: "useInProjectOnly", children: txt('useOnlyInProject') }), _jsx(Radio, { value: "addToDatabase", children: txt('saveSpeaker') })] })) }) }), _jsx("div", { className: styles.controls, children: _jsx(Form.Item, { dependencies: ['firstName', 'lastName', 'role'], name: "submit", rules: [
                            ({ getFieldValue }) => ({
                                validator() {
                                    const isAllFieldsEmpty = getFieldValue('lastName').trim() === '' && getFieldValue('firstName').trim() === '' && getFieldValue('role').trim() === '';
                                    if (isAllFieldsEmpty) {
                                        return Promise.reject(txt('addAtLeastOne'));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ], children: _jsx(ModalFooter, { onCancel: onClose, children: txt('replace') }) }) })] }) }));
};
export default SpeakerSelectForm;
