import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json } from '@newtontechnologies/beey-api-js-client/receivers';
export const getAllAbbreviations = async (connection) => connection.authFetch()
    .url(apiV2.currentUser.abbreviations.url({ skip: 0, count: 2147483647 }))
    .receive(json()
    .map((data) => data.list)).fetch();
export const addAbbreviation = async (connection, params) => {
    const body = {
        abbreviation: params.abbreviation.trim(),
        text: params.text.trim(),
    };
    return connection.authFetch()
        .url(apiV2.currentUser.abbreviations.url())
        .postJson(body)
        .receive(json())
        .send();
};
export const editAbbreviation = async (connection, params) => {
    const body = {
        abbreviationId: params.id,
        abbreviation: params.abbreviation.trim(),
        text: params.text.trim(),
    };
    return connection.authFetch()
        .url(apiV2.currentUser.abbreviations.url())
        .putJson(body)
        .receive(json())
        .send();
};
export const deleteAbbreviation = async (connection, abbreviationId) => {
    const body = {
        abbreviationId,
    };
    return connection.authFetch()
        .url(apiV2.currentUser.abbreviations.url())
        .deleteJson(body)
        .send();
};
export const importCsv = async (connection, batch) => (connection.authFetch()
    .url(apiV2.currentUser.abbreviations.batch.url())
    .postJson({ abbreviations: batch })
    .receive(json())
    .send());
