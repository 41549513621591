/* eslint-disable no-param-reassign */
export const INDEX = 0;
export const VALUE = 1;
export const BEGIN = 1;
export const END = 2;
const METADATA_NAMES = [
    'timeAnchor',
    'captionEnd',
    'captionLineBreak',
    'captionFormat',
    'lineFormatCache',
    'speakerSign',
    'lastSelection',
];
// Metadata that are saved as a phrase in trsx must never be inside another phrase,
// therefore we make sure that they are. Metadata that are not saved to trsx
// generally don't need to enforce phrase end.
const METADATA_ENFORCING_PHRASE_END = [
    'timeAnchor',
    'captionEnd',
    'captionLineBreak',
    'speakerSign',
];
const UNDELETABLE_METADATA = [
    'lastSelection',
];
export default class TextMetadata {
    constructor(timestamps = [], length = 0) {
        // add new timestamp entry at the end with known index
        this.addTimestampAtEndOnIndex = (index, begin, end) => {
            this.timestamps.push([index, begin, end]);
            this.length = index + 2;
        };
        /*
          Returns timestamps corresponding to the given index in text.
          Returns object containing:
            begin and end of the phrase that contains the given index
            from and to indices of the start of the phrase (inclusive) and end of the phrase
            (exclusive).
        */
        this.getTimestampsAtIndex = (index) => {
            if (this.timestamps.length === 0) {
                return {
                    from: 0,
                    to: Infinity,
                    begin: 0,
                    end: Infinity,
                };
            }
            if (index < 0) {
                throw Error(`invalid argument: index<0: ${index}`);
            }
            let from = 0;
            const initialIndex = this.findLastBeforeIndex(index, this.timestamps);
            for (let i = initialIndex; i < this.timestamps.length; i += 1) {
                if (this.timestamps[i][INDEX] >= index) {
                    return {
                        begin: this.timestamps[i][BEGIN],
                        end: this.timestamps[i][END],
                        from,
                        to: this.timestamps[i][INDEX] + 1,
                    };
                }
                from = this.timestamps[i][INDEX] + 1;
            }
            // requesting timestamp at an index that is not covered by timestamps
            // yields a special timestamp beginning and ending at infinity.
            return {
                begin: Infinity,
                end: Infinity,
                from,
                to: Infinity,
            };
        };
        this.getBeginAtIndex = (index) => this.getTimestampsAtIndex(index).begin;
        this.getEndAtIndex = (index) => this.getTimestampsAtIndex(index).end;
        /*
          Returns last timestamp starting before time.
          Returns object containing:
            begin and end of the phrase that contains the given index
            from and to indices of the start of the phrase (inclusive) and end of the phrase
            (exclusive).
        */
        this.getTimestampsAtTime = (time) => {
            if (this.timestamps.length === 0) {
                return {
                    from: 0,
                    to: 0,
                    begin: 0,
                    end: 0,
                };
            }
            let from = 0;
            for (let i = 0; i < this.timestamps.length; i += 1) {
                if (this.timestamps[i][BEGIN] > time) { // found that relevant timestamp was at (i - 1)
                    if (i === 0) {
                        return {
                            from: 0,
                            to: 0,
                            begin: 0,
                            end: 0,
                        };
                    }
                    return {
                        from,
                        to: this.timestamps[i - 1][INDEX] + 1,
                        begin: this.timestamps[i - 1][BEGIN],
                        end: this.timestamps[i - 1][END],
                    };
                }
                from = i === 0 ? 0 : this.timestamps[i - 1][INDEX] + 1;
            }
            return {
                begin: this.timestamps[this.timestamps.length - 1][END],
                end: Infinity,
                from,
                to: this.timestamps[this.timestamps.length - 1][INDEX] + 1,
            };
        };
        /*
          entries contains entries describing the timestamps for the document.
          each entry consists of a list containing
          [index, begin, end]. It describes timestamps for the
          word ending at index (inclusive) in the document. The beginning of the
          word is the index of the previous entry plus one.
          This means that the timestamp is bound to the last letter of the phrase,
          usually space or punctuation, which is convenient.
        */
        this.timestamps = timestamps;
        this.length = length;
        const otherMetadata = {};
        METADATA_NAMES.forEach((name) => {
            otherMetadata[name] = [];
        });
        // NOTE: We are using "as" here because all entries of otherMetadata were filled in
        // and so it is no longer partial.
        this.otherMetadata = otherMetadata;
    }
    static fromDump(dump) {
        const output = new TextMetadata(dump.timestamps, dump.length);
        output.otherMetadata = Object.assign(Object.assign({}, output.otherMetadata), dump.otherMetadata);
        return output;
    }
    // inserts or replaces metadata with the given name on the given index
    addMetadata(name, index, value) {
        let relevantArray = this.otherMetadata[name];
        if (!Object.keys(this.otherMetadata).includes(name)) {
            this.otherMetadata[name] = [];
            relevantArray = this.otherMetadata[name];
        }
        const initialIndex = this.findLastBeforeIndex(index, relevantArray);
        for (let i = initialIndex; i < relevantArray.length; i += 1) {
            const searchedIndex = relevantArray[i][INDEX];
            if (searchedIndex < index)
                continue;
            else if (searchedIndex === index) {
                relevantArray[i][VALUE] = value;
                if (METADATA_ENFORCING_PHRASE_END.includes(name)) {
                    this.enforcePhraseEnd(index);
                }
                return;
            }
            else {
                relevantArray.splice(i, 0, [index, value]);
                if (METADATA_ENFORCING_PHRASE_END.includes(name)) {
                    this.enforcePhraseEnd(index);
                }
                return;
            }
        }
        relevantArray.push([index, value]);
        if (METADATA_ENFORCING_PHRASE_END.includes(name)) {
            this.enforcePhraseEnd(index);
        }
    }
    getMetadataBeforeIndex(name, index) {
        const relevantArray = this.otherMetadata[name];
        if (!Object.keys(this.otherMetadata).includes(name)) {
            global.logger.error(`cannot get metadata ${name}`);
            return { index: null, value: null };
        }
        let lastValue = null;
        let lastIndex = null;
        const initialIndex = this.findLastBeforeIndex(index, relevantArray);
        for (let i = initialIndex; i < relevantArray.length; i += 1) {
            const [currentIndex, currentValue] = relevantArray[i];
            if (currentIndex >= index)
                return { index: lastIndex, value: lastValue };
            lastValue = currentValue;
            lastIndex = currentIndex;
        }
        return { index: lastIndex, value: lastValue };
    }
    getMetadataAfterIndex(name, index) {
        const relevantArray = this.otherMetadata[name];
        if (!Object.keys(this.otherMetadata).includes(name)) {
            global.logger.error(`cannot get metadata ${name}`);
            return { index: null, value: null };
        }
        const initialIndex = this.findLastBeforeIndex(index, relevantArray);
        for (let i = initialIndex; i < relevantArray.length; i += 1) {
            const [currentIndex, currentValue] = relevantArray[i];
            if (currentIndex >= index)
                return { index: currentIndex, value: currentValue };
        }
        return { index: null, value: null };
    }
    spliceMetadata(name, from, to, newValues) {
        const relevantArray = this.otherMetadata[name];
        if (!Object.keys(this.otherMetadata).includes(name)) {
            global.logger.error(`cannot get metadata ${name}`);
            return;
        }
        this.spliceMetadataArray(relevantArray, from, to, newValues);
        this.validateMetadata(name, from, to);
    }
    // timestamps corresponding to the text range [from, to] will
    // be removed and the supplied timestamps will be placed instead of them
    // validity of the inserted entries is enforced
    // returns [ wasCleanSpliceFromLeft, wasCleanSpliceFromRight] indicating whether
    // the first or last timestamp was unchanged by the splice. If the timestamp was
    // changed, the splice is considered not clean on that side.
    spliceTimestamps(from, to, newTimestamps) {
        const { updatedFrom, updatedTo, replacedValues } = this.spliceMetadataArray(this.timestamps, from, to, newTimestamps);
        this.validateTimestamps(updatedFrom, updatedTo);
        this.validateAllMetadata(from, to);
        if (newTimestamps.length === 0 && replacedValues.length === 0) {
            return [true, true];
        }
        if (newTimestamps.length === 0 || replacedValues.length === 0) {
            return [false, false];
        }
        return [
            this.doesFirstMatch(newTimestamps, replacedValues),
            this.doesLastMatch(newTimestamps, replacedValues),
        ];
    }
    // handle text change - timestamps need to be changed accordingly
    // to keep them synchronized with the text in the underlying editor.
    // see delta format description:
    // https://quilljs.com/docs/delta/
    applyDelta(delta) {
        const ops = [...delta.ops];
        let activeIndex = 0;
        let insertTextLength = 0;
        let deleteTextLength = 0;
        // consume the delta in chunks. Every chunk consists of
        // a sequence of retains, a sequence of inserts and a sequence of deletes.
        let op = ops.shift();
        while (op) {
            insertTextLength = 0;
            deleteTextLength = 0;
            while (op && op.retain !== undefined) {
                activeIndex += op.retain;
                op = ops.shift();
            }
            while (op && op.insert !== undefined) {
                if (typeof op.insert === 'string') {
                    insertTextLength += op.insert.length;
                }
                else {
                    global.logger.warn('ignoring operation', { op });
                }
                op = ops.shift();
            }
            while (op && op.delete !== undefined) {
                deleteTextLength += op.delete;
                op = ops.shift();
            }
            if (deleteTextLength > 0) {
                this.applyDeletion(activeIndex, deleteTextLength);
            }
            if (insertTextLength > 0) {
                this.applyInsertion(activeIndex, insertTextLength);
                activeIndex += insertTextLength;
            }
        }
    }
    getMetadataInRange(fromIndex, toIndex, name) {
        const entries = this.otherMetadata[name];
        const range = [];
        const initialIndex = this.findLastBeforeIndex(fromIndex, entries);
        for (let i = initialIndex; i < entries.length; i += 1) {
            const textIndex = entries[i][INDEX];
            if (textIndex < fromIndex)
                continue;
            else if (textIndex >= toIndex)
                break;
            else
                range.push([...entries[i]]);
        }
        return range;
    }
    /*
      takes the *text* and splits it to phrases according to timestamps. The text is assumed to
      begin at the index *offset*.
      returns: list containing phrases, where a phrase is an object
      {text, begin, end}.
    */
    splitTextToPhrases(text, offset) {
        this.enforcePhraseEnd(offset + text.length - 1); // newline
        const phrases = [];
        let end = null;
        const initialIndex = this.findLastBeforeIndex(offset, this.timestamps);
        for (let i = initialIndex; i < this.timestamps.length; i += 1) {
            const timestampIndex = this.timestamps[i][INDEX];
            if (timestampIndex < offset)
                continue;
            if (timestampIndex >= offset + text.length)
                break;
            const begin = this.timestamps[i][BEGIN];
            end = this.timestamps[i][END];
            let previousIndex = (i !== 0 ? this.timestamps[i - 1][INDEX] : -1);
            if (previousIndex < offset - 1) {
                previousIndex = offset - 1;
            }
            const phraseFrom = previousIndex + 1; // +1 because the timestamp is at the last letter
            const phraseTo = timestampIndex + 1;
            const phraseText = text.substring(phraseFrom - offset, phraseTo - offset).replace('\n', '');
            const metadata = this.getAllMetadataAtIndex(timestampIndex);
            if (phraseText !== '' || Object.keys(metadata).length > 0) {
                phrases.push({
                    text: phraseText,
                    begin,
                    end,
                    metadata,
                });
            }
        }
        return phrases;
    }
    dump() {
        return {
            timestamps: this.timestamps,
            otherMetadata: this.otherMetadata,
            length: this.length,
        };
    }
    // MS Interim function
    checkTimestampsValidity() {
        let lastIndex = 0;
        let lastEnd = 0;
        let isProblem = false;
        for (let i = 0; i < this.timestamps.length; i += 1) {
            // eslint-disable-next-line no-unused-vars
            const [index, begin, end] = this.timestamps[i];
            if (Number.isNaN(begin) || Number.isNaN(end)) {
                global.logger.error('Invalid timestamps: timestamp is NaN', { index, begin, end });
                isProblem = true;
            }
            if (Number.isNaN(begin)) {
                global.logger.error('Invalid timestamps: timestamp is NaN', { index1: lastIndex, index2: this.timestamps[i][0] });
                isProblem = true;
            }
            if (index < lastIndex) {
                global.logger.error('Invalid timestamps: indices not increasing {index1}, {index2}', { index1: lastIndex, index2: this.timestamps[i][0] });
                isProblem = true;
            }
            if (begin < lastEnd) {
                global.logger.error('begin before last end', { end1: lastEnd, begin2: this.timestamps[i][1] });
                isProblem = true;
            }
            lastEnd = end;
            lastIndex = index;
        }
        return isProblem;
    }
    // MS end of interim
    getMetadataAtIndex(index, name) {
        const entries = this.otherMetadata[name];
        if (!Object.keys(this.otherMetadata).includes(name)) {
            return null;
        }
        const initialIndex = this.findLastBeforeIndex(index, entries);
        for (let i = initialIndex; i < entries.length; i += 1) {
            const textIndex = entries[i][INDEX];
            if (textIndex < index)
                continue;
            else if (textIndex > index)
                break;
            else
                return entries[i][VALUE];
        }
        return null;
    }
    getLength() {
        return this.length;
    }
    enforcePhraseEnd(index) {
        const phraseTimestamp = this.getTimestampsAtIndex(index);
        if (phraseTimestamp.to - 1 === index) {
            return; // everything OK, there is phrase end at the required index
        }
        if (phraseTimestamp.to === Infinity) { // missing timestamp at end
            const lastEndGlobally = this.timestamps.length > 0
                ? this.timestamps[this.timestamps.length - 1][END]
                : 0;
            this.timestamps.push([index, lastEndGlobally, lastEndGlobally]);
            return;
        }
        const newTimestamps = [
            [index, phraseTimestamp.begin, phraseTimestamp.begin],
            [phraseTimestamp.to - 1, phraseTimestamp.begin, phraseTimestamp.end],
        ];
        this.spliceTimestamps(phraseTimestamp.from, phraseTimestamp.to, newTimestamps);
    }
    spliceMetadataArray(array, from, to, newValues) {
        let deleteStart = null;
        let deleteCount = 0;
        const initialIndex = this.findLastBeforeIndex(from, array);
        for (let i = initialIndex; i < array.length; i += 1) {
            if (array[i][INDEX] < from)
                continue;
            if (deleteStart === null)
                deleteStart = i;
            if (array[i][INDEX] >= to)
                break;
            deleteCount += 1;
        }
        if (deleteStart === null)
            deleteStart = array.length;
        const replacedValues = array.splice(deleteStart, deleteCount, ...newValues);
        return {
            updatedFrom: deleteStart,
            updatedTo: deleteStart + newValues.length,
            replacedValues,
        };
    }
    /*
    returns the position of the last entry in array that has value in specified column
    lower than searchedTextIndex.
    */
    findLastBeforeIndex(searchedTextIndex, array, column = INDEX) {
        if (array.length <= 1)
            return 0;
        let lowerBound = 0;
        let upperBound = array.length;
        while (lowerBound < upperBound - 1) {
            const middle = Math.floor((upperBound + lowerBound) / 2);
            if (array[middle][column] < searchedTextIndex) {
                lowerBound = middle;
            }
            else {
                upperBound = middle;
            }
        }
        return lowerBound;
    }
    // remove invalid entries (mainly repeating timestamps) from this.timestamps.
    // From start-th entry inclusive to end-th entry exclusive. If there are
    // conflicting or duplicate timestamps at i and i+1, the timestamp at i is removed.
    validateTimestamps(startIndex, endIndex) {
        let followingIndex = Infinity;
        let followingBegin = Infinity;
        // search backwards and delete conflicting entries
        for (let i = Math.min(endIndex, this.timestamps.length) - 1; i >= Math.max(0, startIndex); i -= 1) {
            const index = this.timestamps[i][INDEX];
            const begin = this.timestamps[i][BEGIN];
            const end = this.timestamps[i][END];
            if (index >= followingIndex || end > followingBegin) {
                this.timestamps.splice(i, 1);
            }
            else {
                followingBegin = begin;
                followingIndex = index;
            }
        }
    }
    validateAllMetadata(fromIndex, toIndex) {
        METADATA_NAMES.forEach((name) => {
            this.validateMetadata(name, fromIndex, toIndex);
        });
    }
    /*
      All metadata must be at a phrase end - exactly at a timestamp index.
      Function validateMetadata enforces that by creating timestamps where necesary.
    */
    validateMetadata(name, startIndex, endIndex) {
        if (METADATA_ENFORCING_PHRASE_END.includes(name)) {
            this.getMetadataInRange(startIndex, endIndex, name)
                .map((entry) => entry[INDEX])
                .forEach((index) => this.enforcePhraseEnd(index));
        }
    }
    updateDataDeletion(array, fromIndex, deleteTextLength, isKeepAfterDeletion) {
        let deleteCount = 0;
        let deleteStart = null;
        const initialIndex = this.findLastBeforeIndex(fromIndex, array);
        for (let i = initialIndex; i < array.length; i += 1) {
            if (array[i][INDEX] < fromIndex) {
                continue;
            }
            else if (array[i][INDEX] < fromIndex + deleteTextLength) {
                if (deleteStart === null) {
                    deleteStart = i;
                }
                deleteCount += 1;
            }
            else {
                array[i][INDEX] -= deleteTextLength;
            }
        }
        if (deleteStart === null)
            return;
        if (isKeepAfterDeletion) {
            for (let i = deleteStart; i < deleteStart + deleteCount; i += 1) {
                array[i][INDEX] = fromIndex;
            }
        }
        else {
            array.splice(deleteStart, deleteCount);
        }
    }
    // handle insert operation (part of delta)
    applyInsertion(fromIndex, insertTextLength) {
        this.updateDataInsertion(this.timestamps, fromIndex, insertTextLength);
        Object.values(this.otherMetadata).forEach((value) => {
            this.updateDataInsertion(value, fromIndex, insertTextLength);
        });
        this.length += insertTextLength;
    }
    updateDataInsertion(array, fromIndex, insertTextLength) {
        const initialIndex = this.findLastBeforeIndex(fromIndex, array);
        for (let i = initialIndex; i < array.length; i += 1) {
            if (array[i][INDEX] >= fromIndex) {
                array[i][INDEX] += insertTextLength;
            }
        }
    }
    // handle delete operation (part of a delta) - delete timestamps
    // in the deleted range. Move indices after the deleted range.
    applyDeletion(fromIndex, deleteTextLength) {
        this.updateDataDeletion(this.timestamps, fromIndex, deleteTextLength, false);
        Object.entries(this.otherMetadata).forEach(([name, array]) => {
            this.updateDataDeletion(array, fromIndex, deleteTextLength, UNDELETABLE_METADATA.includes(name));
        });
        this.length -= deleteTextLength;
    }
    getAllMetadataAtIndex(index) {
        const relevantMetadata = {};
        METADATA_NAMES.forEach((name) => {
            const metadataValue = this.getMetadataAtIndex(index, name);
            if (metadataValue !== null) {
                relevantMetadata[name] = metadataValue;
            }
        });
        return relevantMetadata;
    }
    doesFirstMatch(array1, array2) {
        for (let i = 0; i < array1[0].length; i += 1) {
            if (array1[0][i] !== array2[0][i]) {
                return false;
            }
        }
        return true;
    }
    doesLastMatch(array1, array2) {
        for (let i = 0; i < array1[0].length; i += 1) {
            if (array1[array1.length - 1][i] !== array2[array2.length - 1][i]) {
                return false;
            }
        }
        return true;
    }
}
