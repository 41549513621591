import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Badge, Popover, Tooltip, Spin, } from 'antd';
import { LoadingOutlined, ShareAltOutlined } from '@ant-design/icons';
import { listProjectSharing } from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import './style.less';
const SharedBadge = ({ project, type = 'standalone' }) => {
    const [sharedWith, setSharedWith] = useState(null);
    const { session } = useSession();
    const handlePopoverOpenChange = async (visible) => {
        if (visible === false) {
            return;
        }
        setSharedWith(await listProjectSharing(session.connection, project.id, session.login.user.email));
    };
    return (_jsx(Popover, { placement: "rightTop", title: (_jsxs(_Fragment, { children: [_jsx(ShareAltOutlined, {}), "\u00A0 \u00A0", txt('sharedWith')] })), onOpenChange: handlePopoverOpenChange, content: sharedWith === null
            ? _jsx(Spin, { indicator: _jsx(LoadingOutlined, {}) })
            : (_jsx(_Fragment, { children: sharedWith.map((email) => _jsx("div", { children: email }, email)) })), trigger: "click", children: _jsx(Tooltip, { title: txt('show'), placement: "top", children: _jsx(Badge, { className: `badge-clicable badge-${type}`, title: " ", count: (project.shareCount - 1), size: type === 'standalone' ? 'default' : 'small', offset: type === 'standalone' ? undefined : [-8, -2] }) }) }));
};
export default SharedBadge;
