import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { App, Button, Card, Popconfirm, Tooltip, } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import AddButton from 'components/AddButton';
import * as Announcements from 'api/announcements';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import { useSession } from 'components/Authenticated';
import dayjs from 'dayjs';
import { renderMarkdown } from 'libs/markdown';
import { txt } from 'libs/i18n';
import NewAnnouncementModal from './NewAnnouncementModal';
import './style.less';
const formatDate = (date) => dayjs(date).format('DD. MM. YYYY HH:mm');
const LOADING = 'loading';
const ANNOUNC_TABLE_ID = 'announc-table'; // Must be unique across all tables in Beey!!
const AnnouncementSubpage = () => {
    const { session } = useSession();
    const { screenSize } = useAppContext();
    const { message } = App.useApp();
    const [announModalVisible, setAnnounModalVisible] = useState(false);
    const [announcementList, setAnnouncementList] = useState('loading');
    const tableView = useTableView(ANNOUNC_TABLE_ID);
    const fetchAnnouncementList = async () => {
        const { currentPage, pageSize } = tableView.getViewParams();
        try {
            const json = await Announcements.listOfAnnouncements(session.connection, '', '', {
                skip: pageSize * (currentPage - 1),
                count: pageSize,
            });
            const newJson = json.list.map((item) => ({
                key: item.id,
                dateFrom: `${formatDate(item.from)}`,
                dateTo: `${formatDate(item.to)}`,
                localizedAnnoun: item.localizations,
                id: item.id,
            }));
            setAnnouncementList({ announcements: newJson, totalCount: json.totalCount });
        }
        catch (error) {
            global.logger.error('Fetching list of announcements failed', {}, error);
        }
        setAnnounModalVisible(false);
    };
    const handleDelete = async (key) => {
        const { currentPage } = tableView.getViewParams();
        try {
            await Announcements.deleteAnnouncement(session.connection, key);
            if (currentPage !== 1
                && announcementList !== 'loading'
                && announcementList.announcements.length === 1) {
                tableView.setCurrentPage(currentPage - 1);
            }
            void message.success(txt('successfullyDeleted'));
        }
        catch (error) {
            global.logger.error('Deleting announcements failed', {}, error);
            void message.error(txt('failedToDelete'));
        }
        void fetchAnnouncementList();
    };
    const columns = [
        {
            title: _jsx("span", { className: "announcement-title", children: txt('announcement') }),
            width: screenSize.width < screenBreakpoints.md ? '50%' : '70%',
            render: (_, record) => {
                // saving localization Object as array of two element arrays:
                // item[0] is announcement language, item[1] is text of announcement
                const localizedAnnounList = Object.entries(record.localizedAnnoun);
                return localizedAnnounList.map((item) => (_jsxs("div", { className: "announcement", children: [_jsx("p", { className: "announcement__language", children: _jsxs("strong", { children: [item[0].substring(3, 5), ":"] }) }), _jsx("span", { dangerouslySetInnerHTML: { __html: renderMarkdown(item[1]) } })] }, item[0])));
            },
        },
        {
            title: _jsx("span", { className: "announcement-title", children: txt('from') }),
            align: 'center',
            render: (row) => `${row.dateFrom}`,
        },
        {
            title: _jsx("span", { className: "announcement-title", children: txt('to') }),
            align: 'center',
            render: (row) => `${row.dateTo}`,
        },
        {
            render: (_, record) => (_jsx(Popconfirm, { title: txt('deleteAnnouncement'), okText: txt('yesDelete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => { void handleDelete(record.key); }, cancelText: txt('cancel'), children: _jsx(Tooltip, { title: txt('delete'), children: _jsx(Button, { className: "projs-toolbar-btn btn__bin", children: _jsx(DeleteOutlined, {}) }) }) })),
        },
    ];
    const toolbar = (_jsx("div", { className: "toolbar", children: _jsx("div", { className: "toolbar__left", children: _jsx(AddButton, { title: txt('newAnnouncement'), onClick: () => setAnnounModalVisible(true) }) }) }));
    return (_jsxs(Card, { className: "announcement-page-card", bordered: false, children: [toolbar, _jsx(ResponsiveTable, { className: "table-striped-rows", tableView: tableView, onViewChange: () => { void fetchAnnouncementList(); }, dataSource: announcementList === LOADING ? [] : announcementList.announcements, recordCount: announcementList === LOADING ? 0 : announcementList.totalCount, columns: columns, loading: announcementList === LOADING }), _jsx(NewAnnouncementModal, { onOk: () => { void fetchAnnouncementList(); }, onCancel: () => setAnnounModalVisible(false), visible: announModalVisible })] }));
};
export default AnnouncementSubpage;
