import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import clsx from 'clsx';
import { fragileTxt, txt } from 'libs/i18n';
import { SpeakerColorSchema } from 'api/settings/user-settings';
import { DownOutlined } from '@ant-design/icons';
import styles from './styles.module.less';
const { Option } = Select;
const capitalizeFirstLetter = (color) => {
    return color[0].toUpperCase() + color.slice(1);
};
const SpeakerColorSelect = ({ defaultColor, style, value, onSelect, onClose, selectClassName, popupClassName, bordered, dropdownMatchSelectWidth, size, onChange, hideArrow, direction, }) => (_jsxs(Select, { className: clsx(styles.speakerColorSelect, selectClassName, direction), popupClassName: clsx(styles.speakerColorSelect, styles.speakerColorDropdown, popupClassName, direction), style: style, onChange: onChange, size: size, listHeight: 300, value: value, bordered: bordered, suffixIcon: hideArrow === true ? null : _jsx(DownOutlined, {}), onSelect: onSelect, popupMatchSelectWidth: dropdownMatchSelectWidth, onBlur: onClose, autoFocus: true, children: [defaultColor !== undefined ? (_jsxs(Option, { value: "default", children: [_jsx("span", { className: clsx(styles.colorCircle, styles[defaultColor]) }), _jsx("span", { className: "color-text", children: txt('speakerColorDefault') })] }, "default")) : null, SpeakerColorSchema.values.map((color) => {
            var _a;
            return (_jsxs(Option, { value: color, children: [_jsx("div", { className: clsx(styles.colorCircle, styles[color]) }), _jsx("span", { className: styles.colorText, children: (_a = fragileTxt(`speakerColor${capitalizeFirstLetter(color)}`)) !== null && _a !== void 0 ? _a : color })] }, color));
        })] }));
export default SpeakerColorSelect;
