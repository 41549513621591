import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ColorPicker, Flex, Form, Input, Popconfirm, Tag, Switch, Button, } from 'antd';
import { txt } from 'libs/i18n';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deriveTagColor, deriveTagStyle } from 'libs/tag-style';
import FormModal from 'components/FormModal';
import { useState } from 'react';
import IconButton from 'components/IconButton';
import { deleteCurrentTeamTag, updateCurrentTeamTag } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import styles from './style.module.less';
const { CheckableTag } = Tag;
const ConfirmDelete = () => (_jsxs("p", { children: [_jsxs("strong", { children: [txt('deleteTag'), ' ', "?"] }), _jsx("br", {}), txt('deleteTagFromAllProjectsPart1'), ' ', _jsx("strong", { children: txt('deleteTagFromAllProjectsPart2') }), _jsx("br", {})] }));
const TagCheckbox = ({ tag, allTags, onChangeTag, onRefetchProjects, onDeleteTag, }) => {
    var _a, _b;
    const { title, check, color } = tag;
    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();
    const { session } = useSession();
    const createTagStyle = () => {
        const isChecked = check === 'on';
        const isPartial = check === 'partial';
        const colorCode = form.getFieldValue('color');
        const tagColor = color !== null && color !== void 0 ? color : colorCode;
        if (isChecked) {
            return deriveTagStyle(title, tagColor);
        }
        return ({
            border: isPartial ? `2px solid ${tagColor}` : '0.5px dashed var(--ant-color-text-disabled)',
            backgroundColor: 'transparent',
            color: 'black',
        });
    };
    const onChange = () => {
        const updatedTag = check === 'partial' || check === 'off' ? Object.assign(Object.assign({}, tag), { check: 'on' }) : Object.assign(Object.assign({}, tag), { check: 'off' });
        onChangeTag(updatedTag);
    };
    const handleTagChanges = async (editedTag) => {
        var _a, _b, _c, _d, _e;
        createTagStyle();
        onRefetchProjects();
        const updatedTag = Object.assign(Object.assign({}, tag), { color: (_a = editedTag.color) !== null && _a !== void 0 ? _a : deriveTagColor(editedTag.title), title: editedTag.title, abbreviation: (_b = editedTag.abbreviation) !== null && _b !== void 0 ? _b : editedTag.title.slice(0, 2), isPinned: editedTag.isPinned, id: tag.id });
        onChangeTag(updatedTag);
        await updateCurrentTeamTag(session.connection, tag.id, editedTag.title, (_c = editedTag.abbreviation) !== null && _c !== void 0 ? _c : editedTag.title.slice(0, 2), (_d = editedTag.color) !== null && _d !== void 0 ? _d : deriveTagColor(editedTag.title), (_e = editedTag.isPinned) !== null && _e !== void 0 ? _e : true);
        setModalOpen(false);
    };
    const handleDeleteTag = async () => {
        await deleteCurrentTeamTag(session.connection, tag.id);
        onDeleteTag();
        onRefetchProjects();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { justify: "space-between", children: [_jsx(CheckableTag, { className: "beeyTag", style: createTagStyle(), checked: check === 'on', onChange: onChange, children: title }), _jsxs(Flex, { children: [_jsx(Popconfirm, { title: _jsx(ConfirmDelete, {}), okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: handleDeleteTag, cancelText: txt('cancel'), children: _jsx(DeleteOutlined, {}) }), _jsx(IconButton, { onClick: () => setModalOpen(true), children: _jsx(EditOutlined, {}) })] })] }), _jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], open: modalOpen, onCancel: () => setModalOpen(false), className: styles.modal, title: txt('editTag'), footer: false, children: _jsx(Form, { form: form, onFinish: handleTagChanges, labelCol: { span: 10 }, wrapperCol: { span: 14 }, labelAlign: "left", initialValues: {
                        title: tag.title,
                        abbreviation: (_a = tag.abbreviation) !== null && _a !== void 0 ? _a : tag.title.substring(0, 2),
                        color: (_b = tag.color) !== null && _b !== void 0 ? _b : deriveTagColor(tag.title),
                        isPinned: tag.isPinned,
                    }, children: _jsxs(Flex, { vertical: true, children: [_jsx(Form.Item, { name: "title", label: txt('name'), rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: txt('noTagName'),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (tag.title === value) {
                                                return Promise.resolve();
                                            }
                                            const duplicitTag = allTags.find((tg) => tg.title.trim() === value.trim());
                                            if (duplicitTag !== undefined) {
                                                return Promise.reject(txt('duplicitTag'));
                                            }
                                            if (value.trim().startsWith('#')) {
                                                return Promise.reject(txt('startsWithHash'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "abbreviation", label: txt('abbreviation'), rules: [
                                    {
                                        max: 2,
                                        message: txt('max2chars'),
                                    },
                                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "color", label: txt('tagColor'), getValueFromEvent: (clr) => {
                                    return `#${clr.toHex()}`;
                                }, children: _jsx(ColorPicker, {}) }), _jsxs(Flex, { gap: "middle", align: "center", children: [_jsx("span", { children: txt('showAsOption') }), _jsx(Form.Item, { name: "isPinned", className: styles.switch, children: _jsx(Switch, { size: "small" }) })] }), _jsx(Button, { type: "primary", htmlType: "submit", className: styles.button, children: "OK" })] }) }) })] }));
};
export default TagCheckbox;
