import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, Form, Input, } from 'antd';
import { Alerts } from 'components/Alerts';
import { fetchPasswordSettings, changeOwnPassword } from 'api/user-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import './style.less';
const ChangePasswordForm = () => {
    const [form] = Form.useForm();
    const { session } = useSession();
    const [alert, setAlert] = useState();
    const [requiredLength, setRequiredLength] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const fetchRequiredPwdLength = async () => {
        const passwordSettings = await fetchPasswordSettings();
        setRequiredLength(passwordSettings.requiredLength);
    };
    useEffect(() => {
        void fetchRequiredPwdLength();
    }, []);
    const handleSubmit = async (passwordValues) => {
        const result = await changeOwnPassword(session.connection, passwordValues.current, passwordValues.new);
        form.resetFields();
        if (result.isSuccess()) {
            setAlert(Alerts.success(txt('successChange')));
        }
        else {
            const error = result.err();
            if (error === 'wrong-password') {
                setAlert(Alerts.error(txt('oldNotValid')));
            }
            else {
                setAlert(Alerts.crash(txt('unexpectedError')));
            }
        }
    };
    return (_jsxs("div", { children: [_jsxs("h4", { children: [txt('changePW'), ":"] }), alert, _jsxs(Form, { onFinish: handleSubmit, form: form, className: "changePW-form", children: [_jsx(Form.Item, { name: "current", label: txt('currPW'), className: "changePW-form__row", rules: [
                            {
                                validator: async (_, value) => {
                                    if (value === '') {
                                        setSubmitDisabled(true);
                                        return Promise.reject(txt('pleaseCurr'));
                                    }
                                    setSubmitDisabled(false);
                                    return Promise.resolve();
                                },
                            },
                        ], children: _jsx(Input.Password, {}) }), _jsx(Form.Item, { name: "new", label: txt('newPW'), className: "changePW-form__row", rules: [
                            {
                                validator: async (_, value) => {
                                    if (value === '') {
                                        setSubmitDisabled(true);
                                        return Promise.reject(txt('pleaseNew'));
                                    }
                                    if (value !== '' && value.length < requiredLength) {
                                        setSubmitDisabled(true);
                                        return Promise.reject(new Error(`${txt('minChars')} ${requiredLength}`));
                                    }
                                    setSubmitDisabled(false);
                                    return Promise.resolve();
                                },
                            },
                        ], children: _jsx(Input.Password, {}) }), _jsx(Form.Item, { name: "confirm", label: txt('confirmPW'), className: "changePW-form__row", dependencies: ['new'], rules: [
                            {
                                validator: async (_, value) => {
                                    if (value === '') {
                                        setSubmitDisabled(true);
                                        return Promise.reject(txt('pleaseConfirm'));
                                    }
                                    if (value !== '' && value !== form.getFieldValue('new')) {
                                        setSubmitDisabled(true);
                                        return Promise.reject(txt('noMatch'));
                                    }
                                    setSubmitDisabled(false);
                                    return Promise.resolve();
                                },
                            },
                        ], children: _jsx(Input.Password, {}) }), _jsx(Form.Item, { className: "changePW-form__btn-save", children: _jsx(Button, { type: "primary", htmlType: "submit", disabled: submitDisabled, children: txt('save') }) })] })] }));
};
export default ChangePasswordForm;
