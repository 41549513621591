import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import { LoadingOutlined, CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined, DeleteOutlined, LinkOutlined, FileOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { MediaFile } from 'screens/DashboardScreen/media-upload/media-origin';
import styles from './style.module.less';
const ellipseFileName = (fileName) => {
    if (fileName.length < 25) {
        return fileName;
    }
    return `${fileName.slice(0, 30)}...`;
};
const MediaOriginView = ({ mediaOrigin, onRemoveOrigin }) => {
    let Icon = CloseCircleOutlined;
    let duration = '';
    let title = '';
    if (mediaOrigin.duration.status === 'loading') {
        Icon = LoadingOutlined;
        duration = '';
        title = '';
    }
    else if (mediaOrigin.duration.status === 'unknown') {
        if (mediaOrigin.getError() === 'cannot-process') {
            Icon = ExclamationCircleOutlined;
            duration = '';
            title = txt('failedProject');
        }
        else if (mediaOrigin.getError() === 'invalidFormat') {
            Icon = CloseCircleOutlined;
            duration = '';
            title = txt('invalidFileFormat');
        }
        else {
            Icon = ExclamationCircleOutlined;
            duration = '(?? min)';
            title = txt('durationUnknownInfo');
        }
    }
    else if (mediaOrigin.hasShortDuration()) {
        Icon = CheckCircleOutlined;
        duration = `(${mediaOrigin.duration.credits} min)`;
        title = '';
    }
    else if (mediaOrigin.hasMediumDuration()) {
        Icon = ExclamationCircleOutlined;
        duration = `(${mediaOrigin.duration.credits} min)`;
        title = txt('longRecordingInfo');
    }
    else if (mediaOrigin.hasLongDuration()) {
        Icon = CloseCircleOutlined;
        duration = `(${mediaOrigin.duration.credits} min)`;
        title = txt('extraLongRecordingInfo');
    }
    return (_jsx(Tooltip, { title: title, children: _jsxs("div", { className: styles.mediaOrigin, children: [_jsx(Icon, {}), _jsxs("div", { className: styles.mediaOriginLabel, children: [mediaOrigin instanceof MediaFile ? _jsx(FileOutlined, {}) : _jsx(LinkOutlined, {}), ellipseFileName(mediaOrigin.getName()), ` ${duration}`] }), mediaOrigin.duration.status === 'loading' ? null : (_jsx("button", { type: "button", onClick: () => onRemoveOrigin(mediaOrigin), children: _jsx(DeleteOutlined, {}) }))] }) }));
};
export default MediaOriginView;
