import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Form, Button, InputNumber } from 'antd';
import { txt } from 'libs/i18n';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import styles from '../../style.module.less';
import creditStyles from './style.module.less';
// SEE: https://ant.design/components/input-number/#components-input-number-demo-formatter
export const regexThousandsSeparator = /\B(?=(\d{3})+(?!\d))/g;
const CreditSettings = ({ team, creditDelta, onCreditChanged, onOperation, }) => {
    return (_jsxs(Form, { wrapperCol: { sm: { offset: 3 } }, labelCol: { sm: { span: 9 } }, labelAlign: "left", className: styles.form, children: [_jsx(Form.Item, { label: txt('remainingCredit'), children: _jsx("span", { className: "ant-form-text", children: `${team.credit}\xa0min` }) }), _jsxs(Form.Item, { label: txt('modifyCredit'), children: [_jsx(InputNumber, { className: creditStyles.creditInput, id: "input-credit", min: 0, max: 1000000000, onKeyDown: (event) => {
                            if (!(event.key === 'Backspace') && !/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }, formatter: (value) => (value === undefined ? String(creditDelta) : value.toString().replace(regexThousandsSeparator, ' ')), 
                        // NOTE: Parsing back added spaces.
                        parser: (value) => (value === undefined ? creditDelta : Number(value.replace(/\$\s?|( *)/g, ''))), precision: 0, value: creditDelta, onChange: onCreditChanged, autoFocus: true }), _jsxs("span", { className: creditStyles.buttons, children: [_jsx(Button, { className: creditStyles.creditButton, onClick: () => onOperation('subtract'), children: _jsx(MinusCircleFilled, {}) }), _jsx(Button, { className: creditStyles.creditButton, onClick: () => onOperation('add'), children: _jsx(PlusCircleFilled, {}) })] })] })] }));
};
export default CreditSettings;
