import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import SpeakerColorButton from '../SpeakerColorButton';
import SpeakerSign from '../SpeakerSign';
import SpeakerTimestamp from '../SpeakerTimestamp';
const SpeakerWidget = ({ position, widgetTrigger, onClick, disabled, isCaptionMode, editorController, }) => {
    var _a;
    const parentTriggerNode = widgetTrigger.parentElement;
    if (parentTriggerNode === null) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(SpeakerTimestamp, { position: position, widgetTrigger: widgetTrigger }), isCaptionMode === true && (_jsxs(_Fragment, { children: [_jsx(SpeakerColorButton, { widgetTrigger: widgetTrigger, position: position, onClick: () => onClick(parentTriggerNode, 'editSpeakerColor'), backgroundColor: (_a = editorController.captions.parameters) === null || _a === void 0 ? void 0 : _a.defaultColor, disabled: disabled }), _jsx(SpeakerSign, { position: position, widgetTrigger: parentTriggerNode, disabled: disabled, editorController: editorController })] }))] }));
};
export default SpeakerWidget;
