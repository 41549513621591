import Delta from 'quill-delta';
import { SPECIAL_SYMBOLS_REGEX, NONSPACE_REGEX, LETTER_REGEX } from 'screens/DashboardScreen/pages/EditorPage/DocumentEditor/text-utils';
export const doesOperationIncludeFormat = (op, formats) => {
    return formats.some((format) => op.attributes !== undefined && op.attributes[format] !== undefined);
};
// NOTE: To exclude spaces and special symbols.
export const findClosestLetter = (quill, index) => {
    if (quill === undefined) {
        return 0;
    }
    let i = index - 1;
    let char = quill.getText(i, 1);
    while (i < quill.getLength()
        && (!NONSPACE_REGEX.test(char) || SPECIAL_SYMBOLS_REGEX.test(char))) {
        i += 1;
        char = quill.getText(i, 1);
    }
    return i;
};
export const getClosestWordEnd = (quill, index) => {
    if (quill === undefined) {
        return 0;
    }
    let i = findClosestLetter(quill, index);
    let char = quill.getText(i, 1);
    while (i < quill.getLength() && LETTER_REGEX.test(char)) {
        i += 1;
        char = quill.getText(i, 1);
    }
    return i;
};
export const changeCapitalLetter = (quill, index, isCapital) => {
    if (quill === undefined)
        return;
    const selection = quill.getSelection();
    if (!selection)
        return;
    let i = findClosestLetter(quill, index + 1);
    let char = quill.getText(i, 1);
    if (quill.getFormat(i, 1).speaker !== undefined)
        return;
    // search for the first nonspace
    while (i < quill.getLength() && !NONSPACE_REGEX.test(char)) {
        i += 1;
        char = quill.getText(i, 1);
    }
    const letter = isCapital ? char.toLowerCase() : char.toUpperCase();
    if (letter !== char) {
        quill.deleteText(i, 1, 'user');
        quill.insertText(i, letter, 'user');
    }
};
export const getNodeProperty = (domNode, prefix) => {
    const classes = domNode.className.split(' ');
    let value = null;
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].startsWith(prefix)) {
            value = classes[i].substring(prefix.length);
            break;
        }
    }
    return value;
};
export const getChangedRangeFromDelta = (delta) => {
    const ops = [...delta.ops];
    let changeStart = 0;
    let changeEnd = 0;
    let cursor = 0;
    let op = ops.shift();
    while (op && op.retain !== undefined) {
        changeStart += op.retain;
        cursor += op.retain;
        op = ops.shift();
    }
    if (!op) {
        // no text change, only retains
        return null;
    }
    while (op) {
        if (op.retain !== undefined)
            cursor += op.retain;
        else if (op.insert !== undefined) {
            if (typeof op.insert === 'string') {
                cursor += op.insert.length;
            }
            else {
                cursor += 1;
            }
            changeEnd = cursor;
        }
        else if (op.delete !== undefined) {
            changeEnd = cursor;
        }
        op = ops.shift();
    }
    if (Number.isNaN(changeEnd)) {
        global.logger.error('invalid change range detected.', {
            delta: JSON.stringify(delta),
        });
        return [changeStart, changeStart];
    }
    return [changeStart, changeEnd];
};
const tryFastCompose = (original, change) => {
    if (change.ops.length === 0)
        return null;
    const changeStart = change.ops[0].retain;
    if (change.ops[0].attributes !== undefined)
        return null;
    if (changeStart === undefined)
        return null;
    const originalLegth = original.length();
    if (changeStart < originalLegth)
        return null;
    if (changeStart === originalLegth) {
        return original.concat(new Delta(change.ops.slice(1)));
    }
    return original.retain(changeStart - originalLegth).concat(new Delta(change.ops.slice(1)));
};
export const fastCompose = (original, change) => {
    const maybeComposedDelta = tryFastCompose(original, change);
    if (maybeComposedDelta === null) {
        return original.compose(change);
    }
    return maybeComposedDelta;
};
// NOTE: quill does not gurantee how line without format looks. Internally they
// use implicit conversion to boolean. The fact that a line does not have some
// format could be represented by missing key, or any falsy value for that key.
// We normalize it so that the key will always be missing.
export const normalizeFormat = (format) => {
    const filteredFormat = Object.assign({}, format);
    Object.keys(format).forEach((name) => {
        if (!Boolean(filteredFormat[name])) {
            delete filteredFormat[name];
        }
    });
    return filteredFormat;
};
