import { jsx as _jsx } from "react/jsx-runtime";
import { App, Button } from 'antd';
import { createStripeOrder } from 'api/orders';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
const PurchaseButton = ({ isSubscribed, lookupKey, quantity, currency, isCurrentPlan, onGetStripeCustomerPortal, isCanceled, billingInfo, onBillingModalVisible, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const createOrder = async () => {
        if (billingInfo === null) {
            onBillingModalVisible();
            return;
        }
        const result = await createStripeOrder(session.connection, lookupKey, quantity, currency);
        if (result.isFail()) {
            if (result.err() === 'invalid-user-data') {
                onBillingModalVisible();
            }
            else {
                void message.error(result.err());
            }
        }
        else {
            window.open(result.get().Url, '_self');
        }
    };
    const isSingleUser = lookupKey === 'beey_start';
    const isEnterprise = lookupKey === 'enterprise';
    if (isSubscribed) {
        if (isSingleUser && isCanceled) {
            return _jsx(Button, { type: "primary", onClick: () => createOrder(), children: txt('topUpCredit') });
        }
        if (isSingleUser) {
            return _jsx(Button, { type: "primary", onClick: () => onGetStripeCustomerPortal('subscription_cancel'), children: txt('cancelPaidPlan') });
        }
        if (isCurrentPlan) {
            return _jsx(Button, { type: "primary", disabled: true, children: txt('currentPlan') });
        }
        if (!isEnterprise) {
            return _jsx(Button, { type: "primary", onClick: () => onGetStripeCustomerPortal('subscription_update_confirm'), children: txt('getPlan') });
        }
    }
    else {
        if (isSingleUser) {
            return _jsx(Button, { type: "primary", disabled: true, children: txt('currentPlan') });
        }
        if (!isEnterprise) {
            return _jsx(Button, { type: "primary", onClick: () => createOrder(), children: txt('getPlan') });
        }
    }
    return _jsx(Button, { type: "primary", onClick: () => window.open('https://www.beey.io/cs/kontakty/', '_blank'), children: txt('contactSales') });
};
export default PurchaseButton;
