import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag, Tooltip, Spin, } from 'antd';
import clsx from 'clsx';
import './style.less';
import { txt } from 'libs/i18n';
const { CheckableTag } = Tag;
const ProjectTag = ({ tag, checked, onTagChange, loadingTags, disabled, style, }) => {
    const isTagLoading = loadingTags.includes(tag);
    const areTagsLoading = loadingTags.length > 0;
    const onTagCheckChange = (checkedBool) => {
        if (!areTagsLoading && disabled === false) {
            onTagChange(tag, checkedBool);
        }
    };
    return (_jsx(Tooltip, { title: disabled === true ? txt('readOnlyProject') : false, children: _jsxs(CheckableTag, { checked: checked, style: style, onChange: onTagCheckChange, className: clsx({ beeyTag__disabled: (areTagsLoading && !isTagLoading) || disabled }, 'beeyTag'), children: [isTagLoading && _jsx(Spin, { size: "small" }), tag] }) }));
};
export default ProjectTag;
