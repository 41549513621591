import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Collapse } from 'antd';
import { localeTxt } from 'libs/i18n';
import Claim from '../Claim';
const LanguageProfiles = ({ profiles, locale, userid, claimSet, }) => {
    return (_jsx(Collapse, { bordered: false, items: [
            {
                key: locale.code,
                label: localeTxt(locale),
                children: (_jsx(_Fragment, { children: profiles.map((profile) => (_jsx(Claim, { userid: userid, claimTitle: ` ${locale.code} ${String(profile.tags[0])}:`, claimAPIName: profile.claim, claimSet: claimSet }, profile.claim))) })),
            },
        ] }));
};
export default LanguageProfiles;
