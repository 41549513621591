import { jsx as _jsx } from "react/jsx-runtime";
import { Speaker } from 'api/speaker';
import SpeakerColorSelect from '../SpeakerColorSelect';
import styles from './styles.module.less';
const FloatingSpeakerColorSelect = ({ expandedNode, speakers, onColorChange, onClose, defaultSpeakerColor, isRightToLeft, }) => {
    var _a, _b, _c;
    const speakerId = Speaker.getId(expandedNode);
    const selectedSpeaker = speakers.getSpeakerById(speakerId);
    if (selectedSpeaker === null) {
        global.logger.error('failed to change speaker color - no selected speaker');
        return _jsx("div", {});
    }
    const speakerColor = (_a = selectedSpeaker.captionColor) !== null && _a !== void 0 ? _a : defaultSpeakerColor;
    const selectedColor = selectedSpeaker.isDefaultColor ? 'default' : speakerColor;
    const rect = (_c = (_b = expandedNode.firstElementChild) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) !== null && _c !== void 0 ? _c : expandedNode.getBoundingClientRect();
    const handleSelect = (selectedValue) => {
        const newColor = selectedValue === 'default' ? defaultSpeakerColor : selectedValue;
        onColorChange(selectedSpeaker, newColor, selectedValue === 'default');
    };
    return (_jsx(SpeakerColorSelect, { selectClassName: styles.speakerColorSelect, direction: isRightToLeft ? 'rtl' : 'ltr', popupClassName: styles.speakerColorDropdown, defaultColor: defaultSpeakerColor, style: { left: `${rect.left - 12}px`, top: `${rect.top + rect.height}px` }, value: selectedColor, onSelect: handleSelect, dropdownMatchSelectWidth: 150, onClose: onClose }));
};
export default FloatingSpeakerColorSelect;
