import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from 'antd';
import { deriveTagStyle } from 'libs/tag-style';
const TagOption = ({ label, value, closable, onClose, }) => {
    const handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (_jsx(Tag, { className: "beeyTag", style: deriveTagStyle(value), onMouseDown: handleMouseDown, closable: closable, onClose: onClose, children: _jsx("span", { children: label }) }));
};
export default TagOption;
