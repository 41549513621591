import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
export const durationInSeconds = (time) => {
    const durationArray = time.split(':');
    const hrs = Number(durationArray[0]);
    const mins = Number(durationArray[1]);
    const secs = Number(durationArray[2]);
    return dayjs.duration({ seconds: secs, minutes: mins, hours: hrs }).asSeconds();
};
// this function is optimized for fast performance
export const secondsToIso = (time) => {
    if (time === Infinity)
        throw new Error('timestamp is infinity');
    let remaining = time;
    let hours = Math.floor(remaining / 3600);
    remaining -= hours * 3600;
    let minutes = Math.floor(remaining / 60);
    remaining -= minutes * 60;
    let seconds = Math.floor(remaining);
    remaining -= seconds;
    let milliseconds = Math.round(remaining * 1000 + 0.001); // round 0.5 ms up
    if (milliseconds === 1000) {
        milliseconds = 0;
        seconds += 1;
        if (seconds === 60) {
            seconds = 0;
            minutes += 1;
            if (minutes === 60) {
                minutes = 0;
                hours += 1;
            }
        }
    }
    let decimals = '';
    if (milliseconds > 0) {
        decimals += '.';
        if (milliseconds < 10) {
            decimals += '0';
        }
        if (milliseconds < 100) {
            decimals += '0';
        }
        decimals += String(milliseconds);
        while (decimals.endsWith('0')) {
            decimals = decimals.substring(0, decimals.length - 1);
        }
    }
    const isoSeconds = seconds > 0 || milliseconds > 0 ? `${seconds}${decimals}S` : '';
    const isoMinutes = minutes > 0 ? `${minutes}M` : '';
    const isoHours = hours > 0 ? `${hours}H` : '';
    const isoValue = `PT${isoHours}${isoMinutes}${isoSeconds}`;
    if (isoValue === 'PT')
        return 'PT0S';
    return isoValue;
};
export const isoToSeconds = (isoDurationString) => (dayjs.duration(isoDurationString).asSeconds());
