import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createAppLink } from 'api/model/services/services-api';
import { fragileTxt } from 'libs/i18n';
import { AppIcon } from 'components/CustomIcons';
import './style.less';
const getAppDescription = (endpoint) => {
    const nameWithoutSlash = endpoint.substring(1).replace(/[^a-zA-Z]/g, '');
    const description = fragileTxt(`${nameWithoutSlash}Description`);
    return description !== null && description !== void 0 ? description : '';
};
const AppCard = ({ name, endpoint }) => {
    const appLink = createAppLink(endpoint);
    return (_jsxs("div", { className: "beey-app", children: [_jsx(AppIcon, { className: "beey-app__icon" }), _jsxs("div", { className: "beey-app__info", children: [_jsx("a", { className: "beey-app__name", href: appLink, target: "_blank", rel: "noreferrer", children: name }), _jsx("div", { className: "beey-app__desc", children: getAppDescription(endpoint) })] })] }));
};
export default AppCard;
