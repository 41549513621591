import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Flex } from 'antd';
import clsx from 'clsx';
import { txt } from 'libs/i18n';
import { InfoCircleOutlined } from '@ant-design/icons';
import { separateThousandsWithDecimals } from '../../TeamPlan/LastPayment';
import styles from './style.module.less';
import PurchaseButton from './PurchaseButton';
import ProductFeatures from './ProductFeatures';
const formatPrice = (interval, price) => {
    if (interval === 'custom') {
        return txt('custom');
    }
    if (interval === 'year' || interval === 'month') {
        return separateThousandsWithDecimals(price);
    }
    return '0,00';
};
const PlanCard = ({ lookupKey, isSubscribed, plan, interval, currency, price, onGetStripeCustomerPortal, isCanceled, quantity, productName, billingInfo, onBillingModalVisible, }) => {
    return (_jsx("div", { className: clsx({
            [styles.border]: (isSubscribed
                && (plan === null || plan === void 0 ? void 0 : plan.lookupKey) === lookupKey)
                || (!isSubscribed && lookupKey === 'beey_start'),
        }), children: _jsxs(Card, { title: lookupKey === 'beey_start' ? 'Start' : productName, bordered: false, className: clsx(styles.card, {
                [styles.activeCard]: (isSubscribed
                    && (plan === null || plan === void 0 ? void 0 : plan.lookupKey) === lookupKey)
                    || (!isSubscribed && lookupKey === 'beey_start'),
            }), children: [_jsxs("div", { className: styles.price, children: [_jsx("strong", { children: formatPrice(interval, lookupKey === 'beey_start'
                                ? 0
                                : price) }), interval !== 'custom' ? (_jsx("strong", { children: currency })) : null, interval === 'year' || interval === 'month' ? (_jsxs("span", { children: ["/", txt(interval).toLowerCase()] })) : null, interval === 'year' ? (_jsxs("div", { className: styles.infoBilling, children: [_jsx(InfoCircleOutlined, { className: styles.icon }), separateThousandsWithDecimals(lookupKey === 'beey_start'
                                    ? 0
                                    : price / 12), ' ', _jsxs("span", { children: [currency, "/", txt('month').toLowerCase()] })] })) : null] }), _jsx("div", { className: styles.features, children: _jsx(ProductFeatures, { lookupKey: lookupKey, price: lookupKey === 'enterprise'
                            ? 0
                            : price, currency: currency }) }), _jsx("div", {}), _jsx(Flex, { justify: "center", children: _jsx(PurchaseButton, { isSubscribed: isSubscribed, isCanceled: isCanceled, lookupKey: lookupKey, quantity: quantity, currency: currency, onGetStripeCustomerPortal: (landingPage) => onGetStripeCustomerPortal(landingPage), isCurrentPlan: lookupKey === (plan === null || plan === void 0 ? void 0 : plan.lookupKey), billingInfo: billingInfo, onBillingModalVisible: onBillingModalVisible }) })] }, lookupKey) }, lookupKey));
};
export default PlanCard;
