import { splitAtFixedPositionVertically, } from 'libs/positions';
export const documentPositions = (bounds, isToolbarHidden, editorPriority) => {
    if ('display' in bounds) {
        return {
            document: { display: 'none' },
            toolbar: { display: 'none' },
        };
    }
    if (editorPriority === 'secondary') {
        return {
            document: bounds,
            toolbar: { display: 'none' },
        };
    }
    const [toolbar, document] = splitAtFixedPositionVertically(bounds, isToolbarHidden ? 15 : 35);
    return { document, toolbar };
};
