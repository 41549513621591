import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Form, Input, Select, Checkbox, Collapse, Divider, Spin, Switch, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SingleFileUpload from 'components/SingleFileUpload';
import { useSession } from 'components/Authenticated';
import { fragileTxt, txt } from 'libs/i18n';
import { transcribeLink } from 'api/model/services/services-api';
import { setupNewProject, updateProjectMetadata } from 'api/project-api';
import { useDashboard } from 'screens/DashboardScreen/dashboard-context';
import ModalFooter from 'components/ModalFooter';
import LanguageSelect from 'components/LanguageSelect';
import { selectInitialTranscriptionProfile } from 'libs/locales/recognition';
import clsx from 'clsx';
import MediaOriginsUpload from './MediaOriginsUpload';
import MediaOriginsSummary from './MediaOriginsSummary';
import RequiredCredit from './RequiredCredit';
import { MediaOriginsList } from './media-origins-list';
import styles from './style.module.less';
const emptyOriginsList = new MediaOriginsList();
const handleTrsxValidation = (info) => {
    if (info === null) {
        return Promise.resolve();
    }
    const fileName = info.name;
    const fileType = fileName.slice(fileName.lastIndexOf('.') + 1);
    const isTrsx = fileType === 'trsx';
    return (isTrsx === false
        ? Promise.reject(txt('fileNotTrsx'))
        : Promise.resolve());
};
const LoadedContent = ({ onClose, onSubmit, transcriptionOptions, initialLocale, credit, project, remainingStorage, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { session, updateUserSettings } = useSession();
    const { connection } = session;
    const { uploadQueueController } = useDashboard();
    const { user: { settings: { transcriptionSettings } } } = session.login;
    const [isLoadingUrls, setIsLoadingUrls] = useState(false);
    const [form] = Form.useForm();
    const transcribeExistingProject = project !== undefined;
    const initialValues = {
        originsList: emptyOriginsList,
        projectName: (_a = project === null || project === void 0 ? void 0 : project.name) !== null && _a !== void 0 ? _a : null,
        language: (_c = (_b = project === null || project === void 0 ? void 0 : project.transcriptionConfig) === null || _b === void 0 ? void 0 : _b.language) !== null && _c !== void 0 ? _c : initialLocale,
        transcriptionProfile: (_e = (_d = project === null || project === void 0 ? void 0 : project.transcriptionConfig) === null || _d === void 0 ? void 0 : _d.profile) !== null && _e !== void 0 ? _e : transcriptionSettings.languageModel,
        trsxFile: null,
        usePPC: (_g = (_f = project === null || project === void 0 ? void 0 : project.transcriptionConfig) === null || _f === void 0 ? void 0 : _f.withPPC) !== null && _g !== void 0 ? _g : transcriptionSettings.usePpc,
        forbidVAD: (_j = (_h = project === null || project === void 0 ? void 0 : project.transcriptionConfig) === null || _h === void 0 ? void 0 : _h.withVAD) !== null && _j !== void 0 ? _j : transcriptionSettings.useVad === false,
        useDiarization: (_l = (_k = project === null || project === void 0 ? void 0 : project.transcriptionConfig) === null || _k === void 0 ? void 0 : _k.withDiarization) !== null && _l !== void 0 ? _l : transcriptionSettings.useDiarization,
    };
    // FIXME: @podlomar - I am not sure if this is even fixable.
    // At first render, useWatch returns undefined but after thet the form values are
    // always set to proper values so there is no point in allowing them
    // to be undefined in TranscribeFormValues. Maybe this is a bug in the antd form library,
    // maybe there is a way do this properly somehow.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const originsList = (_m = Form.useWatch('originsList', form)) !== null && _m !== void 0 ? _m : initialValues.originsList;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const language = (_o = Form.useWatch('language', form)) !== null && _o !== void 0 ? _o : initialValues.language;
    const allOrigins = originsList.getAllOrigins();
    const profiles = (_q = (_p = transcriptionOptions.find((option) => option.locale.equals(language))) === null || _p === void 0 ? void 0 : _p.profiles) !== null && _q !== void 0 ? _q : [];
    const startLinkTranscription = async (projectToUpdate, mediaUrl, transcribeAttrs) => {
        var _a;
        const projectName = (_a = form.getFieldValue('projectName')) !== null && _a !== void 0 ? _a : mediaUrl.getName();
        let newProject = projectToUpdate === 'new' ? await setupNewProject(connection, projectName) : projectToUpdate;
        newProject = await updateProjectMetadata(connection, newProject, 'language', transcribeAttrs.language.code);
        await transcribeLink(connection, mediaUrl.url, newProject.name, newProject.id, transcribeAttrs);
        return newProject;
    };
    const handleSubmit = async (formValues) => {
        var _a;
        const mediaOriginsList = formValues.originsList;
        const languageProfile = (_a = formValues.transcriptionProfile) !== null && _a !== void 0 ? _a : 'default';
        if (mediaOriginsList.canSubmit(credit, remainingStorage, session.login.hasClaim('unlimitedStorage:enabled'))) {
            const transcribeAttrs = Object.assign(Object.assign(Object.assign({}, initialValues), formValues), { profile: languageProfile, language: formValues.language, useVAD: !formValues.forbidVAD });
            uploadQueueController.enqueue(mediaOriginsList.files.map((mediaFile) => {
                var _a;
                return ({
                    uploadTarget: Object.assign(Object.assign({}, transcribeExistingProject
                        ? {
                            type: 'existing',
                            project,
                        }
                        : {
                            type: 'new',
                            projectName: (_a = formValues.projectName) !== null && _a !== void 0 ? _a : mediaFile.getName(),
                        }), { transcribeAttributes: transcribeAttrs }),
                    mediaFile,
                    isUploadOnly: transcribeAttrs.trsxFile !== null,
                });
            }));
            setIsLoadingUrls(true);
            const urlProjects = await Promise.all(mediaOriginsList.urls.map((mediaUrl) => startLinkTranscription(transcribeExistingProject ? project : 'new', mediaUrl, transcribeAttrs)));
            setIsLoadingUrls(false);
            updateUserSettings({
                transcriptionSettings: {
                    useDiarization: formValues.useDiarization,
                    usePpc: formValues.usePPC,
                    useVad: !formValues.forbidVAD,
                    recognitionLocale: formValues.language.code,
                    languageModel: languageProfile,
                },
            });
            form.resetFields();
            onClose();
            onSubmit(mediaOriginsList, urlProjects);
        }
    };
    useEffect(() => {
        const updateProjectName = async () => {
            if (originsList.areOriginsResolved()) {
                const projectName = allOrigins.length === 1
                    ? allOrigins[0].getName()
                    : null;
                form.setFieldsValue({ projectName });
                return;
            }
            const resolvedOriginsList = await originsList.resolveOrigins(connection);
            form.setFieldsValue({ originsList: resolvedOriginsList });
        };
        void updateProjectName();
    }, [originsList]);
    useEffect(() => {
        const selectedOption = transcriptionOptions.find((option) => option.locale.equals(language));
        const initialProfile = selectInitialTranscriptionProfile(
        // NOTE: We only select from available options, so this should never be undefined.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedOption, transcriptionSettings.languageModel);
        form.setFieldsValue({ transcriptionProfile: initialProfile });
    }, [language]);
    const collapseItems = [
        {
            key: '1',
            label: txt('advancedSettings'),
            // NOTE: Collapse has to be forceRendered to enable
            // access to its form item initial values when closed.
            forceRender: true,
            children: (_jsxs(_Fragment, { children: [profiles.length > 1 && (_jsx(Form.Item, { label: txt('transcriberType'), name: "transcriptionProfile", className: styles.label, children: _jsx(Select, { children: profiles.map((profile) => {
                                var _a;
                                return (_jsx(Select.Option, { value: profile.profile, children: (_a = profile.tags[0]) !== null && _a !== void 0 ? _a : profile.profile }, profile.profile));
                            }) }) })), (allOrigins.length > 1 || originsList.urls.length > 0) ? (_jsx(Alert, { type: "info", showIcon: true, message: allOrigins.length > 1 ? txt('trsxForOneFile') : txt('trsxNotInLink') })) : (_jsx(Form.Item, { name: "trsxFile", valuePropName: "uploadFile", rules: [
                            {
                                validator: (_, validationInfo) => handleTrsxValidation(validationInfo),
                            },
                        ], children: _jsx(SingleFileUpload, { label: txt('trsxFile'), accept: ".trsx" }) })), _jsx(Form.Item, { name: "usePPC", valuePropName: "checked", className: styles.formCheckbox, children: _jsx(Checkbox, { children: txt('postprocessing') }) })] })),
        },
    ];
    // Note: Necessary to load project data into form as initial values.
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, []);
    return (_jsxs(Form, { form: form, preserve: false, layout: "vertical", onFinish: handleSubmit, initialValues: initialValues, children: [_jsx("h3", { children: txt('transcribeMedia') }), _jsxs("div", { className: styles.modalBody, children: [_jsx(Form.Item, { name: "originsList", valuePropName: "mediaOriginsList", className: styles.originsList, rules: [
                            {
                                validator: (_, list) => (list.isEmpty()
                                    ? Promise.reject(txt('insertMediaFile'))
                                    : Promise.resolve()),
                            },
                        ], children: _jsx(MediaOriginsUpload, { isSingleFileUpload: transcribeExistingProject, inputId: "input-media-files" }) }), _jsx(MediaOriginsSummary, { mediaOriginsList: originsList, availableCredit: credit, remainingStorage: remainingStorage }), _jsx(RequiredCredit, { mediaOriginsList: originsList }), _jsxs("div", { className: styles.creditSection, children: [_jsxs("span", { className: styles.creditSectionInfo, children: [_jsxs("span", { className: styles.label, children: [txt('availableCredit'), ' '] }), credit, ' ', "min"] }), session.login.hasClaim('orders:create') ? (_jsx(Link, { to: "/account/credit", children: txt('purchaseCredit') })) : null] }), _jsx(Divider, { className: styles.divider }), allOrigins.length < 2 && !transcribeExistingProject && (_jsx(Form.Item, { name: "projectName", className: styles.label, label: txt('projectName'), colon: false, required: false, rules: [
                            {
                                message: txt('nameRequired'),
                                required: true,
                                whitespace: true,
                            },
                        ], children: _jsx(Input, { placeholder: txt('untitledProj') }) })), _jsx(Form.Item, { className: styles.label, name: "language", label: txt('langOfTranscription'), children: _jsx(LanguageSelect, { id: "select-transcription-language", locales: transcriptionOptions.map((option) => option.locale) }) }), _jsx(Form.Item, { name: "useDiarization", label: txt('splitBySpeakers'), className: clsx(styles.radioGroup, styles.label), children: _jsx(Switch, { size: "small" }) }), _jsx(Form.Item, { name: "forbidVAD", label: txt('recordingQuality'), valuePropName: "checked", className: clsx(styles.formCheckbox, styles.label), children: _jsx(Checkbox, { children: txt('noisyRecording') }) }), _jsx(Collapse, { className: styles.collapse, ghost: true, bordered: false, accordion: true, items: collapseItems })] }), _jsx("div", { children: isLoadingUrls ? (_jsxs("div", { className: styles.loadingIndicator, children: [_jsx(Spin, { indicator: _jsx(LoadingOutlined, {}) }), _jsx("span", { children: txt('linkDownload') })] })) : (_jsx(ModalFooter, { id: "action-initiate-transcription", onCancel: onClose, disabled: isLoadingUrls, className: styles.footer, children: _jsxs("div", { children: [_jsxs("span", { children: [txt('transcribe'), ' '] }), (_r = fragileTxt(`in-${language.lang}`)) !== null && _r !== void 0 ? _r : ''] }) })) })] }));
};
export default LoadedContent;
