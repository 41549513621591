import { Login, createBaseUserFromApi, } from 'api/model/user';
import { apiFetch } from '@newtontechnologies/beey-api-js-client/apilib';
import { defaultListing, json, hasErrorMessage, } from '@newtontechnologies/beey-api-js-client/receivers';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { BeeyLocale } from 'libs/locales/locale';
import { defaultUserSettings } from './settings/user-settings';
export const fetchLoginInformation = async (connection) => connection.authFetch()
    .url(apiV2.currentUser.url())
    .receive(json().map(Login.fromAPI))
    .fetch();
export const loginFetch = (onVersionMismatch) => apiFetch()
    .version(VERSION)
    .url(apiV2.currentUser.url())
    .receive(json()
    .map(Login.fromAPI)
    .catchHttpError((response) => {
    switch (response.status) {
        case 401:
            return 'unauthorized';
        case 403:
            return 'forbidden';
        case 412:
            return 'precondition-failed';
        case 422:
            return 'unprocessable-entity';
        default:
            return 'unexpected';
    }
}))
    .tap((response) => {
    if (response.versionMatch !== 'perfect') {
        onVersionMismatch(response.versionMatch);
        throw new Error('Version mismatch');
    }
});
export const loginByAuthString = (authString, onVersionMismatch) => loginFetch(onVersionMismatch)
    .auth(authString)
    .url(apiV2.currentUser.url())
    .fetchStrict();
export const loginByCredentials = (email, password, dataProtectionConsent, onVersionMismatch) => loginFetch(onVersionMismatch)
    .postJson({
    Email: email,
    Password: password,
    DataProtectionConsent: dataProtectionConsent,
})
    .url(apiV2.login.url())
    .fetchStrict();
export const signupByCredentials = async (email, password, uiLocale, userOrigin, userField) => {
    const settings = Object.assign(Object.assign({}, defaultUserSettings), { startBeeyTutorial: true });
    return apiFetch()
        .version(VERSION)
        .url(apiV2.registerAndLogin.url())
        .receive(json().map(Login.fromAPI))
        .postJson({
        Email: email,
        Password: password,
        Language: uiLocale.code,
        Settings: settings,
        Metadata: [
            { Key: 'userOrigin', Value: userOrigin },
            { Key: 'userField', Value: userField },
        ],
    })
        .fetch();
};
export const upgradeCurrentUser = (upgradeToken) => apiFetch()
    .url(apiV2.currentUser.activateAccount.url({ upgradeToken }))
    .post()
    .receive(json()
    .map((apiUser) => (Object.assign(Object.assign({}, apiUser), createBaseUserFromApi(apiUser))))
    .catchHttpError((response) => (response.status === 412 ? 'invalid-token' : 'unexpected')))
    .fetchStrict();
export const sendActivationEmail = async (connection) => connection.authFetch()
    .url(apiV2.currentUser.sendActivationEmail.url())
    .post()
    .send();
export const storeUserUiLocale = (connection, locale) => connection.authFetch()
    .url(apiV2.currentUser.setLanguage.url())
    .postJson({ Language: locale.code })
    .send();
export const logoutUser = (connection) => connection.authFetch()
    .url(apiV2.logout.url())
    .receive(json())
    .post()
    .fetch();
export const resetPasswordRequest = async (email) => apiFetch()
    .url(apiV2.resetPasswordRequest.url())
    .postJson({ Email: email })
    .send();
export const resetPassword = async (password, resetToken) => apiFetch()
    .url(apiV2.currentUser.resetPassword.url())
    .postJson({
    ResetToken: resetToken,
    Password: password,
})
    .send();
export const storeUserSettings = async (connection, settings) => connection.authFetch()
    .url(apiV2.currentUser.settings.url())
    .postJson(settings)
    .send();
export const fetchStripeCustomerPortal = async (connection, returnUrl, landing, subscriptionId, subscriptionItemId, subscribeToPriceId) => connection.authFetch()
    .url(apiV2.currentUser.stripeCustomerPortal.url({
    returnUrl, landing, subscriptionId, subscriptionItemId, subscribeToPriceId,
}))
    .receive(json({ camelcaseResponse: false }))
    .fetch();
export const fetchCurrentUserTranscriptionReport = async (connection, listing) => connection.authFetch()
    .url(apiV2.currentUser.aggregatedTranscriptionLog.url(Object.assign({}, listing !== null && listing !== void 0 ? listing : defaultListing)))
    .receive(json())
    .fetch();
export const fetchTranscriptionLog = async (connection) => connection.authFetch()
    .receive(json())
    .url(apiV2.currentUser.transcriptionLog.url())
    .fetch();
export const changeOwnPassword = async (connection, oldPass, newPass) => connection.authFetch()
    .url(apiV2.currentUser.changePassword.url())
    .postJson({ Password: oldPass, NewPassword: newPass })
    .receive(json()
    .catchHttpError((response, errorPayload) => (response.status === 422 && hasErrorMessage(errorPayload, 'Wrong password')
    ? 'wrong-password'
    : 'unexpected')))
    .fetchStrict();
export const fetchPasswordSettings = async () => apiFetch()
    .url(apiV2.passwordSettings.url())
    .receive(json())
    .fetch();
export const collectTranscriptionOptions = (profiles) => {
    const result = [];
    for (let i = 0; i < profiles.length; i += 1) {
        const profile = profiles[i];
        const locale = BeeyLocale.fromCode(profile.lang);
        if (locale === null) {
            continue;
        }
        const existing = result.find((opt) => opt.locale.equals(locale));
        if (existing === undefined) {
            result.push({
                locale,
                profiles: [profile],
            });
        }
        else {
            existing.profiles.push(profile);
        }
    }
    return result;
};
export const fetchCurrentUserTranscriptionOptions = async (connection) => connection.authFetch()
    .url(apiV2.currentUser.transcriptionProfiles.url())
    .receive(json().map((profiles) => collectTranscriptionOptions(profiles)))
    .fetch();
export const fetchApiToken = async (connection) => connection.authFetch()
    .url(apiV2.apiToken.url())
    .receive(json())
    .fetch();
export const renewToken = async (connection, oldtoken) => connection.authFetch()
    .url(apiV2.apiToken.url())
    .postJson({ OldApiToken: oldtoken })
    .receive(json())
    .fetch();
