import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DashboardPage from 'screens/DashboardScreen/components/DashboardPage';
import { txt } from 'libs/i18n';
import { Alert, Card, Flex, Space, } from 'antd';
import { useSession } from 'components/Authenticated';
import { TagOutlined } from '@ant-design/icons';
import { SectionTagIcon } from 'components/CustomIcons';
import { Link } from 'react-router-dom';
import { fetchCurrentTeamTags } from 'api/team-api';
import { useState } from 'react';
import styles from './style.module.less';
import TagsAdministration from './TagsAdministration';
const AlertMessage = () => {
    const message = (_jsxs("span", { children: [txt('featureNotEnabled'), ' ', _jsx(Link, { to: "/account/preferences", children: txt('yourAccountSettings') })] }));
    return (_jsx(Alert, { message: message, type: "info", showIcon: true }));
};
const TagsPage = () => {
    const { session } = useSession();
    const { teamSettings: { sectionTags, sectionTagsAllowed } } = session;
    const [teamTags, setTeamTags] = useState('loading');
    const fetchTags = async (pageSize, currentPage) => {
        const fetchedTags = await fetchCurrentTeamTags(session.connection, pageSize * (currentPage - 1), pageSize);
        const tagsList = fetchedTags.list.map((tag) => ({
            title: tag.key,
            key: tag.tagId.toString(),
            color: tag.color,
            abbreviation: tag.abbreviation,
            isPinned: tag.isPinned,
        }));
        setTeamTags(Object.assign(Object.assign({}, fetchedTags), { list: tagsList }));
    };
    return (_jsx(DashboardPage, { title: txt('tags'), children: ({ contentHeight }) => (_jsxs(Card, { bordered: false, children: [_jsx("p", { children: txt('tagsInstructions') }), _jsxs(Flex, { wrap: "wrap", gap: "large", children: [_jsxs("div", { className: styles.column, children: [_jsxs(Space, { align: "baseline", children: [_jsx(TagOutlined, { className: styles.icon }), _jsx("h3", { className: styles.title, children: txt('projectTags') })] }), _jsx("p", { children: txt('projectTagsDescription') }), _jsx(TagsAdministration, { contentHeight: contentHeight, tagsType: "project", tags: teamTags !== 'loading' ? teamTags.list : 'loading', totalCount: teamTags !== 'loading' ? teamTags.totalCount : 0, onUpdateTags: fetchTags })] }), _jsxs("div", { className: styles.column, children: [_jsxs(Space, { align: "baseline", children: [_jsx(SectionTagIcon, { className: styles.icon }), _jsx("h3", { className: styles.title, children: txt('sectionTags') })] }), _jsx("p", { children: txt('sectionTagsDescription') }), sectionTagsAllowed ? (_jsx(TagsAdministration, { contentHeight: contentHeight, tagsType: "section", tags: sectionTags })) : (_jsx(AlertMessage, {}))] })] })] })) }));
};
export default TagsPage;
