import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, Form, Rate, Input, Tag, Alert, } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormModal from 'components/FormModal';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import { useState } from 'react';
import { addProjectRating } from 'api/project-api';
import styles from './style.module.less';
const { TextArea } = Input;
const ProjectRatingModal = ({ onClose, visible, projectId, onSubmit, }) => {
    const [score, setScore] = useState(3);
    const [selectedTags, setSelectedTags] = useState([]);
    const [form] = useForm();
    const { session } = useSession();
    const scoreDescription = ['scoreTerrible', 'scoreBad', 'scoreAverage', 'scoreGood', 'scorePerfect'];
    const problems = ['recognition', 'diarization', 'services', 'other'];
    const handleSubmit = async (values) => {
        await addProjectRating(session.connection, projectId, values);
        onSubmit(values);
        form.resetFields();
    };
    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((tagName) => tagName !== tag);
        setSelectedTags(nextSelectedTags);
        // NOTE: AntD does not allow tags as form items so we need to set it manually.
        form.setFieldValue('problems', nextSelectedTags);
    };
    return (_jsx(FormModal, { open: visible, destroyOnClose: true, closable: false, onCancel: onClose, title: txt('howSatisfied'), footer: (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: onClose, children: txt('cancel') }), _jsx(Button, { type: "primary", htmlType: "submit", onClick: () => form.submit(), children: _jsx("span", { children: txt('send') }) })] })), children: _jsxs(Form, { form: form, onFinish: handleSubmit, layout: "vertical", initialValues: { score: 3, problems: [], description: '' }, children: [_jsx(Form.Item, { children: _jsxs(Flex, { vertical: true, align: "center", children: [_jsx(Form.Item, { name: "score", className: styles.score, children: _jsx(Rate, { allowClear: false, onChange: setScore, value: score }) }), _jsx("span", { children: txt(scoreDescription[score - 1]) }), score !== 3 && (_jsx(Alert, { className: styles.alert, type: "info", message: score > 3 ? (_jsx("span", { children: txt('thankYou') }))
                                    : (_jsxs(_Fragment, { children: [_jsxs("span", { children: [txt('weAreSorry'), ' '] }), _jsx("a", { href: txt('tutorialsLink'), target: "_blank", rel: "noreferrer", children: txt('howToUse') })] })) }))] }) }), _jsx(Form.Item, { name: "problems", label: txt('whereToImprove'), children: _jsx("div", { children: problems.map((problem) => (_jsx(Tag.CheckableTag, { checked: selectedTags.includes(problem), onChange: (checked) => handleChange(problem, checked), children: txt(problem) }, problem))) }) }), _jsx(Form.Item, { name: "description", label: txt('yourIdeas'), children: _jsx(TextArea, {}) })] }) }));
};
export default ProjectRatingModal;
