import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import FormModal from 'components/FormModal';
import { Button, Form } from 'antd';
import { txt } from 'libs/i18n';
import './style.less';
// Generic types in arrow functions need to extend something,
// otherwise the syntax is ambiguous. Therefore we need <T extends Object>
const AddEditModal = ({ action, visible, title, initialValues, onCancel, onAdd, onEdit, children, labelColSpan = undefined, wrapperColSpan = undefined, }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (visible && initialValues !== null) {
            form.setFieldsValue(initialValues);
        }
    }, [visible]);
    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };
    const handleSubmit = (formValues) => {
        if (action === 'add') {
            onAdd(formValues);
        }
        else {
            onEdit(formValues);
        }
        onCloseModal();
    };
    return (_jsx(FormModal, { className: "add-edit-modal", title: title, open: visible, onCancel: onCloseModal, footer: false, children: _jsxs(Form, { form: form, onFinish: handleSubmit, requiredMark: false, labelCol: { span: labelColSpan }, wrapperCol: { span: wrapperColSpan }, labelAlign: "left", labelWrap: true, children: [children, _jsx(Button, { className: "add-edit-modal__saveButton", type: "primary", htmlType: "submit", children: action === 'add' ? txt('add') : txt('save') })] }) }));
};
export default AddEditModal;
