import sanitizeFilename from 'sanitize-filename';
import * as TeamAPI from 'api/team-api';
import { exportTeamMembers } from 'api/admin/teams-api';
import { exportProjects } from 'api/project-api';
const downloadUrl = (url, filename) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = sanitizeFilename(filename);
    // FIXME: @baryska: if download fails, this does not throw an exception
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
const downloadBlobAsFile = (filename, blob) => {
    const url = window.URL.createObjectURL(blob);
    downloadUrl(url, filename);
};
export const handleMembersProjectsExport = async (id, email, filterDates, connection) => {
    const memberProjectsExport = await TeamAPI
        .exportMemberTranscriptionLog(connection, id, filterDates);
    downloadBlobAsFile(`projects_${email}.csv`, memberProjectsExport);
};
export const handleTeamMembersExport = async (connection, teamID, dates, orderBy, ascending, email) => {
    const membersExport = await exportTeamMembers(connection, teamID, dates, orderBy, ascending);
    downloadBlobAsFile(`team_${email}.csv`, membersExport);
};
export const handleProjectsViewExport = async (connection, query) => {
    const projectsExport = await exportProjects(connection, query);
    downloadBlobAsFile('projects.csv', projectsExport);
};
