import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { App, Radio } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { addUserClaims, removeUserClaims, resetUserClaims, } from 'api/admin/users-api';
import { txt } from 'libs/i18n';
import './style.less';
const Claim = ({ userid, claimAPIName, claimTitle, claimSet, }) => {
    var _a, _b;
    const { session } = useSession();
    const { message } = App.useApp();
    const isClaimActive = claimSet === null || claimSet === void 0 ? void 0 : claimSet.activeClaims.includes(claimAPIName);
    const isClaimAdded = (_a = claimSet === null || claimSet === void 0 ? void 0 : claimSet.addedClaims.includes(claimAPIName)) !== null && _a !== void 0 ? _a : false;
    const isClaimRemoved = (_b = claimSet === null || claimSet === void 0 ? void 0 : claimSet.removedClaims.includes(claimAPIName)) !== null && _b !== void 0 ? _b : false;
    const radioValue = () => {
        if (isClaimAdded) {
            return 'Enabled';
        }
        if (isClaimRemoved) {
            return 'Disabled';
        }
        return 'Default';
    };
    const [claimValue, setClaimValue] = useState({
        current: isClaimActive,
        radioValue: radioValue(),
    });
    const currentClaimChange = (receivedClaimSet) => {
        const claimActive = receivedClaimSet.activeClaims.includes(claimAPIName);
        setClaimValue(Object.assign(Object.assign({}, claimValue), { current: claimActive }));
    };
    const handleClaimChange = async ({ target }) => {
        try {
            if (target.value === 'Enabled') {
                const receivedClaimSet = await addUserClaims(session.connection, userid, [claimAPIName]);
                currentClaimChange(receivedClaimSet);
            }
            else if (target.value === 'Default') {
                const receivedClaimSet = await resetUserClaims(session.connection, userid, [claimAPIName]);
                currentClaimChange(receivedClaimSet);
            }
            else if (target.value === 'Disabled') {
                const receivedClaimSet = await removeUserClaims(session.connection, userid, [claimAPIName]);
                currentClaimChange(receivedClaimSet);
            }
        }
        catch (error) {
            void message.error(txt('failedToChangeClaim'));
            global.logger.error('Could not change claim', {}, error);
        }
    };
    return (_jsxs("div", { className: "claim-row", children: [_jsx("span", { className: "claim-row__label", children: claimTitle }), _jsxs("div", { className: "claim-row__controls", children: [claimValue.current === true
                        ? _jsx(CheckCircleOutlined, { style: { color: '#52C41A' } })
                        : _jsx(CloseCircleOutlined, { style: { color: '#ff0000' } }), _jsxs(Radio.Group, { onChange: handleClaimChange, defaultValue: claimValue.radioValue, size: "small", children: [_jsx(Radio.Button, { value: "Enabled", children: txt('claimEnabled') }), _jsx(Radio.Button, { value: "Default", children: txt('claimDefault') }), _jsx(Radio.Button, { value: "Disabled", children: txt('claimDisabled') })] })] })] }));
};
export default Claim;
