import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, } from 'react';
import { deriveTagStyle } from 'libs/tag-style';
import { Button, Tag, Form, Checkbox, } from 'antd';
import './style.less';
import { txt } from 'libs/i18n';
const TagsFilter = ({ onTagsFiltered, onTagsCanceled, selectedTags, onDropdownVisibility, teamTags, }) => {
    const [filteredTags, setFilteredTags] = useState([]);
    const [form] = Form.useForm();
    useEffect(() => {
        if (selectedTags.length === 0) {
            setFilteredTags([]);
            form.setFieldsValue({ tags: [], allTags: false });
        }
    }, [selectedTags]);
    const handleCheckAll = (e) => {
        if (e.target.checked) {
            form.setFieldsValue({ tags: teamTags.map((tag) => tag.key) });
            setFilteredTags(teamTags.map((tag) => tag.key));
        }
        else {
            form.setFieldsValue({ tags: [] });
            setFilteredTags([]);
        }
    };
    const handleFilterTags = () => {
        onTagsFiltered(filteredTags);
        onDropdownVisibility(false);
    };
    const handleClearFilter = () => {
        onTagsCanceled();
        onDropdownVisibility(false);
        form.setFieldsValue({ tags: [], allTags: false });
    };
    return (_jsx("div", { className: "saved-tags", children: _jsxs("div", { className: "saved-tags__list", children: [_jsxs(Form, { form: form, initialValues: {
                        tags: selectedTags,
                        allTags: false,
                    }, children: [_jsx(Form.Item, { name: "allTags", valuePropName: "checked", children: _jsx(Checkbox, { className: "saved-tags__all", onChange: handleCheckAll, children: txt('checkAll') }) }), _jsx(Form.Item, { name: "tags", children: _jsx(Checkbox.Group, { className: "saved-tags__checkboxes", onChange: (tags) => {
                                    setFilteredTags([...filteredTags, ...tags]);
                                }, children: teamTags.map((tag) => (_jsx(Checkbox, { value: tag.key, children: _jsx(Tag, { className: "beeyTag", style: deriveTagStyle(tag.key, tag.color), children: tag.key }) }, tag.tagId))) }) })] }), _jsxs("div", { className: "saved-tags__buttons", children: [_jsx(Button, { size: "small", type: "link", onClick: handleClearFilter, children: txt('clearFilter') }), _jsx(Button, { type: "primary", size: "small", onClick: handleFilterTags, children: txt('filter') })] })] }) }));
};
export default TagsFilter;
