import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SearchOutlined, RedoOutlined, UndoOutlined, UpOutlined, DownOutlined, CopyOutlined, UserAddOutlined, ArrowsAltOutlined, } from '@ant-design/icons';
import { App, Divider, Dropdown } from 'antd';
import { txt } from 'libs/i18n';
import { EraseIcon, PlaybackBindIcon, HeadingOneIcon, HeadingTwoIcon, BackwardIcon, ForwardIcon, SpeakerColorIcon, KeyIcon, CapitalizeIcon, } from 'components/CustomIcons';
import { useSession } from 'components/Authenticated';
import * as clientEnv from 'libs/client-env';
import { useState } from 'react';
import { useSubModeTutorial } from '../../subModeTutorial';
import CustomizedButton from './CustomizedButton';
import EditorWidthSelector from './EditorWidthSelector';
import './style.less';
const DocumentToolbar = ({ editorController, triggerFindAndReplace, onToggleToolbar, isToolbarHidden, isCaptionMode, onOpenSpeakerColorDialog, position, toggleKeywordPreview, onEditorWidthSelected, readOnly, }) => {
    const { tutorialGoNext, retrieveTutorialState } = useSubModeTutorial();
    const { session } = useSession();
    const { message } = App.useApp();
    const [widthPopoverVisible, setWidthPopoverVisible] = useState(false);
    const insertHeading1 = () => editorController.insertHeading1();
    const insertSection = () => editorController.sections.insertSection();
    const clearFormatting = () => editorController.clearFormating();
    const insertSpeaker = () => editorController.selectSpeakerFromToolbar();
    const undoChanges = () => editorController.getHistory().undo();
    const redoChanges = () => editorController.getHistory().redo();
    const insertTimeAnchor = () => editorController.timeAnchors
        .insertTimeAnchor(editorController.playback.time);
    const rewindTimeAnchor = () => editorController.timeAnchors.forceTimestampAndAdjust(-0.1);
    const forwardTimeAnchor = () => editorController.timeAnchors.forceTimestampAndAdjust(+0.1);
    const insertCaptionEnd = () => editorController.insertCaptionEnd();
    const copyDocumentToClipboard = () => {
        editorController.copyDocumentToClipboard();
        void message.success(txt('copied'));
    };
    const blockContextMenu = (event) => {
        event.stopPropagation();
    };
    if (isToolbarHidden) {
        return (_jsxs("div", { className: "document-toolbar document-toolbar--hidden", onContextMenu: blockContextMenu, children: [_jsx("div", { className: "document-toolbar__content" }), _jsx(CustomizedButton, { tooltipTitle: "showToolbar", tooltipShortcut: "", onClick: onToggleToolbar, tooltipPlacement: "left", children: _jsx(DownOutlined, {}) })] }));
    }
    const readOnlyTooltip = readOnly ? 'readOnlyProject' : undefined;
    const capitalizationItems = [
        {
            key: '1',
            label: txt('lowerCaseText'),
        },
        {
            key: '2',
            label: txt('titleCaseText'),
        },
        {
            key: '3',
            label: txt('upperCaseText'),
        },
    ];
    const onCapitalizationClick = ({ key }) => {
        if (key === '1') {
            editorController.keyboardHandler.capitalizeText('lowerCase');
        }
        else if (key === '2') {
            editorController.keyboardHandler.capitalizeText('titleCase');
        }
        else if (key === '3') {
            editorController.keyboardHandler.capitalizeText('upperCase');
        }
    };
    return (_jsxs("div", { style: position, className: "document-toolbar", onContextMenu: blockContextMenu, children: [_jsxs("div", { className: "document-toolbar__content", children: [_jsxs("div", { className: "document-toolbar__section", children: [_jsx(CustomizedButton, { tooltipTitle: "assignSpeaker", disabledMessage: readOnlyTooltip, tooltipShortcut: "Shift+Enter", onClick: insertSpeaker, children: _jsx(UserAddOutlined, {}) }), _jsx(CustomizedButton, { tooltipTitle: "headingOne", disabledMessage: readOnlyTooltip, tooltipShortcut: "Ctrl+1", onClick: insertHeading1, children: _jsx(HeadingOneIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "headingTwo", disabledMessage: readOnlyTooltip, tooltipShortcut: "Ctrl+2", onClick: insertSection, children: _jsx(HeadingTwoIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "capitals", disabledMessage: readOnlyTooltip, tooltipShortcut: "Ctrl+G", onClick: () => undefined, children: _jsx(Dropdown, { className: "document-toolbar__dropdown-capitals", menu: { items: capitalizationItems, onClick: onCapitalizationClick }, trigger: ['hover', 'click'], children: _jsx("div", { children: _jsx(CapitalizeIcon, { className: "document-toolbar__icon document-toolbar__icon-capitals" }) }) }) }), _jsx(CustomizedButton, { tooltipTitle: "clearFormat", disabledMessage: readOnlyTooltip, tooltipShortcut: "", onClick: clearFormatting, children: _jsx(EraseIcon, { className: "document-toolbar__icon" }) }), isCaptionMode
                                ? (_jsx(CustomizedButton, { tooltipTitle: "colorSpeakers", disabledMessage: readOnlyTooltip, tooltipShortcut: "", onClick: onOpenSpeakerColorDialog, children: _jsx(SpeakerColorIcon, { className: "document-toolbar__icon" }) })) : null] }), _jsx(Divider, { type: "vertical" }), _jsxs("div", { className: "document-toolbar__section", children: [isCaptionMode
                                ? (_jsx(CustomizedButton, { tooltipTitle: "insertSubtitleEnd", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('bKey')}`, onClick: () => {
                                        insertCaptionEnd();
                                        tutorialGoNext();
                                    }, children: "\u25C6" }))
                                : null, _jsx(CustomizedButton, { tooltipTitle: "bindWordToPlayback", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('qKey')}`, onClick: insertTimeAnchor, children: _jsx(PlaybackBindIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "startWordSooner", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('openBracketKey')}`, onClick: rewindTimeAnchor, children: _jsx(ForwardIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "startWordLater", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('closeBracketKey')}`, onClick: forwardTimeAnchor, children: _jsx(BackwardIcon, { className: "document-toolbar__icon" }) })] }), _jsx(Divider, { type: "vertical" }), _jsxs("div", { className: "document-toolbar__section", children: [_jsx(CustomizedButton, { tooltipTitle: "copy", tooltipShortcut: "Ctrl+A Ctrl+C", onClick: copyDocumentToClipboard, children: _jsx(CopyOutlined, {}) }), _jsx(CustomizedButton, { tooltipTitle: "ctrlZ", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('zKey')}`, onClick: undoChanges, children: _jsx(UndoOutlined, {}) }), _jsx(CustomizedButton, { tooltipTitle: "ctrlY", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('yKey')}`, onClick: redoChanges, children: _jsx(RedoOutlined, {}) })] }), _jsx(Divider, { type: "vertical" }), _jsxs("div", { className: "document-toolbar__section", children: [session.login.hasClaim('keywords:enabled') && clientEnv.getShowKeywords() === true
                                ? (_jsx(CustomizedButton, { tooltipTitle: "keywords", tooltipShortcut: "", onClick: toggleKeywordPreview, children: _jsx(KeyIcon, { className: "document-toolbar__icon" }) })) : null, _jsx(CustomizedButton, { tooltipTitle: "search", tooltipShortcut: "", onClick: () => triggerFindAndReplace('open-find'), children: _jsx(SearchOutlined, {}) })] }), _jsx(Divider, { type: "vertical" }), _jsx(EditorWidthSelector, { onEditorWidthSelected: onEditorWidthSelected, popoverVisible: widthPopoverVisible, onPopoverVisible: (visible) => setWidthPopoverVisible(visible), children: _jsx(CustomizedButton, { tooltipTitle: "editorWidthTitle", tooltipShortcut: "", onClick: () => setWidthPopoverVisible(!widthPopoverVisible), className: "editor-width-selector", children: _jsx(ArrowsAltOutlined, { rotate: (45) }) }) })] }), _jsx(CustomizedButton, { tooltipTitle: "hideToolbar", tooltipShortcut: "", onClick: onToggleToolbar, disabledMessage: retrieveTutorialState() === 'running' ? 'disabledDuringTutorial' : undefined, tooltipPlacement: "left", children: _jsx(UpOutlined, {}) })] }));
};
export default DocumentToolbar;
