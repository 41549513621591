import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import * as clientEnv from 'libs/client-env';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { txt } from 'libs/i18n';
import TutorialModal from 'components/Tutorial/TutorialModal';
import { useHistory } from 'react-router';
import tutorialModalstyles from 'components/Tutorial/TutorialModal/style.module.less';
import tadaEmoji from 'components/Tutorial/TutorialModal/img/tada.png';
import { getRectFromElement } from 'libs/positions';
import { useSession } from 'components/Authenticated';
import { App } from 'antd';
import { useSubModeTutorial } from '../subModeTutorial';
import MediaPlayer from '../MediaPlayer';
import DocumentEditor from '../DocumentEditor';
import { usePlaybackContext } from '../WithMediaPlayback';
import './style.less';
import { editorPositions } from './editor-positions';
import useResizeObserver from '../MediaPlayer/use-resize-observer';
const EditorContent = ({ mediaSource, project, secondaryProject, onProjectUpdated, editorController, secondaryEditorController, mediaProcessingStatus, messageStream, projectErrorStatus = 'no-error', projectQueuePosition = null, onTranscriptionLoadFailed, isCaptionMode, onLeaveEditorRequested, enqueueProjectUpdate, }) => {
    const { playerState, playback } = usePlaybackContext();
    const [editorWidth, setEditorWidth] = useState(clientEnv.getEditorWidth());
    const editorPositionReference = useRef(null);
    const [editorBounds, setEditorBounds] = useState(null);
    const beeyTutorialContext = useBeeyTutorial();
    const subModeTutorialContext = useSubModeTutorial();
    const history = useHistory();
    const { session } = useSession();
    const { notification } = App.useApp();
    useResizeObserver({
        callback: () => {
            if (editorPositionReference.current !== null) {
                setEditorBounds(getRectFromElement(editorPositionReference.current));
            }
        },
        elementRef: editorPositionReference,
    });
    const { resolution } = playback;
    let aspectRatio = resolution && resolution.width / resolution.height;
    if (aspectRatio === null && mediaSource.remoteHasVideo) {
        // FIXME @chocoman: this is a dirty workaround of a bug that
        // video was not showing in streams.
        aspectRatio = 16 / 9;
    }
    const positions = editorPositions(editorBounds, aspectRatio, editorWidth, playerState, secondaryEditorController !== null);
    const editorContentVideo = () => {
        if (playerState.video === 'no-video') {
            return ' no-video';
        }
        if (!playerState.video.isVisible) {
            return ' no-video';
        }
        if (!playerState.video.isMaximized) {
            return ' video-min';
        }
        return '';
    };
    const changeEditorWidth = (editorWidthSelected) => {
        setEditorWidth(editorWidthSelected);
    };
    const finishTutorial = () => {
        beeyTutorialContext.tutorialLastStep();
        history.push('/projects');
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: editorPositionReference, className: "position-reference sub-mode-tutorial-step-split" }), _jsxs("div", { className: `editor__content ${editorContentVideo()}`, onDrop: (e) => {
                    if (e.dataTransfer.files.length !== 0) {
                        // prevent dragging files such as images to editor, but allow plain text
                        e.preventDefault();
                    }
                }, children: [_jsxs(TutorialModal, { skipTutorial: beeyTutorialContext.tutorialLastStep, tutorialAction: finishTutorial, tutorialActionLabel: txt('beeyTutorialFinishButton'), skipTutorialLabel: txt('beeyTutorialTranscribeLater'), visible: beeyTutorialContext.currentStep === 7, children: [_jsx("h2", { children: txt('beeyTutorialFinalHeading') }), _jsx("p", { children: txt('beeyTutorialFinalStep') }), _jsx("img", { src: tadaEmoji, className: tutorialModalstyles.image, alt: "tada emoji" })] }), _jsxs(TutorialModal, { className: "last-tutorial-tooltip", skipTutorial: subModeTutorialContext.tutorialLastStep, tutorialAction: subModeTutorialContext.tutorialFinish, tutorialActionLabel: txt('submodeFinishButton'), skipTutorialLabel: txt('tutorialFinish'), visible: subModeTutorialContext.currentStep === 7, children: [_jsx("h2", { children: txt('beeyTutorialFinalHeading') }), _jsx("p", { className: tutorialModalstyles.text, children: txt('subModeTutorialFinalStep') }), _jsx("p", { className: "tutorial__tip", children: txt('subModeTutorialFinalTip') })] }), _jsx(MediaPlayer, { position: positions.player, className: `editor__content__video ${editorWidth}`, connection: session.connection, project: project, forceVideoCanvas: isCaptionMode, language: editorController.getLanguage() }), secondaryEditorController !== null && secondaryProject !== null
                        && (_jsx(DocumentEditor, { position: positions.secondaryDocument, className: `secondary-editor editor__content__document ${editorWidth}`, project: secondaryProject, onProjectUpdated: async () => { }, editorController: secondaryEditorController, mediaProcessingStatus: "completed", messageStream: messageStream, projectErrorStatus: "no-error", projectQueuePosition: null, isPlayerReady: playerState.status === 'ready', onTranscriptionLoadFailed: () => { }, isCaptionMode: false, tutorialContinue: () => { }, tutorialGoNext: async () => { }, tutorialCurrentStep: 0, retrieveTutorialState: () => '', onLeaveEditorRequested: () => { }, session: session, onEditorWidthSelected: () => { }, enqueueProjectUpdate: async () => { }, editorPriority: "secondary", onScroll: (position) => editorController.syncScroll(position), notification: notification })), _jsx(DocumentEditor, { position: positions.document, className: `primary-editor editor__content__document ${editorWidth}`, project: project, onProjectUpdated: onProjectUpdated, editorController: editorController, mediaProcessingStatus: mediaProcessingStatus, messageStream: messageStream, projectErrorStatus: projectErrorStatus, projectQueuePosition: projectQueuePosition, isPlayerReady: playerState.status === 'ready', onTranscriptionLoadFailed: onTranscriptionLoadFailed, isCaptionMode: isCaptionMode, tutorialContinue: beeyTutorialContext.tutorialContinue, tutorialGoNext: beeyTutorialContext.tutorialGoNext, tutorialCurrentStep: beeyTutorialContext.currentStep, retrieveTutorialState: subModeTutorialContext.retrieveTutorialState, onLeaveEditorRequested: onLeaveEditorRequested, session: session, onEditorWidthSelected: changeEditorWidth, enqueueProjectUpdate: enqueueProjectUpdate, editorPriority: "main", onScroll: (position) => (secondaryEditorController === null || secondaryEditorController === void 0 ? void 0 : secondaryEditorController.syncScroll(position)), notification: notification })] })] }));
};
export default EditorContent;
