import { s } from './sanitize';
export const subtitlesDefaults = Object.freeze({
    maxLineLength: 60,
    speedWarning: 22,
    speedCriticalWarning: 25,
    automaticSpeed: 20,
    minDuration: 2,
    maxDuration: 8,
    speakerSignPlacement: 'utteranceStartOnly',
    pauseBetweenCaptions: 0.08,
    autofillPauseBetweenCaptions: 0,
    fontName: 'Arial',
    fontSize: 22,
    backgroundColor: 'black',
    backgroundTransparency: 1,
    captionPosition: Object.freeze({ align: 'center', line: 100 }),
    speakerColor: 'white',
    upperCaseAllText: false,
    formatting: true,
});
export const SpeakerSignPlacementSchema = s.enum(['utteranceStartOnly', 'multiSpeakerCaptionsOnly', 'none'], 'utteranceStartOnly');
export const SpeakerColorSchema = s.enum(['red', 'blue', 'lime', 'yellow', 'magenta', 'cyan', 'black', 'white'], 'white');
export const SubtitlesFormatSchema = s.enum(['srt', 'srt_FB', 'vtt', 'ssa', 'stl', 'ttml'], 'srt');
export const TransparencySchema = s.object({
    opaque: s.literal(0),
    semitransparent: s.literal(0.5),
    transparent: s.literal(1),
});
export const TransparencyValuesSchema = TransparencySchema.values();
const CaptionAlignmentSchema = s.enum(['left', 'center', 'right'], 'center');
const CaptionFormatSchema = s.object({
    line: s.number(subtitlesDefaults.captionPosition.line).nullable(),
    align: CaptionAlignmentSchema.nullable(),
});
export const SubtitlesSettingsSchema = s.object({
    maxLineLength: s.number(subtitlesDefaults.maxLineLength),
    speedCriticalWarning: s.number(subtitlesDefaults.speedCriticalWarning).nullable(),
    speedWarning: s.number(subtitlesDefaults.speedWarning).nullable(),
    automaticSpeed: s.number(subtitlesDefaults.automaticSpeed).nullable(),
    pauseBetweenCaptions: s.number(subtitlesDefaults.pauseBetweenCaptions),
    autofillPauseBetweenCaptionsMs: s.number(subtitlesDefaults.autofillPauseBetweenCaptions),
    minDuration: s.number(subtitlesDefaults.minDuration),
    maxDuration: s.number(subtitlesDefaults.maxDuration),
    spaceAtLineEnd: s.boolean(false),
    subtitlerMaxLineCount: s.number(2),
    enablePresegmentation: s.boolean(false),
    speakerSignPlacement: SpeakerSignPlacementSchema,
    useSpeakerName: s.boolean(false),
    defaultColor: SpeakerColorSchema.default(subtitlesDefaults.speakerColor),
    templateName: s.string('default'),
    defaultFontName: s.string(subtitlesDefaults.fontName),
    defaultFontSize: s.number(subtitlesDefaults.fontSize),
    defaultBackgroundColor: s.string(subtitlesDefaults.backgroundColor),
    defaultBackgroundTransparency: TransparencySchema
        .values().default(subtitlesDefaults.backgroundTransparency),
    defaultCaptionPosition: CaptionFormatSchema,
    upperCaseAllText: s.boolean(false),
});
export const SubtitlesTemplateSchema = s.object({
    name: s.string('default'),
    settings: SubtitlesSettingsSchema,
    isEditable: s.boolean(false),
});
const SubtitlesExportSettingsSchema = s.object({
    maxLineLength: s.number(subtitlesDefaults.maxLineLength),
    speakerSignPlacement: SpeakerSignPlacementSchema,
    pauseBetweenCaptions: s.number(subtitlesDefaults.pauseBetweenCaptions),
    autofillPauseBetweenCaptionsMs: s.number(subtitlesDefaults.autofillPauseBetweenCaptions),
    subtitlesFormat: SubtitlesFormatSchema,
    formatting: s.boolean(subtitlesDefaults.formatting),
    upperCaseAllText: s.boolean(subtitlesDefaults.upperCaseAllText),
});
const FindAndReplaceParamsSchema = s.object({
    matchCase: s.boolean(false),
});
const FulltextSearchParamsSchema = s.object({
    strict: s.boolean(false),
});
const TranscriptionSettingsSchema = s.object({
    usePpc: s.boolean(true),
    useVad: s.boolean(true),
    useDiarization: s.boolean(true),
    recognitionLocale: s.string('').nullable().default(null),
    languageModel: s.string('default'),
});
const DocumentExportSettingsSchema = s.object({
    template: s.string('default'),
});
const NewSpeakerOptionSchema = s.enum(['useInProjectOnly', 'addToDatabase'], 'useInProjectOnly');
const DbSpeakerOptionSchema = s.enum(['updateInDatabase', 'saveAsNew', 'useInProjectOnly'], 'updateInDatabase');
const SpeakerUpdateOptionsSchema = s.object({
    saveNewSpeaker: NewSpeakerOptionSchema,
    updateDbSpeaker: DbSpeakerOptionSchema,
});
const UserSettingsSchema = s.object({
    nonBreakingSpace: s.boolean(false),
    spellCheck: s.boolean(true),
    pauseOnPedalRelease: s.boolean(false),
    syncCaret: s.boolean(true),
    caretDelay: s.number(1.2),
    isAudioDelayed: s.boolean(false),
    audioDelay: s.number(1),
    showMediaDate: s.boolean(false),
    enableTimestampLabels: s.boolean(false),
    jumpInPlayback: s.number(1),
    lastProjId: s.string('').nullable().default(null),
    userTags: s.array(s.string('')),
    betaEnabled: s.boolean(false),
    editorFontDelta: s.number(0),
    teamSubtitlesTemplate: SubtitlesTemplateSchema.nullable().default(null),
    subtitles: SubtitlesSettingsSchema,
    subtitlesExport: SubtitlesExportSettingsSchema,
    findAndReplaceParams: FindAndReplaceParamsSchema,
    fulltextSearchParams: FulltextSearchParamsSchema,
    startBeeyTutorial: s.boolean(false),
    startSubModeTutorial: s.boolean(true),
    transcriptionSettings: TranscriptionSettingsSchema,
    documentExportSettings: DocumentExportSettingsSchema,
    speakerUpdateOptions: SpeakerUpdateOptionsSchema,
});
export const defaultUserSettings = UserSettingsSchema.sanitize({});
export const sanitizeUserSettings = (data) => UserSettingsSchema.sanitize(data);
