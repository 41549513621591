import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Space, Divider, Spin, } from 'antd';
import { CheckCircleOutlined, DatabaseOutlined, HistoryOutlined, TeamOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';
import { accountClaimList } from 'api/user-claims';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import { listOrders } from 'api/orders';
import { useEffect, useState } from 'react';
import styles from './style.module.less';
import LastPayment, { separateThousandsWithDecimals } from './LastPayment';
import PlanUsage from './PlanUsage';
const TeamPlan = ({ currency, onManagePlan, isSubscribed, plan, }) => {
    const { session } = useSession();
    const [lastOrder, setLastOrder] = useState(null);
    const includedFeatures = accountClaimList
        .filter((claim) => session.login.claims.includes(claim.ApiName));
    useEffect(() => {
        const fetchOrdersHistory = async () => {
            const orders = await listOrders(session.connection);
            if (orders.list.length === 0) {
                setLastOrder(null);
            }
            else {
                setLastOrder({
                    date: orders.list[0].created,
                    amount: orders.list[0].amount,
                    status: orders.list[0].paymentStatus,
                    currency: orders.list[0].currency,
                });
            }
        };
        void fetchOrdersHistory();
    }, []);
    return (_jsxs("div", { children: [_jsxs(Flex, { justify: "space-between", gap: "large", children: [plan !== 'loading' ? (_jsxs("div", { className: styles.column, children: [_jsxs("h4", { children: [txt('usage'), ":"] }), _jsxs("div", { className: styles.feature, children: [isSubscribed ? (_jsxs(_Fragment, { children: [_jsx(PlanUsage, { icon: _jsx(HistoryOutlined, {}), total: plan.fullSubscriptionCredit, remaining: plan.remainingSubscriptionCredit, unit: "min", decimal: false }), _jsx(PlanUsage, { icon: _jsx(TeamOutlined, {}), total: plan.totalNumberOfUsers, remaining: plan.totalNumberOfUsers !== undefined && plan.currentNumberOfUsers !== undefined
                                                    ? plan.totalNumberOfUsers - plan.currentNumberOfUsers
                                                    : 0, unit: txt('fromUsers').toLowerCase(), decimal: false })] })) : null, !session.login.hasClaim('unlimitedStorage:enabled') ? (_jsx(PlanUsage, { icon: _jsx(DatabaseOutlined, {}), total: plan.totalStorage, remaining: plan.totalStorage - plan.currentStorage, unit: "GB", decimal: true })) : null] })] })) : null, _jsxs("div", { className: styles.column, children: [lastOrder !== null ? (_jsx(LastPayment, { lastOrder: lastOrder, onManagePlan: onManagePlan })) : null, isSubscribed ? (_jsxs("div", { className: styles.paymentInfo, children: [_jsxs("h4", { children: [txt('nextPayment'), ":"] }), plan !== 'loading' ? (plan.cancelAt !== null ? (_jsxs(_Fragment, { children: [_jsxs(Space, { className: styles.payment, wrap: true, children: [_jsx("span", { children: txt('willSwitch') }), _jsx("span", { children: dayjs(plan.cancelAt).format('DD/MM/YYYY') })] }), _jsx(IconButton, { onClick: () => onManagePlan(null), className: styles.iconButton, children: txt('renewPlan') })] })) : (_jsxs(_Fragment, { children: [_jsxs(Space, { className: styles.payment, wrap: true, children: [_jsx("strong", { children: plan.upcomingAmountDue !== undefined
                                                            ? separateThousandsWithDecimals(plan.upcomingAmountDue)
                                                            : '' }), _jsx("strong", { children: currency }), _jsx("span", { children: dayjs(plan.upcomingPaymentAttempt).format('DD/MM/YYYY') })] }), _jsx(IconButton, { onClick: () => onManagePlan('subscription_cancel'), className: styles.iconButton, children: txt('cancelPlan') })] }))) : _jsx(Spin, {})] })) : null] })] }), isSubscribed ? _jsx(Divider, {}) : null, _jsxs("h4", { className: styles.featuresTitle, children: [txt('includedFeatures'), ":"] }), _jsx("div", { className: styles.claimsTable, children: includedFeatures.map((claim) => {
                    const claimTitle = txt(claim.title).endsWith(':') ? txt(claim.title).slice(0, -1) : txt(claim.title);
                    return (_jsxs("div", { className: styles.claimsList, children: [_jsx("p", { className: styles.label, children: claimTitle }), _jsx(CheckCircleOutlined, { style: { color: '#52C41A' } })] }, claim.title));
                }) })] }));
};
export default TeamPlan;
