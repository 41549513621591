import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import { Badge, ColorPicker, Flex, Form, Input, Popconfirm, Switch, Tag, Tooltip, } from 'antd';
import { deriveTagColor, deriveTagStyle } from 'libs/tag-style';
import { txt } from 'libs/i18n';
import { useState } from 'react';
import clsx from 'clsx';
import FormModal from 'components/FormModal';
import { addCurrentTeamTag } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import styles from './style.module.less';
const YetAnotherTagComponent = ({ tag, allTags, onTagDelete, onTagSave, }) => {
    var _a, _b;
    const [popConfirmOpen, setPopconfirmOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();
    const { session } = useSession();
    const confirmDelete = (_jsx("div", { children: _jsxs("p", { children: [_jsxs("strong", { children: [txt('deleteTag'), ' ', "?"] }), _jsx("br", {}), txt('deleteTagPermanently'), _jsx("br", {})] }) }));
    const saveTag = () => {
        setModalOpen(true);
    };
    const deleteTag = () => {
        if (onTagDelete) {
            onTagDelete(tag.title);
        }
    };
    const saveToTeamTags = async () => {
        const hex = form.getFieldValue('color');
        const title = form.getFieldValue('title');
        const abbrevitation = form.getFieldValue('abbreviation');
        await addCurrentTeamTag(session.connection, title, abbrevitation, hex, true);
        if (onTagSave) {
            await onTagSave();
        }
        setModalOpen(false);
    };
    const saveIcon = (_jsx(Tooltip, { title: txt('saveTag'), children: _jsx(PushpinOutlined, { onClick: saveTag, className: styles.saveIcon }) }));
    const deleteIcon = (_jsx(Popconfirm, { title: confirmDelete, overlayClassName: "tag-popover", okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: deleteTag, cancelText: txt('cancel'), onOpenChange: () => setPopconfirmOpen(!popConfirmOpen), children: _jsx(Tooltip, { title: txt('deleteTag'), children: _jsx(CloseCircleOutlined, { className: styles.deleteIcon, onClick: () => setPopconfirmOpen(!popConfirmOpen) }) }) }));
    const editIcon = (_jsxs(Flex, { children: [saveIcon, deleteIcon] }));
    const badgeIcon = () => {
        if (!tag.showFullVersion) {
            return '';
        }
        return editIcon;
    };
    const tagAbbreviation = tag.abbreviation !== null ? tag.abbreviation : tag.title.slice(0, 2);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: tag.showFullVersion ? false : tag.title, children: _jsx(Badge, { count: badgeIcon(), offset: [-15, 0], children: _jsx(Tag, { className: clsx('beeyTag', { 'short-tag': !tag.showFullVersion }), style: deriveTagStyle(tag.title, tag.color, tag.isForeign), children: tag.showFullVersion ? tag.title : tagAbbreviation }) }) }), _jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], open: modalOpen, onCancel: () => setModalOpen(false), onOk: saveToTeamTags, className: styles.modal, title: txt('editTag'), children: _jsx(Form, { form: form, initialValues: {
                        title: tag.title,
                        abbreviation: (_a = tag.abbreviation) !== null && _a !== void 0 ? _a : tag.title.substring(0, 2),
                        color: (_b = tag.color) !== null && _b !== void 0 ? _b : deriveTagColor(tag.title),
                        isPinned: tag.isPinned,
                    }, labelCol: { span: 10 }, wrapperCol: { span: 14 }, children: _jsxs(Flex, { vertical: true, children: [_jsx(Form.Item, { name: "title", label: txt('name'), rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: txt('noTagName'),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (tag.title === value) {
                                                return Promise.resolve();
                                            }
                                            const duplicitTag = allTags === null || allTags === void 0 ? void 0 : allTags.find((tg) => tg.key.trim() === value.trim());
                                            if (duplicitTag !== undefined) {
                                                return Promise.reject(txt('duplicitTag'));
                                            }
                                            if (value.trim().startsWith('#')) {
                                                return Promise.reject(txt('startsWithHash'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "abbreviation", label: txt('abbreviation'), rules: [
                                    {
                                        max: 2,
                                        message: txt('max2chars'),
                                    },
                                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "color", label: txt('tagColor'), getValueFromEvent: (clr) => {
                                    return `#${clr.toHex()}`;
                                }, children: _jsx(ColorPicker, {}) }), _jsxs(Flex, { gap: "middle", align: "center", children: [_jsx("span", { children: txt('showAsOption') }), _jsx(Form.Item, { name: "isPinned", className: styles.switch, children: _jsx(Switch, { size: "small", defaultChecked: true }) })] })] }) }) })] }));
};
export default YetAnotherTagComponent;
