import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { CloseOutlined, FundOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import * as TeamAPI from 'api/team-api';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import DateRangeFilterDropdown from 'components/DateRangeFilterDropdown';
import IconButton from 'components/IconButton';
import dayjs from 'dayjs';
import clsx from 'clsx';
import ColumnHeader from 'components/ColumnHeader';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import TranscribedOverview, { formatDuration } from './TranscribedOverview/index';
import styles from './style.module.less';
import { ExpandedContentWithProjects } from './TeamExtendedContent';
import TeamCreditInfo from './TeamCreditInfo';
const TEAM_USERS_TABLE_ID = 'team-users-table'; // Must be unique across all tables in Beey!!
const getCreationDateRange = (from, to) => {
    const fromDate = from.slice(0, 7);
    const toDate = to.slice(0, 7);
    const fromFormatted = dayjs(fromDate).format('MM/YYYY');
    const toFormatted = dayjs(toDate).format('MM/YYYY');
    const isDateRangeWithinSameMonth = fromDate.localeCompare(toDate);
    if (isDateRangeWithinSameMonth === 0) {
        return `${fromFormatted}`;
    }
    return `${fromFormatted} - ${toFormatted}`;
};
export const getDateCellContent = (member, filterDates) => {
    if (member.from === null || member.to === null) {
        return filterDates.length !== 0 ? getCreationDateRange(filterDates[0], filterDates[1]) : '';
    }
    return `${getCreationDateRange(member.from, member.to)}`;
};
const TranscriptionInfo = ({ credit }) => {
    var _a;
    const { session } = useSession();
    const { login } = session;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [memberProjects, setMemberProjects] = useState('loading');
    const [teamTranscription, setTeamTranscription] = useState('loading');
    const [expandedRowProperties, setExpandedRowProperties] = useState({
        key: [],
        email: '',
    });
    const isTeamOwner = session.login.hasClaim('team:owner');
    const defaultSieve = {
        filterDates: [],
        sortBy: 'Email',
        sortOrder: 'ascend',
    };
    const tableView = useTableView(TEAM_USERS_TABLE_ID, {
        pageSize: 10,
        sieve: defaultSieve,
    });
    const { sortBy, sortOrder, filterDates } = (_a = tableView.getViewParams().sieve) !== null && _a !== void 0 ? _a : defaultSieve;
    const { currentPage, pageSize } = tableView.getViewParams();
    const isDefaultSetting = (sortBy === 'Email'
        && filterDates.length === 0
        && sortOrder);
    const fetchTeamTranscriptionPerPeriod = async () => {
        const fetchedTeamTranscription = await TeamAPI.fetchCurrentTeamTranscriptionPerPeriod(session.connection, filterDates, sortBy, sortOrder === 'ascend', pageSize * (currentPage - 1), pageSize);
        const members = await TeamAPI.fetchCurrentTeamMembers(session.connection, pageSize * (currentPage - 1), pageSize);
        const updatedTranscriptionLog = fetchedTeamTranscription.data.list.map((member) => (Object.assign(Object.assign({}, member), { usedStorage: members.list.filter((m) => m.id === member.id)[0].storageSharedBytes })));
        setTeamTranscription({
            totalCharged: fetchedTeamTranscription.totalCharged,
            data: Object.assign(Object.assign({}, fetchedTeamTranscription.data), { list: updatedTranscriptionLog }),
        });
    };
    const fetchMemberTranscriptionLogState = async (memberId) => {
        const memberTranscriptionLog = await TeamAPI.fetchMemberTranscriptionLog(session.connection, memberId, filterDates, 0, 0);
        setMemberProjects(memberTranscriptionLog);
    };
    const handleRowExpanded = async (expanded, member) => {
        setExpandedRowProperties({
            key: expanded ? [member.id] : [],
            email: (expanded && member.email !== null) ? member.email : '',
        });
        await fetchMemberTranscriptionLogState(member.id);
    };
    const handleFilterDatesChanged = (dates) => {
        const oldSieve = tableView.getViewParams().sieve;
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filterDates: dates }));
    };
    const handleClearSieve = () => {
        const oldSieve = tableView.getViewParams().sieve;
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filterDates: [], sortBy: 'Email', sortOrder: 'ascend' }));
    };
    const totalTranscribedFooter = () => {
        if (teamTranscription !== 'loading') {
            return (_jsxs("div", { className: styles.footer, children: [_jsx("span", { className: styles.total, children: filterDates.length === 0 ? txt('total') : txt('totalPerPeriod') }), _jsx("span", { children: formatDuration(teamTranscription.totalCharged) })] }));
        }
        return null;
    };
    const handleSort = (columnKey, sortingOrder) => {
        tableView.setSieve({
            filterDates,
            sortBy: columnKey,
            sortOrder: sortingOrder,
        });
    };
    const columnsOfOwner = [
        {
            title: (_jsx(ColumnHeader, { title: "teamMembers", columnKey: "Email", sortOrder: sortOrder, isSorting: sortBy === 'Email', onSort: handleSort })),
            key: 'Email',
            render: (member) => {
                if (member.email === null) {
                    return _jsx("em", { children: txt('deletedMembers') });
                }
                return member.email;
            },
        },
        {
            title: (_jsx(ColumnHeader, { title: "chargedCredit", columnKey: "ChargedMinutes", sortOrder: sortOrder, isSorting: sortBy === 'ChargedMinutes', onSort: handleSort })),
            dataIndex: 'chargedCredit',
            key: 'ChargedMinutes',
            render: (minutes) => `${formatDuration(minutes)}`,
        },
        {
            title: txt('storageStatistics'),
            dataIndex: 'usedStorage',
            render: (bytes) => `${(bytes / BYTES_IN_GIGABYTE).toFixed(2)} GB`,
        },
        {
            title: txt('forPeriod'),
            render: (member) => getDateCellContent(member, filterDates),
            onFilterDropdownOpenChange: (visibility) => setDropdownVisible(visibility),
            filterDropdownOpen: dropdownVisible,
            filterDropdown: (_jsx(DateRangeFilterDropdown, { filterDates: filterDates, onDropdownVisibility: setDropdownVisible, onClearSieve: handleClearSieve, onFilterDatesChanged: handleFilterDatesChanged })),
        },
    ];
    return (_jsx("div", { className: styles.overview, children: isTeamOwner ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.overviewTranscriptions, children: [_jsxs("h3", { className: styles.monthlyOverviewOfTeam, children: [_jsx(FundOutlined, { style: { marginRight: '5px', fontSize: '20px' } }), txt('monthlyOverviewOfTeam')] }), _jsxs("p", { className: styles.joinTeam, children: [txt('joinTeam'), _jsx("a", { href: txt('contactOptions'), target: "_blank", rel: "noreferrer", children: txt('contactOurSupport') }), "."] }), isDefaultSetting === false ? (_jsxs(IconButton, { className: clsx(styles.toolbarButton, styles.clearSortIcon), onClick: handleClearSieve, children: [_jsx(CloseOutlined, {}), _jsx("span", { children: txt('clearAll') })] })) : null, _jsx(ResponsiveTable, { onViewChange: fetchTeamTranscriptionPerPeriod, bordered: true, className: styles.table, columns: columnsOfOwner, dataSource: teamTranscription === 'loading' ? [] : teamTranscription.data.list, rowKey: (teamMember) => teamMember.id, tableView: tableView, recordCount: teamTranscription === 'loading' ? 0 : teamTranscription.data.totalCount, loading: teamTranscription === 'loading', footer: () => totalTranscribedFooter(), expandable: {
                                expandedRowRender: (record) => ExpandedContentWithProjects(record.email !== null ? record.email : '', record.id, memberProjects, filterDates),
                                onExpand: handleRowExpanded,
                                // NOTE: "key === -1" represents aggregated data for deleted members.
                                rowExpandable: (member) => member.id !== -1,
                                expandedRowKeys: expandedRowProperties.key,
                            }, pageSizeOptions: ['10', '20', '50', '100'] })] }), !login.hasClaim('orders:create') ? (_jsx(TeamCreditInfo, { credit: credit })) : null] })) : (_jsxs(_Fragment, { children: [_jsx(TranscribedOverview, {}), _jsx(TeamCreditInfo, { credit: credit })] })) }));
};
export default TranscriptionInfo;
