import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSession } from 'components/Authenticated';
import { App } from 'antd';
import { txt } from 'libs/i18n';
import LoadingWaveAnimation from './LoadingWaveAnimation';
import { useSubModeTutorial } from '../subModeTutorial';
import { usePlaybackContext } from '../WithMediaPlayback';
import { WaveCanvas } from './wave-canvas';
import SoundWaveToolbar from '../SoundWaveToolbar';
import styles from './style.module.less';
const SoundWave = ({ editorController, project, isWaveCanvasVisible, setWaveCanvasVisibility, setWaveCanvas, }) => {
    const canvasRef = useRef(null);
    const canvasContainerRef = useRef(null);
    const [isWaveLoading, setIsWaveLoading] = useState(true);
    const [zoom, setZoom] = useState({
        horizontal: 1.0,
        vertical: 1.0,
    });
    const { playback, captions } = usePlaybackContext();
    const { session } = useSession();
    const { message } = App.useApp();
    const { tutorialGoNext } = useSubModeTutorial();
    useEffect(() => {
        if (canvasContainerRef.current !== null && canvasRef.current === null) {
            const handleZoomChange = (scale) => {
                setZoom(scale);
            };
            canvasRef.current = new WaveCanvas(editorController, canvasContainerRef.current, playback, captions, project, session, handleZoomChange);
            setWaveCanvas(canvasRef.current);
        }
        return () => {
            if (canvasRef.current !== null) {
                canvasRef.current.destroy();
                setWaveCanvasVisibility(false, false);
                setWaveCanvas(null);
            }
        };
    }, []);
    useEffect(() => {
        if (canvasRef.current !== null) {
            if (isWaveCanvasVisible) {
                const fetchWave = async () => {
                    var _a, _b;
                    const isWaveFetched = (_b = await ((_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.fetchWaveFormWithRetry(10, 10))) !== null && _b !== void 0 ? _b : false;
                    const isCanvasDisplayed = canvasRef.current !== null && !canvasRef.current.getIsDestroyed;
                    if (!isWaveFetched && isCanvasDisplayed) {
                        void message.error(txt('failedLoadingSoundWave'));
                    }
                    setIsWaveLoading(false);
                };
                editorController.scrollToPlayback();
                void fetchWave();
                void canvasRef.current.fetchSceneChange();
            }
            canvasRef.current.updateWaveCanvasVisibility(isWaveCanvasVisible);
        }
    }, [isWaveCanvasVisible]);
    return (_jsxs("div", { className: clsx(styles.canvasPanel, 'sub-mode-tutorial-step-wave', { [styles.canvasPanelHidden]: !isWaveCanvasVisible }), children: [_jsx("div", { ref: canvasContainerRef, className: styles.canvasContainer, onPointerUp: tutorialGoNext }), canvasRef.current !== null ? (_jsxs(_Fragment, { children: [isWaveLoading ? (_jsxs("div", { className: styles.waveLoading, children: [_jsx(LoadingWaveAnimation, {}), _jsx("span", { className: styles.waveLoadingText, children: txt('loadingSoundWave') })] })) : null, _jsx(SoundWaveToolbar, { soundWave: canvasRef.current, zoom: zoom })] })) : null] }));
};
export default SoundWave;
