import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Spin, Card, Row, Col, Space, App, } from 'antd';
import { IdcardOutlined, SolutionOutlined } from '@ant-design/icons';
import { BigAlert } from 'components/Alerts';
import { useSession } from 'components/Authenticated';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import { fetchUser, fetchUserClaims, modifyUser } from 'api/admin/users-api';
import * as AdminTeamsAPI from 'api/admin/teams-api';
import { txt } from 'libs/i18n';
import { fetchManagementGroups } from 'api/admin/management-groups-api';
import UserProfile from './UserProfile';
import TeamUsageOverview from './TeamUsageOverview';
import TeamProfile from './TeamProfile';
import styles from './style.module.less';
import TeamPlanAdmin from './TeamPlanAdmin';
const EditUserSubpage = ({ match: { params: { userid } } }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const { notification } = App.useApp();
    const [profile, setProfile] = useState('loading');
    const [managementGroups, setManagementGroups] = useState([]);
    const [alert, setAlert] = useState({
        isVisible: false,
        text: null,
    });
    const [creditDelta, setCreditDelta] = useState(0);
    const [storageStatistics, setStorageStatistics] = useState('loading');
    const [teamMembersNumber, setTeamMembersNumber] = useState(0);
    const [claimSet, setClaimSet] = useState(null);
    const fetchManagementGroupsState = async () => {
        if (session.login.hasClaim('admin:superadmin')) {
            const response = await fetchManagementGroups(session.connection);
            setManagementGroups(response.list);
        }
        else {
            setManagementGroups([]);
        }
    };
    const getUserClaimSet = async () => {
        try {
            setClaimSet(await fetchUserClaims(session.connection, userid));
        }
        catch (error) {
            global.logger.error('Failed to get claim set', {}, error);
        }
    };
    const fetchStorageStatisticsInfo = async (teamId, onlyUserData) => {
        return AdminTeamsAPI.fetchStorageStatistics(session.connection, teamId.toString(), onlyUserData);
    };
    const handleStorageStatistics = async (teamId, onlyUserData) => {
        const data = await fetchStorageStatisticsInfo(teamId, onlyUserData);
        setStorageStatistics({ storagePerCount: data.bytesPerShareCount, onlyUserData });
    };
    const fetchProfile = async () => {
        try {
            const user = await fetchUser(session.connection, userid);
            const team = await AdminTeamsAPI.fetchTeam(session.connection, Number(user.teamId));
            void handleStorageStatistics(team.id, true);
            setProfile({ user, team });
        }
        catch (err) {
            setProfile('not-found');
        }
    };
    useEffect(() => {
        void fetchProfile();
        void fetchManagementGroupsState();
        void getUserClaimSet();
    }, []);
    const handleRoleChange = async (newRole) => {
        if (profile === 'loading' || profile === 'not-found') {
            return;
        }
        try {
            const modifiedUser = await modifyUser(session.connection, userid, {
                role: newRole,
            });
            setProfile(Object.assign(Object.assign({}, profile), { user: modifiedUser }));
            void message.success(`${txt('roleSuccess')} ${newRole}`, 2);
            if (userid === session.login.user.id) {
                if (newRole === 'Standard') {
                    notification.warning({
                        message: txt('roleWarningHead'),
                        description: txt('roleWarningBody'),
                        placement: 'topRight',
                        duration: 8,
                    });
                }
            }
        }
        catch (error) {
            if (error instanceof ApiError
                && error.errorMessage.startsWith('Unknown error')) {
                global.logger.error('Invalid role. Role change failed', {}, error);
                setAlert({ isVisible: true, text: 'invalidRole' });
            }
            else {
                global.logger.error('Role change failed', {}, error);
                setAlert({ isVisible: true, text: 'unknownError' });
            }
        }
    };
    const handleCreditChanged = (delta) => {
        if (delta !== null) {
            setCreditDelta(delta);
        }
    };
    const handleOperation = async (operation) => {
        if (profile === 'loading' || profile === 'not-found') {
            return;
        }
        try {
            await (operation === 'add' ? AdminTeamsAPI.addTeamCredit : AdminTeamsAPI.removeTeamCredit)(session.connection, Number(profile.team.id), creditDelta);
            void message.success(txt('creditSuccess'));
            setCreditDelta(0);
            await fetchProfile();
        }
        catch (error) {
            if (error instanceof ApiError) {
                if (error.errorMessage.startsWith('Invalid JSON in body')) {
                    global.logger.error('Invalid JSON in body\nCreditMinutes', {}, error);
                    void message.error(txt('invalidCreditFormat'));
                }
                else {
                    global.logger.error('Credit set failed', {}, error);
                    void message.error(txt('unknownError'));
                }
            }
        }
    };
    let pageContent = null;
    if (profile === 'loading') {
        pageContent = _jsx(Spin, {});
    }
    else if (profile === 'not-found') {
        pageContent = (_jsx(Row, { gutter: 18, children: _jsx(Col, { span: 24, children: _jsx(Card, { bordered: false, children: _jsx(BigAlert, { header: txt('noSuchUser'), body: txt('userDoesNotExist') }) }) }) }));
    }
    else {
        pageContent = (_jsxs(Row, { gutter: 18, className: styles.pageRow, children: [_jsx(Col, { span: 24, lg: 12, children: _jsx(UserProfile, { user: profile.user, teamOwner: profile.team.ownerEmail, alert: alert, onRoleChange: handleRoleChange, claimSet: claimSet }) }), _jsxs(Col, { span: 24, lg: 12, children: [_jsx(TeamProfile, { creditDelta: creditDelta, onOperation: handleOperation, managementGroups: managementGroups, onCreditChanged: handleCreditChanged, team: profile.team }), _jsx(TeamUsageOverview, { team: profile.team, storageStatistics: storageStatistics, onGetTeamMembersNumber: (number) => setTeamMembersNumber(number), onFetchStorageStatistics: (userDataOnly) => handleStorageStatistics(profile.team.id, userDataOnly) }), _jsx(TeamPlanAdmin, { teamId: profile.team.id, storageStatistics: storageStatistics, teamMembersNumber: teamMembersNumber, hasUnlimitedStorage: claimSet !== null ? claimSet.activeClaims.includes('unlimitedStorage:enabled') : false })] })] }));
    }
    const breadcrumbItems = [
        {
            title: (_jsx(Link, { to: "/admin/users", children: _jsxs(Space, { children: [_jsx(SolutionOutlined, {}), _jsx("span", { children: txt('administration') })] }) })),
        },
        {
            title: (_jsx(Link, { to: "/admin/users", children: _jsxs(Space, { children: [_jsx(IdcardOutlined, {}), _jsx("span", { children: txt('userProfile') })] }) })),
        },
    ];
    return (_jsxs("section", { children: [_jsx(Breadcrumb, { items: breadcrumbItems }), pageContent] }));
};
export default EditUserSubpage;
