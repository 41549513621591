import { jsx as _jsx } from "react/jsx-runtime";
import { Badge, Tooltip } from 'antd';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import { useState } from 'react';
import clsx from 'clsx';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const getSignState = (editorController, trigger) => {
    const range = editorController.speakers.getSpeakerIndexRange(trigger);
    if (range === null) {
        return null;
    }
    const metadata = editorController.textMetadata.getMetadataAtIndex(range.end - 1, 'speakerSign');
    if (metadata !== null) {
        return {
            visible: metadata.display !== 'hide',
            display: metadata.display,
        };
    }
    return { visible: false, display: 'default' };
};
const SpeakerSign = ({ position, widgetTrigger, editorController, disabled, }) => {
    if (editorController === undefined) {
        return null;
    }
    const [hovered, setHovered] = useState(false);
    const [speakerSign, setSpeakerSign] = useState(getSignState(editorController, widgetTrigger));
    const setSign = (display) => {
        const range = editorController.speakers.getSpeakerIndexRange(widgetTrigger);
        if (range === null)
            return;
        const { begin, end } = range;
        const newMetadata = [
            [end - 1, { content: '', display }],
        ];
        editorController.textMetadata.spliceMetadata('speakerSign', end - 1, end, newMetadata);
        editorController.captions.updateCaptions(begin, end, true);
        setSpeakerSign({ visible: display === 'show', display });
        editorController.triggerSave();
    };
    const deleteIcon = (_jsx(IconButton, { className: styles.deleteButton, onClick: () => setSign('hide'), children: _jsx(CloseCircleOutlined, {}) }));
    if (speakerSign === null) {
        return null;
    }
    if (!speakerSign.visible) {
        return (_jsx(IconButton, { disabled: disabled, className: clsx(styles.addButton, { [styles.forceDisplay]: speakerSign.display !== 'default' }), style: {
                position: 'absolute', zIndex: 100, top: position.top + 37, left: position.left - 22,
            }, onClick: () => setSign('show'), children: _jsx(Tooltip, { title: disabled === true ? undefined : txt('showSpeakerSign'), children: _jsx(PlusCircleOutlined, {}) }) }));
    }
    return (_jsx("div", { style: {
            position: 'absolute', zIndex: 100, top: position.top + 35, left: position.left - 12,
        }, onFocus: disabled === true ? undefined : () => setHovered(true), onBlur: () => setHovered(false), onMouseOver: disabled === true ? undefined : () => setHovered(true), onMouseOut: () => setHovered(false), className: clsx(styles.speakerSignContainer, { [styles.disabled]: disabled === true }), children: _jsx(Badge, { count: hovered ? deleteIcon : 0, children: _jsx("div", { className: clsx(styles.speakerSign, { [styles.forceDisplay]: speakerSign.display !== 'default' }) }) }) }));
};
export default SpeakerSign;
