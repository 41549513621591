import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Form, Input } from 'antd';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import { addManagementGroup, fetchManagementGroups, updateManagementGroup } from 'api/admin/management-groups-api';
import { useSession } from 'components/Authenticated';
import AddEditModal from 'components/AddEditModal';
import { txt } from 'libs/i18n';
import { EditOutlined, TeamOutlined, } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import AddButton from 'components/AddButton';
import styles from './style.module.less';
const GROUPS_TABLE_ID = 'groups-table'; // Must be unique across all tables in Beey!!
const ManagementGroupSubpage = () => {
    const { session } = useSession();
    const tableView = useTableView(GROUPS_TABLE_ID);
    const [managementGroups, setManagementGroups] = useState('loading');
    const [modal, setModal] = useState({
        action: 'add',
        visible: false,
        initialValues: {},
    });
    const modalTitle = (_jsxs(_Fragment, { children: [_jsx(TeamOutlined, { className: styles.groupIcon }), modal.action === 'add' ? txt('addGroup') : txt('editGroup')] }));
    const formItems = (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: txt('groupTitle'), name: "groupName", rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: txt('noGroupTitle'),
                    },
                    {
                        validator: (_, groupName) => {
                            const currentGroupName = modal.initialValues.groupName;
                            if (managementGroups !== 'loading') {
                                const doesGroupExist = managementGroups.some((managementGroup) => managementGroup.groupName === groupName);
                                if (doesGroupExist && groupName !== currentGroupName) {
                                    return Promise.reject(txt('groupExists'));
                                }
                                return Promise.resolve();
                            }
                            return Promise.reject(txt('waitForGroups'));
                        },
                    },
                ], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { label: txt('groupDescription'), name: "notes", children: _jsx(Input, {}) }), _jsx(Form.Item, { label: txt('externalId'), name: "externalId", children: _jsx(Input, {}) }), _jsx(Form.Item, { className: "form__item--hidden", name: "id", children: _jsx(Input, { type: "hidden" }) })] }));
    const fetchGroupList = async () => {
        const response = await fetchManagementGroups(session.connection);
        setManagementGroups(response.list);
    };
    useEffect(() => {
        void fetchGroupList();
    }, []);
    const openEditModal = (record) => {
        const { groupName, notes, id, externalId, } = record;
        setModal({
            action: 'edit',
            visible: true,
            initialValues: {
                groupName, notes, id, externalId,
            },
        });
    };
    const openAddModal = () => {
        setModal({ action: 'add', visible: true, initialValues: { groupName: '', notes: '', externalId: '' } });
    };
    const handleAddGroup = async (group) => {
        const { groupName, notes, externalId } = group;
        await addManagementGroup(session.connection, groupName, notes, externalId === '' ? undefined : externalId);
        void fetchGroupList();
    };
    const handleEditGroup = async (group) => {
        const { groupName, notes, id, externalId, } = group;
        await updateManagementGroup(session.connection, id, groupName, notes, externalId === '' ? undefined : externalId);
        void fetchGroupList();
    };
    const columns = [
        {
            title: txt('groupTitle'),
            dataIndex: 'Title',
            key: 'Title',
            render: (_, { groupName, id, }) => (_jsx(Link, { to: { pathname: '/admin/users', state: { groupId: id } }, children: groupName })),
        },
        {
            title: txt('groupDescription'),
            dataIndex: 'Description',
            key: 'Description',
            render: (_, { notes, }) => (_jsx("div", { children: notes })),
        },
        {
            title: '',
            key: 'Edit',
            width: '40px',
            render: (record) => {
                return (_jsx(IconButton, { onClick: () => openEditModal(record), children: _jsx(EditOutlined, {}) }));
            },
        },
    ];
    return (_jsx("section", { className: styles.groupsSubpage, children: _jsxs(Card, { bordered: false, className: styles.groupsCard, children: [_jsx("div", { className: styles.cardHeader, children: _jsxs("div", { className: styles.cardTitle, children: [_jsx(TeamOutlined, { className: styles.groupIcon }), _jsx("span", { className: styles.groupTitle, children: txt('groups') })] }) }), _jsx(AddButton, { className: styles.addGroupButton, onClick: () => openAddModal(), title: txt('addGroup') }), _jsx(ResponsiveTable, { bordered: true, columns: columns, className: styles.groupsTable, dataSource: managementGroups === 'loading' ? [] : managementGroups, tableView: tableView, loading: managementGroups === 'loading', rowKey: (group) => group.id, recordCount: managementGroups.length, pageSizeOptions: ['5', '10', '20', '50', '100'] }), _jsx(AddEditModal, { action: modal.action, visible: modal.visible, title: modalTitle, initialValues: modal.initialValues, onCancel: () => setModal(Object.assign(Object.assign({}, modal), { visible: false })), onAdd: async (group) => handleAddGroup(group), onEdit: async (group) => handleEditGroup(group), children: formItems })] }) }));
};
export default ManagementGroupSubpage;
