import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSession } from 'components/Authenticated';
import * as TeamAPI from 'api/team-api';
import { txt } from 'libs/i18n';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import { TeamIcon } from 'components/CustomIcons';
import { App, Form, Input, Spin, Tooltip, } from 'antd';
import FormModal from 'components/FormModal';
import { DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import DeleteModal from 'components/DeleteModal';
import { deleteTeamMember } from 'api/team-api';
import { fetchPasswordSettings } from 'api/user-api';
import LanguageSelect from 'components/LanguageSelect';
import { getUiLocales } from 'libs/locales/ui';
import AddButton from 'components/AddButton';
import styles from './style.module.less';
import PlanUsage from '../CreditInfo/TeamPlan/PlanUsage';
const TEAM_MEMBERS_TABLE_ID = 'team-members-table'; // Must be unique across all tables in Beey!!
const TeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState('loading');
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        memberId: 0,
        memberEmail: '',
    });
    const [passwordRequiredLength, setPasswordRequiredLength] = useState('loading');
    const [teamPlan, setTeamPlan] = useState('loading');
    const { session } = useSession();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const tableView = useTableView(TEAM_MEMBERS_TABLE_ID, {
        pageSize: 10,
    });
    const { currentPage, pageSize } = tableView.getViewParams();
    const isTeamOwner = session.login.hasClaim('team:owner');
    const getTeamMembers = async () => {
        const members = await TeamAPI.fetchCurrentTeamMembers(session.connection, pageSize * (currentPage - 1), pageSize);
        setTeamMembers(members);
    };
    useEffect(() => {
        const fetchPwdSettings = async () => {
            const pwdSettings = await fetchPasswordSettings();
            setPasswordRequiredLength(pwdSettings.requiredLength);
        };
        const fetchTeamPlanState = async () => {
            const teamPlanState = await TeamAPI.fetchTeamPlan(session.connection);
            setTeamPlan(teamPlanState);
        };
        void fetchPwdSettings();
        void fetchTeamPlanState();
    }, []);
    const createMember = async (memberInfo) => {
        const { email, password, language: { code } } = memberInfo;
        try {
            await TeamAPI.addTeamMember(session.connection, email, password, code);
            void getTeamMembers();
            void message.success(txt('memberCreated'));
            setAddModalOpen(false);
        }
        catch (error) {
            if (error instanceof ApiError
                && error.response.status === 422
                && error.errorMessage.startsWith('Email already used')) {
                void message.error(txt('alreadyExists'));
            }
            else {
                void message.error(txt('failedToAddMember'));
            }
        }
    };
    const deleteMember = async () => {
        try {
            await deleteTeamMember(session.connection, deleteModal.memberId);
            void getTeamMembers();
            void message.success(txt('memberDeleted'));
            setDeleteModal({ memberEmail: '', memberId: 0, open: false });
        }
        catch (error) {
            void message.error(txt('failedToDeleteMember'));
        }
    };
    if (teamPlan === 'loading') {
        return _jsx(Spin, {});
    }
    const unlimitedMembers = session.login.hasClaim('unlimitedTeamSize:enabled');
    const canCreateMembers = teamPlan.lookupKey !== 'beey_start' || unlimitedMembers;
    const remainingMembers = TeamAPI.getAllowedNumberOfMembers(teamPlan.lookupKey) - (teamMembers === 'loading' ? 0 : teamMembers.totalCount);
    const columnOfMembers = [
        {
            title: txt('members'),
            dataIndex: 'email',
            key: 'Email',
        },
        {
            title: '',
            render: (member) => (isTeamOwner && !member.isOwner
                ? (_jsx(Tooltip, { title: txt('delete'), children: _jsx(IconButton, { danger: true, onClick: () => setDeleteModal({
                            open: true,
                            memberId: member.id,
                            memberEmail: member.email,
                        }), children: _jsx(DeleteOutlined, {}) }) })) : null),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.teamInfo, children: [_jsxs("div", { className: styles.title, children: [_jsx(TeamIcon, {}), _jsx("h2", { children: txt('teamMembers') })] }), teamMembers !== 'loading' && (_jsxs("div", { children: [_jsxs("span", { className: styles.label, children: [txt('teamOwner'), ":"] }), ' ', _jsx("span", { children: teamMembers.list.filter((member) => member.isOwner)[0].email })] }))] }), isTeamOwner && canCreateMembers && !unlimitedMembers ? (_jsx("div", { className: styles.planUsage, children: _jsx(PlanUsage, { icon: _jsx(TeamOutlined, {}), total: TeamAPI.getAllowedNumberOfMembers(teamPlan.lookupKey), remaining: remainingMembers < 0 ? 0 : remainingMembers, unit: txt('fromUsers').toLowerCase(), decimal: false }) })) : null, isTeamOwner && canCreateMembers ? (_jsx(AddButton, { className: styles.addMemberButton, disabled: remainingMembers <= 0 && !unlimitedMembers, onClick: () => setAddModalOpen(true), size: "small", title: txt('addMember') })) : null, _jsx(ResponsiveTable, { onViewChange: getTeamMembers, className: styles.table, columns: columnOfMembers, dataSource: teamMembers === 'loading' ? [] : teamMembers.list, rowKey: (teamMember) => teamMember.id, tableView: tableView, recordCount: teamMembers === 'loading' ? 0 : teamMembers.totalCount, loading: teamMembers === 'loading', pageSizeOptions: ['10', '20', '50', '100'] }), _jsx(FormModal, { open: addModalOpen, title: txt('addMember'), onCancel: () => setAddModalOpen(false), onOk: () => form.submit(), afterClose: () => form.resetFields(), width: "25rem", children: _jsxs(Form, { form: form, onFinish: createMember, labelCol: { span: 8 }, wrapperCol: { span: 14 }, labelAlign: "left", children: [_jsx(Form.Item, { name: "email", label: txt('email'), rules: [
                                {
                                    required: true,
                                    message: txt('pleaseEmail'),
                                    type: 'email',
                                },
                                {
                                    max: 254,
                                    message: txt('maxCharacters'),
                                },
                                {
                                    validator: async (_, value) => {
                                        if (value !== undefined) {
                                            const trimmedEmail = value.trim();
                                            const isEmailTaken = teamMembers !== 'loading' && teamMembers.list.some((member) => member.email.toLowerCase() === trimmedEmail.toLowerCase());
                                            if (value !== '' && isEmailTaken) {
                                                return Promise.reject(txt('alreadyRegistered'));
                                            }
                                            return Promise.resolve();
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ], children: _jsx(Input, { autoFocus: true }) }), passwordRequiredLength === 'loading' ? _jsx(Spin, {}) : (_jsx(Form.Item, { name: "password", label: txt('pw'), rules: [
                                {
                                    required: true,
                                    message: txt('pleasePW'),
                                },
                                {
                                    min: passwordRequiredLength,
                                    whitespace: true,
                                    message: `${txt('minChars')}${passwordRequiredLength}`,
                                },
                                {
                                    max: 128,
                                    message: txt('maxCharacters'),
                                },
                            ], children: _jsx(Input, {}) })), _jsx(Form.Item, { name: "language", label: txt('language'), rules: [
                                {
                                    required: true,
                                    message: txt('pleaseLanguage'),
                                },
                            ], children: _jsx(LanguageSelect, { locales: getUiLocales() }) })] }) }), _jsx(DeleteModal, { visible: deleteModal.open, onCancel: () => setDeleteModal(Object.assign(Object.assign({}, deleteModal), { open: false })), email: deleteModal.memberEmail, isTeamOwner: false, onDelete: async () => deleteMember(), redirectTo: "/account/members" })] }));
};
export default TeamMembers;
